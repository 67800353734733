import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      BUTTON_TEXT_SAVE:'Save',
      BUTTON_TEXT_SAVE_SEND:'Save and Send',
      BUTTON_TEXT_CANCEL: 'Cancel',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      ERROR_MESSAGE_INVALID_MOBILE_NUMBER: 'Invalid mobile contact number',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    ABOUTME: {
      SECTION_HEADING_HI: 'Hi',
      SECTION_HEADING_ABOUT_ME: 'this is information about you, your friends and families.',
      SECTION_HADING_MY_DETAILS: 'My Details',
      SECTION_HADING_MY_ADDRESSES: 'My Addresses',
      SECTION_HADING_MY_SCHOOLS: 'My Schools',
      BUTTON_TEXT_VIEW_ON_MAP: 'View on Map',
      BUTTON_TEXT_UPLOAD: 'Upload',
      BUTTON_TEXT_VIEW_ALL_ON_A_MAP: 'View all on a Map',
      BUTTON_TEXT_ADD_AN_ADDRESS: 'Add an Address',
      BUTTON_TEXT_ADD_SCHOOL: 'Add a School',
      CHARACTER_COUNT:'Character Count',
      TAB_LINK_TEXT_ABOUT_ME: 'About Me',
      TAB_LINK_TEXT_MY_ESSENTIAL_INFO: 'My Essential Information',
      TAB_LINK_TEXT_MY_FAMILIES: 'My Families',
      TAB_LINK_TEXT_MY_FRIENDS: 'My Friends',
      TAB_LINK_TEXT_MY_ACHIEVEMENTS_AND_AWARDS: 'My Awards & Achievements',
      TAB_LINK_TEXT_MY_JOBS: 'My Jobs',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'Upload',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'Save Changes',
      TOOLTIP_TEXT_BUTTON_VIEW_ALL_ON_MAP: 'View all on Map',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'Add a New Record',
      TOOLTIP_TEXT_BUTTON_VIEW_ON_MAP: 'View on Map',
      TOOLTIP_TEXT_COPY: 'Copy',
      TOOLTIP_TEXT_EXTERNAL_LINK: 'Open in External-Tab',
      TOOLTIP_TEXT_SEND_EMAIL: 'Send an Email',
      TOOLTIP_TEXT_FIRST_NAME: 'Enter First Name',
      TOOLTIP_TEXT_MIDDLE_NAME: 'Enter Middle Name(s) if you have any',
      TOOLTIP_TEXT_LAST_NAME: 'Enter Last Name',
      TOOLTIP_TEXT_NICK_NAME: 'This is your Nickname',
      TOOLTIP_TEXT_FULL_NAME: 'This is a combination of your First Name, Middle Name(s) & Last Name. It is for display purpose only',
      TOOLTIP_TEXT_NOOSOM_WEB_URL: 'This is your Noosom Web URL. It is personalized from your username. This is the URL to share for your blog',
      TOOLTIP_TEXT_NOOSOM_EMAIL: 'Your email address is used for two-step authentication; to change this field, please go to your Noosom Profile',
      TOOLTIP_TEXT_PERSONAL_URL: 'This is your Personal Web URL or your Company / Business URL',
      TOOLTIP_TEXT_USERNAME: 'This is your Allocated Noosom Username, please go to your Noosom Profile to change this field',
      TOOLTIP_TEXT_DOB: 'This is your Registered Date of Birth with us; Noosom can only be used for ages 12 & Up for security reasons',
      TOOLTIP_TEXT_SEX_BIRTH: 'This is your Sex at Birth - Choose from: Male / Female',
      TOOLTIP_TEXT_GENDER: 'This is the expression of your identity',
      TOOLTIP_TEXT_RELIGION: 'This is the Religion you believe in',
      TOOLTIP_TEXT_ZODIAC: 'This is your Astrological or Horoscope Sign based on each month',
      TOOLTIP_TEXT_CHINESE_ZODIAC: 'This is your Animal Sign based on the year of your birth, Find more information see here',
      TOOLTIP_TEXT_MOBILE: 'Enter Mobile Number with the area code',
      TOOLTIP_TEXT_DIARY_JOURNAL: 'Choose Diary or Journal as per your preference, and Noosom will handle the rest',
      CONTROL_LABEL_NOOSOM_USER_NAME: 'Your Allocated Noosom Username',
      CONTROL_LABEL_FULL_NAME: 'Full Name',
      CONTROL_LABEL_CALL_ME_NAME: 'Call Me Name',
      CONTROL_LABEL_FIRST_NAME: 'First Name',
      CONTROL_LABEL_MIDDLE_NAME: 'Middle Name',
      CONTROL_LABEL_LAST_NAME: 'Last Name',
      CONTROL_LABEL_DATE_OF_BIRTH: 'Date of Birth',
      CONTROL_LABEL_SEX_AT_BIRTH: 'Sex at Birth',
      CONTROL_LABEL_GENDER_IDENTIFY_AS: 'Gender Identify as',
      CONTROL_LABEL_RELIGION: 'Religion',
      CONTROL_LABEL_ZODIAC_SIGN: 'Zodiac Sign',
      CONTROL_LABEL_LUNAR_SIGN: 'Chinese Zodiac Sign',
      CONTROL_LABEL_NOOSOM_WEB_URL: 'Noosom Web URL',
      CONTROL_LABEL_PERSONAL_WEB_URL: 'Personal Web URL',
      CONTROL_LABEL_MOBILE_NUMBER: 'Mobile Number',
      CONTROL_LABEL_DIARY_OR_JOURNAL: 'Call App as',
      CONTROL_LABEL_DIARY: 'Diary',
      CONTROL_LABEL_JOURNAL: 'Journal',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      CONTROL_LABEL_PERSNOAL_WEB_URL: 'Personal Web URL',
      CONTROL_LABEL_SHOW: 'Show',
      CONTROL_LABEL_SEARCH: 'Search',
      CONTROL_LABEL_ADD_EMAIL:'Add Email',
      PLACEHOLDER_SEX_AT_BIRTH: 'Select Sex at Birth',
      PLACEHOLDER_GENDER_IDENTIFY_AS: 'Select Gender',
      PLACEHOLDER_RELIGION: 'Select Religion',
      PLACEHOLDER_ZODIAC_SIGN: 'Select Zodiac Sign',
      PLACEHOLDER_LUNAR_SIGN: 'Select Chinese Zodiac Sign',
      SWEET_ALERT_TITLE_SCHOOL_TEXT: 'Are you sure, you want to delete this School and all the attachments associated with it?',
      SWEET_ALERT_TITLE_ADDRESS_TEXT: 'Are you sure, you want to delete this Address and all the attachments associated with it?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'Are you sure, you want to delete this Insurance and all the attachments associated with it?',
      SWEET_ALERT_TITLE_MY_HEALTH_PROVIDERS_TEXT: 'Are you sure, you want to delete this Health Provider Detail?',
      SWEET_ALERT_TITLE_MY_MEDICATIONS_TEXT: 'Are you sure, you want to delete this Medication?',
      SWEET_ALERT_TITLE_MY_VACCINATION_TEXT: 'Are you sure, you want to delete this Vaccination?',
      SWEET_ALERT_TITLE_AWARD_TEXT: 'Are you sure, you want to delete this Award?',
      SWEET_ALERT_TITLE_ACHIEVEMENT_TEXT: 'Are you sure, you want to delete this Achievement?',
      SWEET_ALERT_TITLE_FAMILY_TEXT: 'Are you sure, you want to delete this Family Detail?',
      SWEET_ALERT_TITLE_FRIEND_TEXT: 'Are you sure, you want to delete this Friend Detail?',
      SWEET_ALERT_TITLE_INVITATION_TEXT: 'You are about to send Personname an invitation email to connect with you on Noosom.',
      SWEET_ALERT_TITLE_JOB_TEXT: 'Are you sure, you want to delete this Job Detail?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_NO_BUTTON: 'NO',
      SWEET_ALERT_YES_BUTTON: 'YES',
      SWEET_ALERT_SURE_TEXT: 'Are you sure ?',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      DATA_TABLE_HEADER_TEXT_SEQUENCE: 'SEQUENCE',
      DATA_TABLE_HEADER_TEXT_ADDRESS_CODE: 'ADDRESS TAG/CODE',
      DATA_TABLE_HEADER_TEXT_NAME_FOR_THIS_ADDRESS: 'HOUSE / BUILDING NAME',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'DATE FROM',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'DATE TO',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'ADDRESS',
      DATA_TABLE_HEADER_TEXT_SCHOOL_NAME: 'SCHOOL NAME',
      DATA_TABLE_HEADER_TEXT_COUNTRY: 'COUNTRY',
      DATA_TABLE_HEADER_TEXT_EDUCATION_RECEIVED: 'EDUCATION RECEIVED',
      TOAST_MESSAGE_ABOUTME_UPDATED_SUCCESSFULLY: 'About me data updated successfully.',
      TOAST_MESSAGE_ABOUTME_COPIED: 'Copied.',
      ERROR_MESSAGE_REQUIRED_NOOSOM_USER_NAME: 'The noosom user name is required!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'You have typed too many characters, maximum allowed is 50',
      ERROR_MESSAGE_MAX_LENGTH_100: 'You have typed too many characters, maximum allowed is 100',
      ERROR_MESSAGE_MAX_LENGTH_200: 'You have typed too many characters, maximum allowed is 200',
      ERROR_MESSAGE_MAX_LENGTH_12: 'You have typed too many characters, maximum allowed is 12',
      ERROR_MESSAGE_MAX_LENGTH_320: 'You have typed too many characters, maximum allowed is 320',
      ERROR_MESSAGE_MAX_LENGTH_250: 'You have typed too many characters, maximum allowed is 250',
      ERROR_MESSAGE_REQUIRED_FULL_NAME: 'The full name is required!',
      ERROR_MESSAGE_REQUIRED_CALL_ME_NAME: 'The call me name is required!',
      ERROR_MESSAGE_REQUIRED_FIRST_NAME: 'The first name is required!',
      ERROR_MESSAGE_REQUIRED_MIDDLE_NAME: 'The middle name is required!',
      ERROR_MESSAGE_REQUIRED_LAST_NAME: 'The last name is required!',
      ERROR_MESSAGE_REQUIRED_DATE_OF_BIRTH: 'The birth date is required!',
      ERROR_MESSAGE_REQUIRED_SEX_AT_BIRTH: 'The sex at birth is required!',
      ERROR_MESSAGE_REQUIRED_GENDER_IDENTIFY_AS: 'The gender identify as is required!',
      ERROR_MESSAGE_REQUIRED_RELIGION: 'The religion is required!',
      ERROR_MESSAGE_REQUIRED_ZODIAC_SIGN: 'The zodiac sign is required!',
      ERROR_MESSAGE_REQUIRED_LUNAR_SIGN: 'The lunar sign is required!',
      ERROR_MESSAGE_REQUIRED_NOOSOM_WEB_URL: 'The noosom web URL is required!',
      ERROR_MESSAGE_REQUIRED_PERSONAL_WEB_URL: 'The personal web URL is required!',
      ERROR_MESSAGE_REQUIRED_MOBILE_NUMBER: 'The mobile number is required!',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email address is required!',
      ERROR_MESSAGE_REQUIRED_JOBDESCRIPTION: 'The job description is required!',
      ERROR_MESSAGE_REQUIRED_STREET: 'The street name is required!',
      ERROR_MESSAGE_REQUIRED_EMPLOYER: 'The employer is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE: 'The post / zip code is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESSTOWN: 'The town / city is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_STATE: 'The state is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_REGION: 'The region is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_COUNTRY: 'The country is required!',
      ERROR_MESSAGE_EXISTED_EMAILADDRESS: 'That email address has been taken. Please try another.',
      ERROR_MESSAGE_EXISTED_MOBILENUMBER: 'That mobile number has been taken. Please try another.',
      ERROR_MESSAGE_EXISTED_USERNAME: 'That username has been taken. Please try another.',
      ERROR_MESSAGE_INVALID_DATA: 'Invalid Contact Number!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Invalid Email Address!',
      ERROR_MESSAGE_EXIST_EMAIL: 'This email address is already connected with a friend, family member, or is your own. Please use a different one.',
      ERROR_MESSAGE_REQUIRED_LAST_REFILL_DATE_REQUIRED: 'The last refill date is required!',
      ERROR_MESSAGE_REQUIRED_NEXT_REFILL_DATE_REQUIRED: 'The next refill date is required!',
      ERROR_MESSAGE_INVALID_MOBILE_NUMBER: 'Invalid mobile contact number',
      ERROR_MESSAGE_EXISTED_MOBILE_NUMBER: 'That mobile number has been taken. Please try another.',
    },
    ESSENTIAL_INFORMATION: {
      BUTTON_TEXT_ADD_AN_INSURANCE: 'Add an Insurance',
      BUTTON_TEXT_ADD_A_PROFFESIONAL: 'Add a Professional',
      CONTROL_LABEL_BLOOD_GROUP: 'Blood Group',
      CONTROL_LABEL_ORGAN_DONOR: 'Organ Donor',
      CONTROL_LABEL_YES: 'Yes',
      CONTROL_LABEL_NO: 'No',
      CONTROL_LABEL_ALLERGIES: 'Allergies',
      CONTROL_LABEL_RELATIONSHIP: 'Relationship',
      CONTROL_LABEL_EMERGENCY_CONTACT_FULLNAME: 'Emergency Contact Full Name',
      CONTROL_LABEL_EMERGENCY_CONTACT_NUMBER: 'Emergency Contact Number',
      CONTROL_LABEL_GENERAL_MEDICAL_HISTORY: 'General Medical History',
      CONTROL_LABEL_FAMILY_MEDICAL_HISTORY: 'Family Medical History',
      CONTROL_LABEL_SURGICAL_HISTORY: 'Surgical History',
      CONTROL_LABEL_POST_CARE_NOTES: 'Post Care Notes',
      DATA_TABLE_HEADER_TEXT_INSURANCE_TYPE: 'INSURANCE TYPE',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      DATA_TABLE_HEADER_TEXT_INSURANCE_NAME: 'INSURANCE NAME',
      DATA_TABLE_HEADER_TEXT_INSURANCE_POLICY_NUMBER: 'INSURANCE POLICY NUMBER',
      DATA_TABLE_HEADER_TEXT_CARD_NUMBER: 'CARD NUMBER',
      DATA_TABLE_HEADER_TEXT_INSURANCE_CONTACT_NUMBER: 'INSURANCE CONTACT NUMBER',
      DATA_TABLE_HEADER_TEXT_STATUS: 'STATUS',
      DATA_TABLE_HEADER_TEXT_POLICY_START_DATE: 'POLICY START DATE',
      DATA_TABLE_HEADER_TEXT_POLICY_EXPIRATION_DATE: 'POLICY EXPIRATION DATE',
      DATA_TABLE_HEADER_TEXT_SPECIALITY: 'SPECIALITY',
      DATA_TABLE_HEADER_TEXT_PROFESSIONAL_NAME: "PROFESSIONAL'S NAME",
      DATA_TABLE_HEADER_TEXT_FULL_ADDRESS: 'FULL ADDRESS',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'EMAIL ADDRESS',
      DATA_TABLE_HEADER_TEXT_CONTACT_NUMBER: 'CONTACT NUMBER 1',
      DATA_TABLE_HEADER_TEXT_LAST_EXAM_DATE: 'LAST EXAM DATE',
      DATA_TABLE_HEADER_TEXT_NEXT_EXAM_DATE: 'NEXT EXAM DATE',
      PLACEHOLDER_SELECT_BLOOD_GROUP: 'Select Blood Group',
      PLACEHOLDER_SELECT_RELATIONSHIP: 'Select Relationship',
      SECTION_HEADING_MY_DEMOGRAPHICS: 'My Demographics',
      SECTION_HEADING_MEDICAL_HISTORY: 'Medical History',
      SECTION_HEADING_MY_INSURANCE_INFORMATION: 'My Insurance Information',
      SECTION_HEADING_MY_HEALTH_PROVIDERS: 'My Health Providers',
      TAB_HEADING_MY_DEMOGRAPHICS: 'My Demographics & Medical History',
      TAB_HEADING_GENERAL_MANAGEMENT: 'General Management',
      TAB_HEADING_MEDICATIONS: 'Medications',
      TAB_HEADING_MY_VACCINATION_RECORD: 'My Vaccination Record',
      TOOLTIPTEXT_CONTROL_LABEL_GENERAL_MEDICAL_HISTORY: 'These fields are optional; enter to the best of your ability',
      FORM_DESCRIPTION: 'This form gets rid of your paper pile when you visit your healthcare practitioner. They have a bird\'s eye view on your health',
      TOOLTIP_TEXT_BLOOD_GROUP: 'Choose your Blood Group',
      TOOLTIP_TEXT_ORGAN_DONOR: 'In the case of your death, if you have chosen yes as an Organ Donor, your family can see this information for the necessary donations and last rites',
      TOOLTIP_TEXT_ALLERGIES: 'This is for the people who get very bad reactions to medications, foods, and other seasonal allergies',
      TOOLTIP_TEXT_EMERGENCY_CONTACT_INFO: 'Information of an immediate family member in case of an emergency',
      TOOLTIP_TEXT_RELATIONSHIP_TO_EMERGENCY_CONTACT: 'Information of relationship to immediate family member in case of an emergency',
      TOOLTIP_TEXT_EMERGENCY_CONTACT_MOBILE: 'Enter Mobile Number with the area code of whom to contact in case of an emergency',
      TOAST_MESSAGE_ESSENTIAL_ADDED_SUCCESSFULLY: 'Reocrd Added Successfully',
      TOAST_MESSAGE_ESSENTIAL_UPDATED_SUCCESSFULLY: 'Record Updated Successfully',
    },
    MY_MEDICATION: {
      BUTTON_TEXT_ADD_A_MEDICATION: 'Add a Medication',
      DATA_TABLE_HEADER_TEXT_MEDICATION_NAME: 'MEDICATION NAME',
      DATA_TABLE_HEADER_TEXT_CONDITION_FOR: 'CONDITION FOR',
      DATA_TABLE_HEADER_TEXT_DOSAGE: 'DOSAGE',
      DATA_TABLE_HEADER_TEXT_FREQUENCY: 'FREQUENCY',
      DATA_TABLE_HEADER_TEXT_PRESCRIBED_BY: 'PRESCRIBED BY',
      DATA_TABLE_HEADER_TEXT_LAST_REFILL_DATE: 'LAST REFILL DATE',
      DATA_TABLE_HEADER_TEXT_NEXT_REFILL_DATE: 'NEXT REFILL DATE',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      SECTION_HEADING_MY_MEDICATIONS: 'My Medications',
    },
    MY_VACCINATION: {
      BUTTON_TEXT_ADD_A_VACCINATION: 'Add a Vaccination',
      DATA_TABLE_HEADER_TEXT_VACCINATION_NAME: 'VACCINATION NAME',
      DATA_TABLE_HEADER_TEXT_VACCINATION_TYPE: 'VACCINATION TYPE',
      DATA_TABLE_HEADER_TEXT_LAST_DOSAGE_DATE: 'LAST DOSAGE DATE',
      DATA_TABLE_HEADER_TEXT_NEXT_DOSAGE_DATE: 'NEXT DOSAGE DATE',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      SECTION_HEADING_MY_VACCINATION_RECORD: 'My Vaccination Record',
    },
    MY_AWARDS_ACHIEVEMENT: {
      BUTTON_TEXT_ADD_AN_AWARD: 'Add an Award',
      BUTTON_TEXT_ADD_AN_ACHIEVEMENT: 'Add an Achievement',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_ADD_AN_EDUCATIONAL_ACHIEVEMENT: 'Add an Educational Achievement',
      BUTTON_TEXT_ADD_AN_PROFESSIONAL_ACHIEVEMENT: 'Add a Professional Achievement',
      BUTTON_TEXT_ADD_AN_MISCELLANEOUS_ACHIEVEMENT: 'Add a Miscellaneous Achievement',
      CONTROL_LABEL_NAME_OF_THE_AWARD: 'Name of the Award',
      CONTROL_LABEL_NAME_OF_THE_ACHIEVEMENT: 'Name of the Achievement',
      CONTROL_LABEL_OCCASION: 'Occasion',
      CONTROL_LABEL_DATE_RECEIVED: 'Date Received',
      CONTROL_LABEL_OCCASION_NOTES: 'Occasion Notes',
      DIALOG_TITLE_ADD_ACHIEVEMENT: 'Add an Achievement',
      DIALOG_TITLE_EDIT_ACHIEVEMENT: 'Edit an Achievement',
      DIALOG_TITLE_ADD_EDUCATIONAL_ACHIEVEMENT: 'Add an Educational Achievement',
      DIALOG_TITLE_ADD_PROFESSIONAL_ACHIEVEMENT: 'Add a Professional Achievement',
      DIALOG_TITLE_ADD_MISCELLANEOUS_ACHIEVEMENT: 'Add a Miscellaneous Achievement',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_AWARD: 'NAME OF THE AWARD',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_ACHIEVEMENT: 'NAME OF THE ACHIEVEMENT',
      DATA_TABLE_HEADER_TEXT_OCCASION: 'OCCASION',
      DATA_TABLE_HEADER_TEXT_OCCASION_NOTES: 'OCCASION NOTES',
      DATA_TABLE_HEADER_TEXT_DATE_RECEIVED: 'DATE RECEIVED',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      ERROR_MESSAGE_REQUIRED_ACHIEVEMENT_NAME: 'The achievement name is required!',
      SECTION_HEADING_MY_AWARDS: 'My Awards',
      SECTION_HEADING_MY_ACHIEVEMENT: 'My Achievements',
      SECTION_HEADING_MY_EDUCATIONAL_ACHIEVEMENT: 'My Educational Achievements',
      SECTION_HEADING_MY_PROFESSIONAL_ACHIEVEMENT: 'My Professional Achievements',
      SECTION_HEADING_MY_MISCELLANEOUS_ACHIEVEMENT: 'My Miscellaneous Achievements',
      SECTION_TEXT: 'Add some information about your achievements.',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_ACHIEVEMENT_NAME: 'Enter Name of the Achievement',
      TOOLTIP_TEXT_OCCASION_EVENT_NAME: 'Enter the Occasion or Event Name where you completed the achievement',
      TOOLTIP_TEXT_ACHIEVEMENT_DATE: 'Enter the date the achievement was completed',
      TOOLTIP_TEXT_ACHIEVEMENT_NOTES: 'Enter any specific notes about this achievement',
      TOAST_MESSAGE_ACHIEVEMENT_ADDED_SUCCESSFULLY: 'Achievement Added Successfully',
      TOAST_MESSAGE_ACHIEVEMENT_UPDATED_SUCCESSFULLY: 'Achievement Updated Successfully',
      TAB_HEADING_MY_AWARDS: 'My Awards',
      TAB_HEADING_MY_ACHIEVEMENT: 'My Achievements',

    },
    MY_MEDICATION_FORM: {
      BUTTON_TEXT_ADD_A_MEDICATION: 'Add a Medication',
      BUTTON_TEXT_CANCEL: 'Cancel',
      CONTROL_LABEL_MEDICATION_NAME: 'Medication Name',
      CONTROL_LABEL_CONDITION_FOR: 'Condition For',
      CONTROL_LABEL_DOSAGE: 'Dosage',
      CONTROL_LABEL_FREQUENCY: 'Frequency',
      CONTROL_LABEL_PRESCRIBED_BY: 'Prescribed By',
      CONTROL_LABEL_REFILL_DURATION: 'Refill Duration',
      CONTROL_LABEL_LAST_REFILL_DATE: 'Last Refill Date',
      CONTROL_LABEL_NEXT_REFILL_DATE: 'Next Refill Date',
      CONTROL_LABEL_NOTES: 'Notes',
      DIALOG_TITLE_ADD_MEDICATION: 'Add a Medication',
      DIALOG_TITLE_EDIT_MEDICATION: 'Edit a Medication',
      ERROR_MESSAGE_REQUIRED_MEDICATION_NAME: 'The medication name is required!',
      ERROR_MESSAGE_REQUIRED_CONDITION_FOR: 'The condition for is required!',
      ERROR_MESSAGE_REQUIRED_DOSAGE: 'The dosage is required!',
      ERROR_MESSAGE_REQUIRED_FREQUENCY: 'The frequency is required!',
      ERROR_MESSAGE_REQUIRED_PRESCRIBED_BY: 'The prescribed by is required!',
      ERROR_MESSAGE_REQUIRED_REFILL_DURATION: 'The refill duration is required!',
      PLACEHOLDER_SELECT_PROFESSIONAL_NAME: "Select Professional's Name",
      PLACEHOLDER_SELECT_REFILL_DURATION: 'Select Refill Duration',
      SECTION_TEXT: 'Add some information about your medications.',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_MEDICATION_NAME: 'The Name of the Medication that is prescribed or taken by you',
      TOOLTIP_TEXT_MEDICAL_CONDITION: 'The Medical Condition or symptom that the medication is intended to treat or prevent',
      TOOLTIP_TEXT_AMOUNT_OF_MEDICATION: 'The Amount of Medication that is given or taken at one time',
      TOOLTIP_TEXT_MEDICATION_FREQUENCY: 'The Number of times or intervals that the medication is given or taken in a day or a week',
      TOOLTIP_TEXT_PRESCRIBING_DOCTOR: 'The Name of the doctor or health care provider who prescribed the medication to you',
      TOOLTIP_TEXT_REFILL_SUPPLY_DURATION: 'How many days / weeks / months till the next refill supply',
      TOOLTIP_TEXT_LAST_SUPPLY_DATE: 'The Date when you last received a new supply of the medication from the pharmacy or the health care provider',
      TOOLTIP_TEXT_NEXT_SUPPLY_DATE: 'The Date when you need a new supply of the medication from the pharmacy or the health care provider',
      TOOLTIP_TEXT_MEDICATION_NOTES: 'Add any specific notes that you have for this medication',
      TOAST_MESSAGE_MEDICATION_ADDED_SUCCESSFULLY: 'Medication Added Successfully',
      TOAST_MESSAGE_MEDICATION_UPDATED_SUCCESSFULLY: 'Medication Updated Successfully'
    },
    MY_VACCINATION_FORM: {
      BUTTON_TEXT_ADD_A_VACCINATION: 'Add a Vaccination',
      BUTTON_TEXT_CANCEL: 'Cancel',
      CONTROL_LABEL_VACCINATION_NAME: 'Vaccination Name',
      CONTROL_LABEL_OTHER: 'Other',
      CONTROL_LABEL_VACCINATION_TYPE: 'Vaccination Type',
      CONTROL_LABEL_LAST_DOSAGE_DATE: 'Last Dosage Date',
      CONTROL_LABEL_NEXT_DOSAGE_DATE: 'Next Dosage Date',
      CONTROL_LABEL_NOTES: 'Notes',
      DIALOG_TITLE_ADD_VACCINATION: 'Add a Vacciantion',
      DIALOG_TITLE_EDIT_VACCINATION: 'Edit Vaccination',
      ERROR_MESSAGE_REQUIRED_VACCINATION_NAME: 'The vaccination name is required!',
      ERROR_MESSAGE_REQUIRED_OTHER: 'The "other" is required!',
      ERROR_MESSAGE_REQUIRED_VACCINATION_TYPE: 'The vaccination type is required!',
      ERROR_MESSAGE_REQUIRED_LAST_DOSAGE_DATE: 'The last dosage is required!',
      ERROR_MESSAGE_REQUIRED_NEXT_DOSAGE_DATE: 'The next dosage is required!',
      PLACEHOLDER_SELECT_VACCINATION_NAME: "Select Vaccination Name",
      SECTION_TEXT: 'Add some information about your vaccinations.',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_INFO_VACCINATION_NAME:'The Name of the vaccine that is given or taken by you',
      TOOLTIP_INFO_VACCINATION_TYPE:'Some vaccines also require multiples & booster shots; You can add as #1 or #2 individually',
      TOOLTIP_INFO_LAST_DOSAGE:'The Date when the patient last received a dose of the vaccine from the health care provider',
      TOOLTIP_INFO_NEXT_DOSAGE:'The Date when the patient needs to receive the next dose of the vaccine from the health care provider.',
      TOOLTIP_INFO_VACCINATION_NOTES:'Any other notes you might want to add about this vaccination record such as symptoms, reactions etc.',
      TOAST_MESSAGE_VACCINATION_ADDED_SUCCESSFULLY: 'Vaccination Added Successfully',
      TOAST_MESSAGE_VACCINATION_UPDATED_SUCCESSFULLY: 'Vaccination Updated Successfully'
    },
    AWARD: {
      BUTTON_TEXT_ADD_AN_AWARD: 'Add an Award',
      BUTTON_TEXT_CANCEL: 'Cancel',
      CONTROL_LABEL_NAME_OF_THE_AWARD: 'Name of the Award',
      CONTROL_LABEL_OCCASION: 'Occasion',
      CONTROL_LABEL_DATE_RECEIVED: 'Date Received',
      CONTROL_LABEL_OCCASION_NOTES: 'Occasion Notes',
      DIALOG_TITLE_ADD_AWARD: 'Add an Award',
      DIALOG_TITLE_EDIT_AWARD: 'Edit an Award',
      ERROR_MESSAGE_REQUIRED_AWARD_NAME: 'The award name is Required!',
      ERROR_MESSAGE_REQUIRED_OCCASION: 'The occasion is required!',
      ERROR_MESSAGE_REQUIRED_DATE_RECEIVED: 'The date received is required!',
      ERROR_MESSAGE_REQUIRED_OCCASION_NOTES: 'The occasion notes is required!',
      SECTION_TEXT: 'Add some information about your awards.',
      TOOLTIP_TOGGLE_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_AWARD_NAME: 'Enter Name of the Award',
      TOOLTIP_TEXT_OCCASION_EVENT_NAME: 'Enter the Occasion or Event Name where you received the award',
      TOOLTIP_TEXT_AWARD_DATE: 'Enter the Date, the award was received',
      TOOLTIP_TEXT_AWARD_NOTES: 'Enter any specific notes about this award',
      TOAST_MESSAGE_AWARD_ADDED_SUCCESSFULLY: 'Award Added Successfully',
      TOAST_MESSAGE_AWARD_UPDATED_SUCCESSFULLY: 'Award Updated Successfully'
    },
    MY_FAMILIES: {
      BUTTON_TEXT_SEARCH_ON_MAP: 'Search On Map',
      BUTTON_TEXT_ADD_AN_OWN_FAMILY_MEMBER: 'Add an Own Family Member',
      BUTTON_TEXT_ADD_A_BIRTH_FAMILY_MEMBER: 'Add a Birth Family Member',
      BUTTON_TEXT_ADD_AN_OTHER_FAMILY_MEMBER: 'Add an Other Family Member',
      BUTTON_TEXT_ADD_A_FRIEND: 'Add a Friend',
      BUTTON_TEXT_ADD_OWN_FAMILY: 'Add a Family Member',
      BUTTON_TEXT_ADD_BIRTH_FAMILY: 'Add a Birth Family Member',
      BUTTON_TEXT_ADD_OTHER_FAMILY: 'Add an Other Family Member',
      CONTROL_LABEL_FIRST_NAME: 'First Name',
      CONTROL_LABEL_MIDDLE_NAME: 'Middle Name(s)',
      CONTROL_LABEL_LAST_NAME: 'Last Name',
      CONTROL_LABEL_DOB: 'Date of Birth',
      CONTROL_LABEL_CONTACT1: 'Contact Number1',
      CONTROL_LABEL_CONTACT2: 'Alternate Contact Number',
      CONTROL_LABEL_ADDRESS1: 'Street Name',
      CONTROL_LABEL_ADDRESS2: 'Address Line 2',
      CONTROL_LABEL_ADDRESS3: 'Address Line 3',
      CONTROL_LABEL_REGION: 'Region',
      CONTROL_LABEL_COUNTRY: 'Country',
      CONTROL_LABEL_ADDRESS_STATE: 'State',
      CONTROL_LABEL_ADDRESS_TOWN: 'Town / City',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'Post / Zip Code',
      CONTROL_LABEL_RELATIONSHIP: 'Relationship',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      CONTROL_LABEL_PERSONAL_URL: 'Personal Web URL',
      CONTROL_LABEL_NOOSOM_URL: 'Noosom Web URL',
      DATA_TABLE_HEADER_TEXT_FIRST_NAME: 'FIRST NAME',
      DATA_TABLE_HEADER_TEXT_LAST_NAME: 'LAST NAME',
      DATA_TABLE_HEADER_TEXT_DATE_OF_BIRTH: 'DATE OF BIRTH',
      DATA_TABLE_HEADER_TEXT_RELATIONSHIP: 'RELATIONSHIP',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'EMAIL ADDRESS',
      DATA_TABLE_HEADER_TEXT_PERSONAL_WEB_URL: 'PERSONAL WEB URL',
      DATA_TABLE_HEADER_TEXT_NOOSOM_WEB_URL: 'NOOSOM WEB URL',
      DATA_TABLE_HEADER_TEXT_LINKED: 'LINKED?',
      DIALOG_TITLE_ADD_OWN_FAMILY_MEMBERS: 'Add an Own Family Member',
      DIALOG_TITLE_ADD_BIRTH_FAMILY_MEMBERS: 'Add a Birth Family Member',
      DIALOG_TITLE_ADD_OTHER_FAMILY_MEMBERS: 'Add an Other Family Member',
      DIALOG_TITLE_ADD_A_FRIEND: 'Add a Friend',
      DIALOG_TITLE_EDIT_A_FRIEND: 'Edit a Friend',
      DIALOG_TITLE_EDIT_FAMILY_MEMBERS: 'Edit a Family Member',
      PLACEHOLDER_SELECT_REGION: 'Select Region',
      PLACEHOLDER_SELECT_COUNTRY: 'Select Country',
      PLACEHOLDER_SELECT_STATE: 'Select State',
      SECTION_HEADING_MY_OWN_FAMILY: 'My Own Family',
      SECTION_HEADING_MY_BIRTH_FAMILY: 'My Birth Family',
      SECTION_HEADING_MY_OTHER_FAMILY: 'My Other Family',
      SECTION_HEADING_MY_FRIEND: 'My Friends',
      SECTION_TEXT: 'Add information about your family member; you can invite them to create a Noosom account by sending them an email if they do not have a Noosom Profile with us.',
      SECTION_TEXT_FRIEND: 'Add information about your friend; you can invite them to create a Noosom account by sending them an email if they do not have a Noosom Profile with us.',
      TOAST_MESSAGE_FAMILY_UPDATED_SUCCESSFULLY: 'Family Member Updated Successfully',
      TOAST_MESSAGE_FAMILY_ADDED_SUCCESSFULLY: 'Family Member Added Successfully',
      TOAST_MESSAGE_FRIEND_UPDATED_SUCCESSFULLY: 'Friend Updated Successfully',
      TOAST_MESSAGE_FRIEND_ADDED_SUCCESSFULLY: 'Friend Added Successfully',
      TOAST_MESSAGE_CONNECTION_MAIL_SUCCESSFULLY:'Connection mail send Successfully.',
      TOOLTIP_BIRTH_FAMILY: 'Mother, Father, Grand Mother, Grand Father, Brother, Sister',
      TOOLTIP_OWN_FAMILY: 'Spouse, Husband, Children',
      TOOLTIP_OTHER_FAMILY: 'Cousins, Extended Family',
      TOOLTIP_TEXT_FIRST_NAME: 'Enter First Name',
      TOOLTIP_TEXT_MIDDLE_NAME: 'Enter Middle Name(s) if you have any',
      TOOLTIP_TEXT_LAST_NAME: 'Enter Last Name',
      TOOLTIP_TEXT_DATE_OF_BIRTH: 'Enter Date of Birth',
      TOOLTIP_TEXT_RELATIONSHIP: 'How is this person related to you? Are they your mother, father, sister(s) or brother(s)?',
      TOOLTIP_TEXT_EMAIL: 'Enter the Family Member\'s Email Address',
      TOOLTIP_TEXT_FRIENDS_EMAIL:'Enter your friend\'s Email Address',
      TOOLTIP_TEXT_PERSONAL_URL: 'This is the Family Member\'s Personal Web URL or their Company / Business URL',
      TOOLTIP_TEXT_PERSONAL_URL1: 'This is your friend\'s Personal Web URL or their Company / Business URL',
      TOOLTIP_TEXT_NOOSOM_URL: 'Enter the Family Member\'s Noosom URL to send them a connection link to view their Noosom Blog',
      TOOLTIP_TEXT_NOOSOM_URL1: 'Enter the Friend\'s Noosom URL to send them a connection link to view their Noosom Blog',
      TOOLTIP_TEXT_MOBILE: 'Enter Mobile No. with the area code',
      TOOLTIP_TEXT_ALTERNATE_CONTACT: 'Enter an alternate contact no. with area code',
      TOOLTIP_TEXT_STREET_NAME: 'This is a required field for mapping your location accurately; please enter the street name correctly',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'Enter the Town, Village, or City of the address',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'This is the Postal Zipcode associated with the address\'s country',
      TOOLTIP_TEXT_REGION_CONTINENT: 'Choose the Region or Continent of the address; for example, United Kingdom will be in Europe',
      TOOLTIP_TEXT_COUNTRY: 'Select the Country of the address; for example, England will be in Europe',
      TOOLTIP_TEXT_STATE_PROVINCE: 'Choose the State or Province of the address; for example, Rotuma is a state or island in Fiji',
      TOOLTIP_TEXT_FAMILY_MEMBER_NOTES: 'Enter any other notes you might have for this family member',
      TOOLTIP_TEXT_BIRTH_FAMILY_INFO:'This is the family you were born into, Mother, Father, Brother Sister etc',
      TOOLTIP_TEXT_OWN_FAMILY_INFO:'Your family by marriage, wife, husband, children, grand children',
      TOOLTIP_TEXT_OTHER_FAMILY_INFO:'In laws, aunties, uncles, nieces, nephews etc',
      TOOLTIP_TEXT_CONNECTION_STATUS:'Connection Status'
    },
    MY_JOBS: {
      BUTTON_TEXT_ADD_A_NEW_JOBS: 'Add a New Job',
      BUTTON_TEXT_SEARCH_ON_MAP: 'Search On Map',
      CONTROL_LABEL_JOB_DESCRIPTION: 'Job Description',
      CONTROL_LABEL_EMPLOYER: 'Employer',
      CONTROL_LABEL_ADDRESS1: 'Street Name',
      CONTROL_LABEL_ADDRESS2: 'Address Line 2',
      CONTROL_LABEL_ADDRESS3: 'Address Line 3',
      CONTROL_LABEL_REGION: 'Region',
      CONTROL_LABEL_COUNTRY: 'Country',
      CONTROL_LABEL_ADDRESS_STATE: 'State',
      CONTROL_LABEL_ADDRESS_TOWN: 'Town / City',
      CONTROL_LABEL_ADDRESS_POSTALCODE: 'Post / Zip Code',
      CONTROL_LABEL_CURRENT_JOBS: 'Current Job',
      CONTROL_LABEL_DATE_FROM: 'Date From',
      CONTROL_LABEL_DATE_TO: 'Date To',
      DATA_TABLE_HEADER_TEXT_EMPLOYER: 'EMPLOYER',
      DATA_TABLE_HEADER_TEXT_JOB_DESCRIPTION: 'JOB DESCRIPTION',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'DATE FROM',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'DATE TO',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'ACTIONS',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'ADDRESS',
      DATA_TABLE_HEADER_TEXT_NOTES: 'NOTES',
      DIALOG_TITLE_EDIT_JOBS: 'Edit a Job',
      ERROR_MESSAGE_JOB_FROM_DATE_REQUIRED: 'The date from is required!',
      ERROR_MESSAGE_DATE_TO_REQUIRED: 'The date to is required!',
      PLACEHOLDER_SELECT_REGION: 'Select Region',
      PLACEHOLDER_SELECT_COUNTRY: 'Select Country',
      PLACEHOLDER_SELECT_STATE: 'Select State',
      SECTION_HEADING_MY_JOB: 'My Jobs',
      SECTION_TEXT_JOBS: 'Add information about your past or current jobs',
      TOAST_MESSAGE_ADDRESS_ADDED_SUCCESSFULLY: 'My Jobs Added Successfully',
      TOAST_MESSAGE_ADDRESS_UPDATED_SUCCESSFULLY: 'My Jobs Updated Successfully',
      TOOLTIP_TEXT_EMPLOYER_NAME: 'Enter the Name of your Employer or Company',
      TOOLTIP_TEXT_JOB_ROLE: 'Enter information about your role(s) at this job',
      TOOLTIP_TEXT_CURRENT_JOB: 'If current - check this box',
      TOOLTIP_TEXT_JOB_START_DATE: 'Enter the Date the job was started',
      TOOLTIP_TEXT_JOB_END_DATE: 'Enter the Date the job was ended',
      TOOLTIP_TEXT_STREET_NAME: 'This is a required field for mapping your location accurately; please enter the street name correctly',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'Enter the Town, Village, or City of the address',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'This is the Postal Zipcode associated with the address\'s country',
      TOOLTIP_TEXT_REGION_CONTINENT: 'Choose the Region or Continent of the address; for example, United Kingdom will be in Europe',
      TOOLTIP_TEXT_COUNTRY: 'Select the Country of the address; for example, England will be in Europe',
      TOOLTIP_TEXT_STATE_PROVINCE: 'Choose the State or Province of the address; for example, Rotuma is a state or island in Fiji',
      TOOLTIP_TEXT_JOB_NOTES: 'Enter any other notes you might have for this job',
      WARNING_MESSAGE_SELECT_LOCATION: 'Please select location on a map.',
    }
  }
};
