import { AfterViewInit, Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { locale as english } from 'app/insurance/add-insurance/i18n/en';
import { locale as french } from 'app/insurance/add-insurance/i18n/fr';
import { locale as german } from 'app/insurance/add-insurance/i18n/de';
import { locale as portuguese } from 'app/insurance/add-insurance/i18n/pt';
import { locale as gujarati } from 'app/insurance/add-insurance/i18n/gj';
import { locale as hindi } from 'app/insurance/add-insurance/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { retry, takeUntil } from 'rxjs/operators';
import { AddInsuranceService } from './add-insurance.service'
import { Observable, Subject } from 'rxjs';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Constant } from 'app/Classes/constant';
import { InsuranceLoadDataOutputModel, TblUserInsurances, InsuranceModel, InsuranceDocumentListOutputModel } from './insurance.model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { AuthenticationService } from 'app/auth/service';
import { E } from 'assets/extensions/extensions';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { GlobalService } from 'app/Services/global.service';
import { Status, Enums, MapModalType, RecordsType } from 'app/enums';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { CountryISO } from 'ngx-intl-tel-input';
import Swal from 'sweetalert2';
import { DeleteInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { _ } from 'ag-grid-community';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SelectSyncFileManagerComponent } from 'app/sync-file-manager/select-sync-file-manager/select-sync-file-manager.component';

@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.scss']
})
export class AddInsuranceComponent implements OnInit, AfterViewChecked {
  @ViewChild('InsuranceType') InsuranceType:NgSelectComponent;
  @ViewChild('notes') notes:ElementRef;
  @ViewChild('nav') nav: NgbNav;
  @ViewChild('fileInput') fileInput;
  _ImageUrlAPI: string = Constant.API + 'api/UserInsurance/UploadUserInsuranceDocuments';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderInsurance",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["pdf"]//, "doc", "docx", "xls", "xlsx", "ppt"
  });
  public loading = false;
  public submitted = false;
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  public coreConfig: any;
  public dialogTitle: string;
  public frmInsurance: FormGroup;
  public IsForEdit: boolean = false;
  public IsForAbandon: boolean = false;
  public error = '';
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  currentAppLanguage: any;
  _data: number = 0;
  private _unsubscribeAll: Subject<any>;
  SelectedCountryISO: CountryISO;
  insuranceLoadDataOutputModel: InsuranceLoadDataOutputModel = new InsuranceLoadDataOutputModel();
  userInsurance: TblUserInsurances;
  insurance: InsuranceModel;
  public DocumentList: InsuranceDocumentListOutputModel[] = [];
  public DocumentList1: InsuranceDocumentListOutputModel[] = [];
  public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  public downloadFileInputModel: DownloadFileInputModel;
  deleteInputModel: DeleteInputModel = new DeleteInputModel();
  public tab = 1;
  infoTooltip: string;
  dateformat: string;
  uploadCount=0;
  constructor(
    private dialogRef: MatDialogRef<AddInsuranceComponent>,
    private _coreTranslationService: CoreTranslationService,
    private addInsurance: AddInsuranceService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private _toastrService: CustomToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private _router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.InsuranceType)
      this.InsuranceType.focus();
     if(this.notes){
      this.notes.nativeElement.focus();
     }
    }, 500);
   
  }
  ngOnInit(): void {
    // console.log(this._ImageUrlAPI)
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.INSURANCE.DIALOG_TITLE_ADD_INSURANCE;
    this.infoTooltip= this.currentAppLanguage.data.INSURANCE.SECTION_TEXT;
    this.frmInsurance = this._formBuilder.group({
      InsuranceTypeId: [, Validators.required],
      InsuranceName: [, Validators.required],
      PolicyNumber: [],
      CardNumber: [],
      ContactNumber: [],
      PolicyStartDate: [],
      PolicyEndDate: [],
      Notes: [],
    });
    //console.log(this.frmInsurance)
    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserInsuranceID', value: E.ConvertToString(this._data) }]
      });
      //this.uploader.setOptions({ url: fileItem.url + fileItem.file.name });
      return fileItem;
    };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   alert('File uploaded');
    // };
    this.uploader.response.subscribe(result => {
      // console.log(result)
      if (result) {
        this.loadingScreenService.startLoading();
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
          this.loadingScreenService.startLoading();
          this.uploaderWarning=true;
         // console.log(this.uploaderWarning)
      }else if(this.uploader.progress==100){
        this.uploaderWarning=false;
        this.forgotuploadWarning=false;
        // this.onSubmit(tabs)
        //this.uploadCount = 0;
        this.loadingScreenService.stopLoading();
      }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _InsuranceDocument = <InsuranceDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
          this.DocumentList.push(_InsuranceDocument);
          this.DocumentList1.push(_InsuranceDocument);
        }
        else if(_result.status == Status.StorageFull){
          // this.uploader.destroy();
          this.loadingScreenService.stopLoading();
           this.uploader.cancelAll();
           // this.uploader.removeFromQueue();
           // this.uploader.cancelItem()
           this.uploader.clearQueue()
           Swal.fire({
             title: "Storage Full",
             text: _result.message,
             icon: 'error',
             showCancelButton: true,
             confirmButtonColor: '#7367F0',
             cancelButtonColor: '#E42728',
             confirmButtonText: "Buy More Storage",
             cancelButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CANCEL_BUTTON_TEXT,
             customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-danger ml-1'
             }
           })
           .then(
             (async (result) => {
               if (result.value) {
                 //this.uploader.clearQueue();
                 this.dialogRef.close(true);
                 this._router.navigate(['/pages/pricing'])
               }
             })
           );
               
         }
      }
      this.loadingScreenService.stopLoading();
    });

    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.INSURANCE.DIALOG_TITLE_EDIT_INSURANCE;
      }
      else {
        //Add Mode
        if (this.authenticationService.currentUserValue.CountryShortCode) {
          this.SelectedCountryISO = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode);
        }
      }
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        let response: Observable<any>;
        //console.log(this._data)
        this.addInsurance.GetUserInsuranceLoadData(this._data)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              // console.log(data);
              if (data.status == Status.Success) {
                this.insuranceLoadDataOutputModel = <InsuranceLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));

                if (this.insuranceLoadDataOutputModel.InsuranceDocumentList) {
                  this.insuranceLoadDataOutputModel.InsuranceDocumentList.forEach((doc) => {
                    this.DocumentList.push(doc);
                  });
                }

                if (this.insuranceLoadDataOutputModel.UserInsurance) {
                  this.userInsurance = this.insuranceLoadDataOutputModel.UserInsurance;
                  //console.log(this.userInsurance);
                  this.frmInsurance.patchValue({
                    InsuranceTypeId: this.userInsurance.InsuranceTypeId,
                    InsuranceName: this.userInsurance.InsuranceName,
                    PolicyNumber: this.userInsurance.PolicyNumber,
                    CardNumber: this.userInsurance.CardNumber,
                    ContactNumber: this.userInsurance.ContactNumber,
                    PolicyStartDate: E.ConvertToDate(this.userInsurance.PolicyStartDate),
                    PolicyEndDate: E.ConvertToDate(this.userInsurance.PolicyEndDate),
                    Notes: this.userInsurance.Notes,
                    // SelectedCountryISO :this.userInsurance.ContactNumberCountryCode.toLocaleUpperCase(),
                  });
                  if (this.userInsurance.ContactNumberCountryCode)
                    this.SelectedCountryISO = this.globalService.GetCountryISOObject(this.userInsurance.ContactNumberCountryCode);
                   else{
                    this.SelectedCountryISO = this.globalService.GetCountryISOObject(this.authenticationService.currentUserValue.CountryShortCode)
                   }
                  // console.log(this.SelectedCountryISO);
                }
              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );
      }, 500);
      //}
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });

  }
  checkQueueLength(queuLength){
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
          this.uploaderWarning=false;
        }
  }

  onTabActive(tabNumber:number){
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.INSURANCE.SECTION_TEXT;}
    if(tabNumber==2){
      this.infoTooltip= this.currentAppLanguage.data.INSURANCE.SECTION_TEXT3;
      setTimeout(() => {
      // console.log(this.notes)
      if (this.notes)
      this.notes.nativeElement.focus();
    },500)
  }
  else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.INSURANCE.SECTION_TEXT1;}
  else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.INSURANCE.SECTION_TEXT2;}
    this.tab=tabNumber;
  }

  onFileSelected(event){
    //console.log(event)
    this.uploader.uploadAll();
  }

  onSubmit(tabs: boolean) {
    this.frmInsurance.controls['PolicyStartDate'].setErrors(null);
    this.frmInsurance.controls['PolicyEndDate'].setErrors(null);

    if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
    this.submitted = true;
    //console.log(this.frmInsurance);
    if (this.frmInsurance.invalid) {
      // console.log('this.frmInsurance.invalid');
      return;
    }
   // console.log(this.frmInsurance.invalid);
    this.submitted = false;
    // console.log(this.userInsurance)
    if (this.userInsurance == null) {
      this.userInsurance = new TblUserInsurances();
    }
    this.userInsurance.InsuranceTypeId = this.frmInsurance.get('InsuranceTypeId').value;
    this.userInsurance.InsuranceName = this.frmInsurance.get('InsuranceName').value;
    this.userInsurance.PolicyNumber = this.frmInsurance.get('PolicyNumber').value;
    this.userInsurance.CardNumber = this.frmInsurance.get('CardNumber').value;
    if(this.frmInsurance.get('ContactNumber').value)
    this.userInsurance.ContactNumber = this.frmInsurance.get('ContactNumber').value.number.replace(/\s/g, "");
    this.userInsurance.PolicyStartDate = E.GetDateStringOnly(this.frmInsurance.get('PolicyStartDate').value);
    this.userInsurance.PolicyEndDate = E.GetDateStringOnly(this.frmInsurance.get('PolicyEndDate').value);
    if(this.frmInsurance.get('ContactNumber').value)
    this.userInsurance.ContactNumberCountryCode = this.frmInsurance.get('ContactNumber').value.countryCode.toLocaleLowerCase();
    this.userInsurance.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
    this.userInsurance.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    this.userInsurance.Notes = this.frmInsurance.get('Notes').value;
    if (E.ConvertToNumber(this.userInsurance.UserInsuranceId) == 0 && this.IsForEdit == false) {
      this.userInsurance.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userInsurance.CreatedDate = E.GetTodayDateStringOnly();
      this.userInsurance.CreatedTime = E.GetTodayTimeStringOnly();
      this.userInsurance.ChangedDate = E.GetTodayDateStringOnly();
      this.userInsurance.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userInsurance.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userInsurance.ChangedDate = E.GetTodayDateStringOnly();
      this.userInsurance.ChangedTime = E.GetTodayTimeStringOnly();
    }
    if (this.tab <= 2 || this.IsForEdit) {
    this.loading = true;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this.addInsurance.CreateUpdateUserInsurance(this.userInsurance)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.INSURANCE.TOAST_MESSAGE_INSURANCE_ADDED_SUCCESSFULLY;
            this.tab = this.tab + 1;
            this.nav.select(this.tab)
            if(this.tab==2){
              setTimeout(() => {
              // console.log(this.notes)
              if (this.notes)
              this.notes.nativeElement.focus();
            },500)
          }
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserInsuranceId
            this.userInsurance.UserInsuranceId=parsedObject.Data.UserInsuranceId
            this.IsForAbandon=true;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.INSURANCE.TOAST_MESSAGE_INSURANCE_UPDATED_SUCCESSFULLY;
            if(!tabs)
            {this.tab=this.tab+1
            this.nav.select(this.tab)}
          }
          else {
            errormessage = E.ConvertToString(result.message);
            // console.log(errormessage);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            if ((this.tab == 1) || tabs) {
            this.dialogRef.close();
            this.frmInsurance.reset();
            this.frmInsurance.patchValue({
              PolicyStartDate: new Date(),
              PolicyEndDate: new Date()
            });}
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else if (this.tab >= 2 && this.tab <= 4) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      if (tabs) {
        this.dialogRef.close(true);
        this.frmInsurance.reset();
      }
    }
  }

  async onDownloadAllDocument() {
    for (let index = 0; index < this.DocumentList.length; index++) {
      const _doc = this.DocumentList[index];
      var _res = await this.DownloadMyInsuranceDocument(_doc, true);
      // console.log('_res');
      // console.log(_res);
      if (_res) {
        if ((index + 1) === this.DocumentList.length) {
          this._toastrService.Success('Downloaded All Successfully.', Constant.SuccessToastrTitle);
        }
      } else {
        break;
      }
    }
  }

  async onDownloadDocument(document: InsuranceDocumentListOutputModel) {
    if (document) {
      await this.DownloadMyInsuranceDocument(document);
    }
  }

  async DownloadMyInsuranceDocument(Document: InsuranceDocumentListOutputModel, IsDownloadAll = false) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.DocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      // const path = 'api/UserInsurance/DownloadUserInsuranceDocuments';
      let response: Observable<any>;
      // response = this.globalService.PostDataById(this.downloadFileInputModel, path);
      this.addInsurance.DownloadUserInsuranceDocuments(this.downloadFileInputModel)
        .subscribe(
          (result) => {
            this.loadingScreenService.stopLoading();
            // console.log('result');
            // console.log(result.status);
            // console.log(Status.Success);
            if (result.status == Status.Success) {
              let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
              var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
              this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
              if (IsDownloadAll === false)
                this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
              //console.log(this.DocumentList);
              Result = true;
            }
            else {
              this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
              Result = false;
            }
            resolve(Result);
          },
          (error) => {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            this._toastrService.Error(error, Constant.ErrorToastrTitle);
            this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
            this.loadingScreenService.stopLoading();
            resolve(Result);
          }
        );
    });
    return await promise;
  }

  async onDeleteAllDocument() {
    let UserInsuranceDocumentIds: number[] = [];
    if (this.DocumentList) {
      this.DocumentList.forEach(_doc => {
        UserInsuranceDocumentIds.push(_doc.DocumentId);
      });
      if (UserInsuranceDocumentIds.length > 0) {
        Swal.fire({
          title: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT,
          text: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TEXT,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
          cancelButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CANCEL_BUTTON_TEXT,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .then(
            (async (result) => {
              if (result.value) {
                const _res = await this.DeleteMyInsuranceDocument(UserInsuranceDocumentIds);
              }
            })
          );
      }
    }
  }

  async onDeleteDocument(userInsuranceDocumentId: number) {
    console.log(userInsuranceDocumentId)
    let UserInsuranceDocumentIds: number[] = [];
    if (userInsuranceDocumentId) {
      UserInsuranceDocumentIds.push(userInsuranceDocumentId);
      Swal.fire({
        title: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT,
        text: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
        cancelButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      })
        .then(
          (async (result) => {
            if (result.value) {
              const a = await this.DeleteMyInsuranceDocument(UserInsuranceDocumentIds);
            }
          })
        );
    }
  }
  async DeleteMyInsuranceDocument(UserInsuranceDocumentIds: Array<number>) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (UserInsuranceDocumentIds.length < 1) {
      return false;
    }
    this.deleteDocumentsInputModel = [];
    UserInsuranceDocumentIds.forEach(userInsuranceDocumentId => {
      this.deleteDocumentsInputModel.push({
        DeleteID: userInsuranceDocumentId,
        UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
      });
    });
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      // const path = 'api/UserInsurance/DeleteUserInsuranceDocuments';
      // let response: Observable<any>;
      // response = this.globalService.PostDataById(this.deleteDocumentsInputModel, path);
      let response: Observable<any>;
      //  console.log('this.userInsurance');
      // console.log(this.userInsurance);
      this.addInsurance.DeleteUserInsuranceDocuments(this.deleteDocumentsInputModel)
        .subscribe(
          (data) => {
            this.loadingScreenService.stopLoading();
            //console.log(data);
            var result = JSON.parse(JSON.stringify(data));
            //console.log(result);
            if (result.status == Status.Success) {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: E.ConvertToString(result.message),
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
              //this.loadData();    //Re-loading User Addresses Grid
              //console.log(this.DocumentList);
              this.DocumentList = this.DocumentList.filter(x => !UserInsuranceDocumentIds.includes(x.DocumentId));
              this.DocumentList1 = this.DocumentList1.filter(x => !UserInsuranceDocumentIds.includes(x.DocumentId));
              //console.log(this.DocumentList);

              Result = true;
            }
            else {
              this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
              Result = false;
            }
            resolve(Result);
          },
          (error) => {
            this._toastrService.Error(error, Constant.ErrorToastrTitle);
            this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
            this.loadingScreenService.stopLoading();
          }
        );
    });
    return await promise;
  }

  async OnInsuranceDeleteClick() {
    //console.log(UserInsuranceId);
    Swal.fire({
      title: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TITLE_INSURANCE_TEXT,
      text: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.INSURANCE.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const UserInsuranceId= this._data
            const a = await this.DeleteMyInsurance(UserInsuranceId);
          }
        })
      );
  }

  async DeleteMyInsurance(UserInsuranceId: number) {
    this.loadingScreenService.startLoading();
    this.deleteInputModel = {
      DeleteID: UserInsuranceId,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      const path = 'api/UserInsurance/DeleteUserInsurance';
      let response: Observable<any>;
      response = this.globalService.PostDataById(this.deleteInputModel, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          //console.log(data);
          var result = JSON.parse(JSON.stringify(data));
          // console.log(result);
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: E.ConvertToString(result.message),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            Result = true;
            this.dialogRef.close(true);
            this.frmInsurance.reset();
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  OnPolicyExpirationDateChange(event) {
  //  console.log(this.insuranceLoadDataOutputModel.UserInsurance)
  //   if (this.insuranceLoadDataOutputModel.UserInsurance.UserInsuranceId){
    let datefrom = this.frmInsurance.get('PolicyStartDate').value;
    let dateto = this.frmInsurance.get('PolicyEndDate').value;
    // console.log(datefrom,dateto)
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmInsurance.get('PolicyStartDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;

    
  }
  onComingSoon() {
    this._toastrService.Info(this.currentAppLanguage.data.INSURANCE.INFO_MESSAGE_COMING_SOON, Constant.InfoToastrTitle);
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  
  openFilemanager(){
    // using for old filemanager uploads
   // this.openDialog(UploadManagerComponent)
   var matdata = {
    RecordId : this._data,
    RecordType: RecordsType.Insurance
   }
   this.openDialog(SelectSyncFileManagerComponent,matdata)
  }
  
  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.height="60%"
    dialogConfig.position={
      top:"10%"
    }
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
            if(data){
             
            }
      }
    );
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  onCloseButtonClick() {
    this.dialogRef.close();
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmInsurance.controls;
  }
}


