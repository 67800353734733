import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'बंद करना',
      BUTTON_TEXT_SAVE:'Save',
      BUTTON_TEXT_SAVE_SEND:'Save and Send',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    MEONWEB: {
      BUTTON_TEXT_BUTTON_ADD_NEW_PASTIME: 'नई वेब प्रविष्टि जोड़ें',
      CONTROL_LABEL_ENTRY_NAME: 'प्रविष्टि का नाम',
      CONTROL_LABEL_DATE_POSTED: 'पोस्ट की तारीख',
      CONTROL_LABEL_URL_WEBSITE: 'वेबसाइट का URL',
      CONTROL_LABEL_DESCRIPTION: 'विवरण',
      CONTROL_LABEL_ACTION: 'क्रियाएँ',
      CONTROL_LABEL_LEGAL_DOCUMENTS: 'कानूनी दस्तावेज़ नाम',
      CONTROL_LABEL_FIELD_ENTRY_NAME: 'प्रविष्टि का नाम',
      CONTROL_LABEL_FIELD_DATE_POSTED: 'प्रविष्टि तिथि',
      CONTROL_LABEL_FIELD_URL_WEBSITE: 'वेबसाइट का URL',
      CONTROL_LABEL_FIELD_DESCRIPTION: 'विवरण',
      CONTROL_LABEL_FIELD_NOTES: 'टिप्पणियाँ',
      CONTROL_LABEL_NOTES: 'टिप्पणियाँ',
      DIALOG_TITLE_ADD_WEB: 'एक वेब प्रविष्टि जोड़ें',
      ERROR_MESSAGE_ENTRY_NAME_REQUIRED: 'प्रविष्टि नाम आवश्यक है!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 50 है',
      ERROR_MESSAGE_MAX_LENGTH_100: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 100 है',
      ERROR_MESSAGE_MAX_LENGTH_120: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 120 है',
      ERROR_MESSAGE_MAX_LENGTH_250: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 250 है',
      ERROR_MESSAGE_MAX_LENGTH_1000: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 1000 है',
      HEADING_LABEL_ME_ON_WEB: 'वेब पर मैं',
      SECTION_HEADING_HI: 'नमस्ते',
      SECTION_HEADING_ABOUT_ME: 'यह आपके बारे में है और आपकी हमारे साथ प्रोफ़ाइल के बारे में सब कुछ',
      TOOLTIP_TEXT_BUTTON_ADD_NEW_RECORD: 'नई रिकॉर्ड जोड़ें',
      TOOLTIP_TEXT_WEB_TITLE: 'एक छोटा शीर्षक',
      TOOLTIP_TEXT_WEB_PUBLICATION_DATE: 'वेब / लेख दर्जी की गई तिथि',
      TOOLTIP_TEXT_WEB_URL: 'प्रकाशित पृष्ठ का वेबसाइट URL या लिंक',
      TOOLTIP_TEXT_WEB_DESCRIPTION: 'इस वेब प्रविष्टि के बारे में एक छोटा विवरण दर्ज करें',
      TOOLTIP_TEXT_WEB_OTHER_INFO: 'इस वेब प्रविष्टि के बारे में कोई अन्य जानकारी जोड़ें',
      TOOLTIP_TEXT_WEB_ENTRY_INFORMATION: 'किसी भी वेब प्रविष्टि के बारे में कुछ जानकारी जोड़ें',
      ERROR_MESSAGE_INVALID_EMAIL: 'Invalid Email Address!',
      ERROR_MESSAGE_EXIST_EMAIL: 'This email address is already connected with a friend, family member, or is your own. Please use a different one.',
      ERROR_MESSAGE_MAX_LENGTH_320: 'You have typed too many characters, maximum allowed is 320',
      CONTROL_LABEL_ADD_EMAIL:'Add Email',
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      TOOLTIP_TEXT_EMAIL: 'Enter the Family Member\'s Email Address',
      TOOLTIP_TEXT_FRIENDS_EMAIL:'Enter your friend\'s Email Address',
      TOAST_MESSAGE_FAMILY_UPDATED_SUCCESSFULLY: 'Family Member Updated Successfully',
      TOAST_MESSAGE_FAMILY_ADDED_SUCCESSFULLY: 'Family Member Added Successfully',
      TOAST_MESSAGE_FRIEND_UPDATED_SUCCESSFULLY: 'Friend Updated Successfully',
      TOAST_MESSAGE_FRIEND_ADDED_SUCCESSFULLY: 'Friend Added Successfully',
      SWEET_ALERT_TITLE_FAMILY_TEXT: 'Are you sure, you want to delete this Family Detail?',
      SWEET_ALERT_TITLE_FRIEND_TEXT: 'Are you sure, you want to delete this Friend Detail?',
      SWEET_ALERT_TITLE_INVITATION_TEXT: 'You are about to send Personname an invitation email to connect with you on Noosom.',
      SWEET_ALERT_TITLE_JOB_TEXT: 'Are you sure, you want to delete this Job Detail?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_NO_BUTTON: 'NO',
      SWEET_ALERT_YES_BUTTON: 'YES',
      SWEET_ALERT_SURE_TEXT: 'Are you sure ?',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
    },
    AWARDS: {
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_AWARD: 'पुरस्कार का नाम',
      DATA_TABLE_HEADER_TEXT_NAME_OF_THE_ACHIEVEMENT: 'उपलब्धि का नाम',
      DATA_TABLE_HEADER_TEXT_OCCASION: 'अवसर',
      DATA_TABLE_HEADER_TEXT_OCCASION_NOTES: 'अवसर नोट्स',
      DATA_TABLE_HEADER_TEXT_DATE_RECEIVED: 'प्राप्ति दिनांक',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'कार्रवाई',
      SECTION_HEADING_MY_ACHIEVEMENT: 'मेरी उपलब्धियाँ',
      SECTION_HEADING_MY_EDUCATIONAL_ACHIEVEMENT: 'मेरी शैक्षिक उपलब्धियाँ',
      SECTION_HEADING_MY_PROFESSIONAL_ACHIEVEMENT: 'मेरी पेशेवर उपलब्धियाँ',
      SECTION_HEADING_MY_MISCELLANEOUS_ACHIEVEMENT: 'मेरी विविध उपलब्धियाँ',
      SWEET_ALERT_TITLE_AWARD_TEXT: 'क्या आप निश्चित हैं कि आप इस पुरस्कार को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ACHIEVEMENT_TEXT: 'क्या आप निश्चित हैं कि आप इस उपलब्धि को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT: 'आप इसे पूनर्स्थापित नहीं कर पाएंगे!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हाँ, इसे हटा दें!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करें',
      HEADING_MY_AWARDS: 'मेरे पुरस्कार',
      BUTTON_ADD_AN_AWARD: 'एक पुरस्कार जोड़ें',
      BUTTON_TEXT_ADD_AN_ACHIEVEMENT: 'एक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_EDUCATIONAL_ACHIEVEMENT: 'एक शैक्षिक उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_PROFESSIONAL_ACHIEVEMENT: 'एक पेशेवर उपलब्धि जोड़ें',
      BUTTON_TEXT_ADD_AN_MISCELLANEOUS_ACHIEVEMENT: 'एक विविध उपलब्धि जोड़ें',

    },
    MY_JOBS: {
      BUTTON_TEXT_ADD_A_NEW_JOBS: 'नई नौकरी जोड़ें',
      DATA_TABLE_HEADER_TEXT_EMPLOYER: 'नियोक्ता',
      DATA_TABLE_HEADER_TEXT_JOB_DESCRIPTION: 'नौकरी विवरण',
      DATA_TABLE_HEADER_TEXT_DATE_FROM: 'तिथि से',
      DATA_TABLE_HEADER_TEXT_DATE_TO: 'तिथि तक',
      DATA_TABLE_HEADER_TEXT_ACTIONS: 'क्रियाएँ',
      DATA_TABLE_HEADER_TEXT_ADDRESS: 'पता',
      DATA_TABLE_HEADER_TEXT_NOTES: 'टिप्पणियाँ',
      SECTION_HEADING_MY_JOB: 'मेरी नौकरियाँ',
    },
    MY_FAMILIES: {
      BUTTON_TEXT_ADD_AN_OWN_FAMILY_MEMBER: 'अपने परिवार के सदस्य जोड़ें',
      BUTTON_TEXT_ADD_A_BIRTH_FAMILY_MEMBER: 'जन्म के परिवार के सदस्य जोड़ें',
      BUTTON_TEXT_ADD_AN_OTHER_FAMILY_MEMBER: 'अन्य परिवार के सदस्य जोड़ें',
      DATA_TABLE_HEADER_TEXT_FIRST_NAME: 'पहला नाम',
      DATA_TABLE_HEADER_TEXT_LAST_NAME: 'उपनाम',
      DATA_TABLE_HEADER_TEXT_DATE_OF_BIRTH: 'जन्म की तारीख',
      DATA_TABLE_HEADER_TEXT_RELATIONSHIP: 'संबंध',
      DATA_TABLE_HEADER_TEXT_EMAIL_ADDRESS: 'ईमेल पता',
      DATA_TABLE_HEADER_TEXT_PERSONAL_WEB_URL: 'व्यक्तिगत वेब यूआरएल',
      DATA_TABLE_HEADER_TEXT_NOOSOM_WEB_URL: 'नूसोम वेब यूआरएल',
      DATA_TABLE_HEADER_TEXT_LINKED: 'लिंक किया गया?',
      TAB_LINK_TEXT_MY_OWN_FAMILY: 'मेरा खुद का परिवार',
      TAB_LINK_TEXT_MY_BIRTH_FAMILY: 'मेरा जन्म का परिवार',
      TAB_LINK_TEXT_MY_OTHER_FAMILY: 'मेरा अन्य परिवार',
      TOOLTIP_TEXT_BIRTH_FAMILY_INFO: 'यह वह परिवार है जिसमें आप पैदा हुए थे, माँ, पिता, भाई, बहन, आदि',
      TOOLTIP_TEXT_OWN_FAMILY_INFO: 'आपका परिवार शादी द्वारा, पत्नी, पति, बच्चे, पोते-पोतियां',
      TOOLTIP_TEXT_OTHER_FAMILY_INFO: 'ससुराल वाले, चाची-चाचा, भतीजी-भतीजे आदि'
    },
    LEGALS: {
      ADD_TITLE: 'कानूनी दस्तावेज़ जोड़ें',
      EDIT_TITLE: 'कानूनी दस्तावेज़ संपादित करें',
      CONTROL_LABEL_LEGAL: 'कानूनी दस्तावेज़ का नाम',
      CONTROL_LABEL_NOTES: 'नोट्स',
      TAB_HEADING_LEGAL_DETAILS: 'कानूनी विवरण',
      TAB_HEADING_UPLOAD: 'अपलोड',
      TAB_HEADING_DOCUMENT: 'दस्तावेज़',
      TAB_HEADING_IMAGES: 'छवियाँ',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'सहेजें और बंद करें',
      BUTTON_TEXT_SAVE_AND_NEXT: 'सहेजें और आगे',
      BUTTON_TEXT_ABANDON: 'छोड़ें',
      BUTTON_TEXT_CANCEL: 'रद्द करें',
      BUTTON_TEXT_UPLOAD_ALL: 'सभी को अपलोड करें',
      BUTTON_TEXT_PAUSE_ALL: 'सभी को रोकें',
      BUTTON_TEXT_DOWNLOAD_ALL: 'सभी को डाउनलोड करें',
      BUTTON_TEXT_REMOVE_ALL: 'सभी को हटाएं',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तन सहेजें',
      BUTTON_TEXT_ADD_DOCUMENTS: 'दस्तावेज़ जोड़ें',
      COLUMN_HEADING_NAME: 'नाम',
      COLUMN_HEADING_SIZE: 'साइज़',
      COLUMN_HEADING_PROGRESS: 'प्रगति',
      COLUMN_HEADING_STATUS: 'स्थिति',
      COLUMN_HEADING_DATE_CREATED: 'तिथि बनाई गई',
      COLUMN_HEADING_TYPE: 'प्रकार',
      COLUMN_HEADING_ACTIONS: 'क्रियाएँ',
      ERROR_MESSAGE_LEGAL_DOC_NAME: 'कानूनी दस्तावेज़ का नाम आवश्यक है!',
      HEADING_TEXT_SELECT_IMAGES: 'दस्तावेज़ चुनें',
      HEADING_TEXT_UPLOAD_QUEUE: 'अपलोड कतार',
      HEADING_TEXT_QUEUE_PROGRESS: 'कतार प्रगति',
      HEADING_MY_LEGALS: 'मेरे कानूनी दस्तावेज़',
      PLACEHOLDER_CHOOSE_FILES: 'फ़ाइलें चुनें',
      SECTION_TEXT1: 'कुछ दस्तावेज़ चुनें। आप उन्हें एक-एक करके अपलोड कर सकते हैं या एक साथ सभी अपलोड कर सकते हैं।',
      SECTION_TEXT2: 'यहाँ आपके सभी दस्तावेज़ की सूची में प्रदर्शित किए जाएंगे। आप उन्हें व्यक्तिगत रूप से डाउनलोड या हटा सकते हैं।',
      SWEET_ALERT_TITLE_PASTIME_TEXT: 'क्या आपको यकीन है, कि आप इस कानूनी दस्तावेज़ को हटाना चाहते हैं?',
      // SWEET_ALERT_TITLE_HOBBY_TEXT:'Are you sure, you want to delete this Hobby?',
      SWEET_ALERT_TEXT: "आप इसे पूर्ववत करने में सक्षम नहीं होंगे!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'हां, इसे हटा दें!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'रद्द करें',
      CONTROL_LABEL_MULTIPLE: 'बहुत',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इस कानूनी दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इस हॉबी दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इन सभी पाठक दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_ALL_HOBBY_DOCUMENT_TEXT: 'क्या आपको यकीन है, कि आप इन सभी हॉबी दस्तावेज़ को हटाना चाहते हैं?',
      SWEET_ALERT_TITLE_INSURANCE_TEXT: 'क्या आपको यकीन है, कि आप इस कानूनी और इसके साथ संबंधित सभी अनुलग्नकों को हटाना चाहते हैं?',
      SWEET_ALERT_TEXT_DELETED: 'हटा दिया गया!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'ठीक है',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_UNLINK:'हाँ, इसे अनलिंक करें!',
      TEXT_FILE_TYPES: 'अनुमत फ़ाइल प्रकार:',
      TEXT_QUEUE_LENGTH: 'कतार लंबाई:',
      TEXT_MB: 'मेगाबाइट',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'अपलोड करें',
      TOOLTIP_TEXT_BUTTON_REMOVE: 'हटाएं',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'डाउनलोड',
      TOOLTIP_TEXT_BUTTON_VIEW: 'देखें',
      TOOLTIP_TEXT_BUTTON_DELETE: 'हटाएँ',
      TOOLTIP_TOGGLE_CALENDAR: 'कैलेंडर परिवर्तित करें',
      TOOLTIP_TEXT_TEXT_STYLE: 'टेक्स्ट स्टाइल',
      TOOLTIP_TEXT_TEXT_SIZE: 'टेक्स्ट साइज़',
      TOOLTIP_TEXT_TEXT_COLOUR: 'टेक्स्ट रंग',
      TOOLTIP_TEXT_TEXT_ALIGNMENT: 'टेक्स्ट संरेखण',
      TOOLTIP_TEXT_BOLD: 'बोल्ड',
      TOOLTIP_TEXT_ITALIC: 'तिरछा',
      TOOLTIP_TEXT_UNDERLINE: 'रेखांकित करें',
      TOOLTIP_TEXT_LINK: 'लिंक',
      TOOLTIP_TEXT_BULLET_LIST: 'गोली सूची',
      TOOLTIP_TEXT_NUMBERED_LIST: 'संख्यात्मक सूची',
      TOOLTIP_TEXT_CLEAR_FORMAT: 'स्वरुप साफ़ करें',
      TOOLTIP_TEXT_LEGAL_DOCUMENT_INFORMATION: 'अपने किसी भी कानूनी दस्तावेज़ के विवरण के बारे में कुछ जानकारी जोड़ें',
      TOOLTIP_TEXT_UPLOAD_LEGAL_DOCUMENTS: 'कुछ दस्तावेज़ चुनें; आप उन्हें एक-एक करके अपलोड कर सकते हैं या एक साथ सभी अपलोड कर सकते हैं',
      TOOLTIP_TEXT_UPLOADED_LEGAL_DOCUMENTS_DISPLAY: 'यहाँ आपके अपलोड किए गए सभी दस्तावेज़ प्रदर्शित होंगे',
      INFO_TEXT2: 'आपकी सभी अपलोड की गई तस्वीरें यहां प्रदर्शित की जाएगी।',

    }


  }
};
