import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Component, ElementRef, HostListener, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';

import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { NoosomCategoryID, NoosomPlans, RecordsType, Status } from 'app/enums';
import { Constant } from 'app/Classes/constant';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { E } from 'assets/extensions/extensions';
import { locale as english } from 'app/layout/components/menu/i18n/en';
import { locale as french } from 'app/layout/components/menu/i18n/fr';
import { locale as german } from 'app/layout/components/menu/i18n/de';
import { locale as portuguese } from 'app/layout/components/menu/i18n/pt';
import { locale as gujarati } from 'app/layout/components/menu/i18n/gj';
import { locale as hindi } from 'app/layout/components/menu/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchInputModel } from 'app/home/home-model.model';
import { AuthenticationService } from 'app/auth/service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddAddressComponent } from 'app/address/add-address/add-address.component';
import { AddSchoolComponent } from 'app/school/add-school/add-school.component';
import { AddInsuranceComponent } from 'app/insurance/add-insurance/add-insurance.component';
import { AddProfessionalComponent } from 'app/about-me/professional/add-professional/add-professional.component';
import { MyMedicationComponent } from 'app/about-me/my-medication/my-medication.component';
import { MyVaccinationComponent } from 'app/about-me/my-vaccination/my-vaccination.component';
import { AwardComponent } from 'app/about-me/award/award.component';
import { MyAchievementComponent } from 'app/about-me/my-achievement/my-achievement.component';
import { JobsComponent } from 'app/about-me/jobs/jobs.component';
import { FriendsComponent } from 'app/about-me/friends/friends.component';
import { OwnFamilyComponent } from 'app/about-me/own-family/own-family.component';
import { WebEntryComponent } from 'app/me-on-web/web-entry/web-entry.component';
import { MyHobbiesComponent } from 'app/mypastimes/my-hobbies/my-hobbies.component';
import { MyPastimesComponent } from 'app/mypastimes/my-pastimes/my-pastimes.component';
import { UploadComponent } from 'app/quick-post/upload/upload.component';
import { QuickPostComponent } from 'app/quick-post/quick-post/quick-post.component';
import { storage } from 'app/auth/models';
import { GlobalService } from 'app/Services/global.service';
import { LegalsdocumentComponent } from 'app/legals/legalsdocument/legalsdocument.component';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { LightGallery } from 'lightgallery/lightgallery';
import Swal from 'sweetalert2';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbNail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import share from 'lightgallery/plugins/share';
import autoPlay from 'lightgallery/plugins/autoplay';
import fullScreen from 'lightgallery/plugins/fullscreen';
// import comment from 'lightgallery/plugins/comment';
import rotate from 'lightgallery/plugins/rotate';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgvideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html',
  styleUrls: ['./search.scss'],
})
export class NavbarSearchComponent implements OnInit {
  // Public
  @ViewChild('AddressGallery')lightGallery: LightGallery;
  searchText: string = '';
  searchSubject: Subject<string> = new Subject();
  public openSearchRef = false;
  public activeIndex = 0;
  public apiData;
  public pages = [];
  public files = [];
  public contacts = [];
  public pageSearchLimit;
  public RecordsType = RecordsType;
  public dropdown: { key: string, value: number }[] = [];
  public selectedEnums: number[] = [];
  public error = '';
  public attachmentlistimage: any = [];
  settings = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: false,
    slideVideo: true,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [lgZoom, thumbNail, autoPlay, fullScreen, rotate,  videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,] //,comment (commented for the future use)
  };
  private needRefresh = false;
  galleryRefresh:LightGallery;
  cache: { [key: string]: string } = {};
  isDropdownOpen = false;
  plan: number;
  userCategory: number;
  btnNameJorD: string;
  dateformat: string;
  templcstg:storage = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  // Decorators
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;

  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }
  private _unsubscribeAll: Subject<any>;
  public coreConfig: any;
  currentAppLanguage: any;
  @HostListener('document:click', ['$event']) clickout(event) {
   // console.log(event)
    // if (event.target.className === 'content-overlay') {
      if (event.target.className === 'app-content content') {
      this.removeOverlay();
      this.openSearchRef = false;
      this.searchText = '';
    }
  }

  // @HostListener('document:dblclick', ['$event'])
  // onDoubleClickOutside(event: MouseEvent) {
  //   // if (this.openSearchRef && !this.searchArea.nativeElement.contains(event.target)) {
  //   //   this.toggleSearch();
  //   // }
  //  // console.log('abc')
  //   // this.removeOverlay();
  //   // this.openSearchRef = false;
  //   // this.searchText = '';
  //   if (this.openSearchRef === true) {
  //    this.toggleSearch();}
  // }

  /**
   *
   * @param document
   * @param router
   * @param _searchService
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _elementRef: ElementRef,
    private router: Router,
    public _searchService: SearchService,
    private _toastrService: CustomToastrService,
    public _encryptDecryptService: EncrDecrService,
    public _authenticationService:AuthenticationService,
    public globalService: GlobalService,
    private dialog: MatDialog,
    private loadingScreenService:LoadingScreenService,
    private _translateService: TranslateService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService
  ) {
    if(this.templcstg){
      this.plan = this.templcstg.NoosomPlan
     this.userCategory = this.templcstg.userCategory
     if(this.templcstg.DiaryOrJournal == '2'){
      this.btnNameJorD= 'Journal';
    }
    else{
      this.btnNameJorD= 'Diary';
    }
    }
    else if(this._authenticationService?.currentUserValue?.NoosomTypeId === 2){
      this.btnNameJorD= 'Journal';
     }
     else{
       this.btnNameJorD= 'Diary';
     }
     this._unsubscribeAll = new Subject();
     this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
     this._translateService.onLangChange.subscribe(lang => {
       // console.log('lang');
       // console.log(lang.lang);
       setTimeout(() => {
         let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
         if (_LangJsonObj)
           this.currentAppLanguage = _LangJsonObj;
       }, 100);
     })

     this.dateformat=this.globalService.getDateFormat();
    // this.dropdown = Object.keys(RecordsType)
    // .filter(key => isNaN(Number(key)))
    // .map(key => ({ key, value: RecordsType[key as keyof typeof RecordsType] }));

    let additionalIds: number[] = [0,1,2,3,4,5,6,7,8,9,10,11,12]; // You can set this to the IDs you want to inject when the condition is false

// Check the condition
if (this.plan === NoosomPlans.Premium_1 || this.userCategory === NoosomCategoryID.Test) {
  // Your original dropdown mapping logic
  this.dropdown = Object.keys(RecordsType)
    .filter(key => isNaN(Number(key)))
    .map(key => ({ key, value: RecordsType[key as keyof typeof RecordsType] }));
} else {
  // Provide specific IDs to inject
  const specificKeys = Object.keys(RecordsType)
    .filter(key => additionalIds.includes(RecordsType[key as keyof typeof RecordsType]))
    .filter(key => isNaN(Number(key))) // If you want to retain the non-numeric keys
    .map(key => ({ key, value: RecordsType[key as keyof typeof RecordsType] }));
  
  // Combine with additional logic if needed
  this.dropdown = specificKeys;
}
//console.log(this.dropdown)
  
  // Select all enums by default, except "All" (id = 0)
  this.selectAllEnums();
  this.setupSearchListener();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  onCheckboxChange(event: any, enumValue: number) {
    if (enumValue === this.RecordsType.All) {
      if (event.target.checked) {
        this.selectAllEnums();
      } else {
        this.clearAllEnums();
      }
    } else {
      if (event.target.checked) {
        this.selectedEnums.push(enumValue);
      } else {
        const index = this.selectedEnums.indexOf(enumValue);
        if (index > -1) {
          this.selectedEnums.splice(index, 1);
        }
      }

      // If all items are deselected manually, also deselect "All"
      if (this.selectedEnums.length === this.dropdown.length - 1 && !event.target.checked) {
        this.clearAllEnums();
      }
    }
    //(this.selectedEnums);
  }

  selectAllEnums() {
    this.selectedEnums = this.dropdown
      .map(item => item.value)
      .filter(value => value !== this.RecordsType.All);
     // console.log(this.selectedEnums)
  }

  clearAllEnums() {
    this.selectedEnums = [];
  }

  isEnumSelected(enumValue: number): boolean {
    return this.selectedEnums.includes(enumValue);
  }
  // getSelectedEnums() {
  //   return this.selectedEnums;
  // }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      let current_item = this._pageListElement.nativeElement.getElementsByClassName('current_item');
      current_item[0]?.children[0].click();
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this._searchService.onIsBookmarkOpenChange.next(false);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

    if (this.openSearchRef === false) {
      this.document.querySelector('.app-content').classList.remove('show-overlay');
      this.searchText = '';
    }
  }


  /**
   * Search Update
   *
   * @param event
   */
  // searchUpdate(event) {
  //   const val = event.target.value.toLowerCase();
  //   if (val !== '') {
  //     this.document.querySelector('.app-content').classList.add('show-overlay');
  //   } else {
  //     this.document.querySelector('.app-content').classList.remove('show-overlay');
  //   }
  //   this.autoSuggestion(event);
  // }
  setupSearchListener() {
    this.searchSubject
      .pipe(
        debounceTime(300), // Adjust this value to control the delay
        distinctUntilChanged() // Only trigger if the input has actually changed
      )
      .subscribe((searchText) => {
        if (searchText.length >= 3) {
          this.callSearchAPI();
        }
      });
  }

  searchUpdate(event: KeyboardEvent) {
    this.searchSubject.next(this.searchText);
  }

  callSearchAPI() {
    if (this.searchText !== '' && this.searchText !== null) {
      this.files = [];
      const data: SearchInputModel = {
        UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
        SearchString: this.searchText,
        NoosomPlanID: this._authenticationService.currentUserValue.NoosomCategoryEnumId,
      };
      this.loadingScreenService.startLoading();
      this._searchService.GlobalSearch(data).subscribe(
        (result) => {
          let message = '';
          let errormessage = '';
          if (result.status === Status.Success) {
            const carousel = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, result.data));
            this.files = carousel.Data;
           // console.log(this.files)
            this.loadingScreenService.stopLoading();
          } else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
        }
      );
    }
  }


  openEditForm(data){
   // console.log(data)
    if(data.TableName=='dbo.tblUserAddresses'){this.openDialog(AddAddressComponent, data?.UserAddressID);}
    else if(data.TableName=='dbo.tblUserSchools'){this.openDialog(AddSchoolComponent, data?.UserSchoolID);}
    else if(data.TableName=='dbo.tblUserInsurances'){this.openDialog(AddInsuranceComponent, data?.UserInsuranceID);}
    else if(data.TableName=='dbo.tblUserHealthProviders'){this.openDialog(AddProfessionalComponent, data?.UserHealthProviderID);}
    else if(data.TableName=='dbo.tblUserMedications'){this.openDialog(MyMedicationComponent, data?.UserMedicationID);}
    else if(data.TableName=='dbo.tblUserVaccinations'){this.openDialog(MyVaccinationComponent, data?.UserVaccinationID);}
    else if(data.TableName=='dbo.tblUserAwards'){this.openDialog(AwardComponent, data?.UserAwardID);}
    else if(data.TableName=='dbo.tblUserAchievements'){this.openDialog(MyAchievementComponent, data?.UserAchievementID);}
    else if(data.TableName=='dbo.tblUserJobs'){this.openDialog(JobsComponent, data?.UserJobID);}
    else if(data.TableName=='dbo.tblUserFriends'){this.openDialog(FriendsComponent, data?.UserFriendID);}
    else if(data.TableName=='dbo.tblUserFamilyMembers'){this.openDialog(OwnFamilyComponent, data?.UserFamilyMemberID);}
    else if(data.TableName=='dbo.tblUserQuickPosts'){this.AddQuickPost1(data?.UserQuickPostID)}
    else if(data.TableName=='dbo.tblUserLifeStory'){this.router.navigate(['/mylifestory'])}
    else if(data.TableName=='dbo.tblUserMeOnWeb'){this.openDialog(WebEntryComponent, data?.UserMeOnWebID);}
    else if(data.TableName=='dbo.tblUserPastimes'){this.openDialog(MyPastimesComponent, data?.UserPastimeID);}
    else if(data.TableName=='dbo.tblUserHobbies'){this.openDialog(MyHobbiesComponent, data?.UserHobbyID);}
    else if(data.TableName=='dbo.tblUserLegals'){this.openDialog(LegalsdocumentComponent, data?.UserLegalID);}
    else{this.openLightGallery(data)}
  }

  AddQuickPost1(id) {
    if ((this.plan == NoosomPlans.Premium_1)||(this.userCategory == NoosomCategoryID.Test)) {
      this.openDialogTrackerComponent(id);
    }
   else {
      this.openDialog(QuickPostComponent, id);
    }
  }

  openDialogTrackerComponent(_data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
     if (window.innerWidth < 768) {
    dialogConfig.width = "100%"; // Mobile devices
    dialogConfig.panelClass = 'mobile-dialog'; // Custom class for mobile
  } else if (window.innerWidth < 1024) {
    dialogConfig.width = "80%"; // Tablets
    dialogConfig.panelClass = 'tablet-dialog'; // Custom class for tablets
  } else {
    dialogConfig.width = "66%"; // Desktops
  }
    dialogConfig.data = _data
    const dialogRef: MatDialogRef<UploadComponent> = this.dialog.open(UploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data){
         }
      }
    );
  }


  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.isMobileScreen() ? '100%' : '60%';
    //dialogConfig.height = "80%";
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != null && data != 'undefined' && data != false) {
          // this.addGridColumns();
          // this.LoadAboutMeData();
          // setTimeout(() => {
          //     this.onAboutMeTabActive();
          // }, 400);
        }
      }
    );
  }

  private isMobileScreen(): boolean {
    return window.innerWidth <= 767; // You can adjust this value as per your requirements
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);

    this._searchService.onApiDataChange.subscribe(res => {
      this.apiData = res;
      this.pages = this.apiData[0].data;
      this.pageSearchLimit = this.apiData[0].searchLimit;
     // this.files = this.apiData[1].data;
      this.contacts = this.apiData[2].data;
    });

    if(this.templcstg){
    //   this.plan = this.templcstg.NoosomPlan
    //  this.userCategory = this.templcstg.userCategory
     if(this.templcstg.DiaryOrJournal == '2'){
      this.btnNameJorD= 'Journal';
    }
    else{
      this.btnNameJorD= 'Diary';
    }
    }
    else if(this._authenticationService?.currentUserValue?.NoosomTypeId === 2){
      this.btnNameJorD= 'Journal';
     }
     else{
       this.btnNameJorD= 'Diary';
     }
  }




  openLightGallery(params){
    if(params){
      if(params.FileType == 'Documents'){
        window.open(params.DocumentPath, '_blank');
      }
      else{
      this.attachmentlistimage=[]
      
      this.AddItemToGallery(params.AttachmentId, params.DocumentPath, params.DocumentOldName,params.Size ,params.FileType);
     this.needRefresh = true;
   }
  }
}

   async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
    };
      
    this.attachmentlistimage.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
    };
    const index = this.attachmentlistimage.findIndex(item => item.id == _id);
    this.attachmentlistimage[index] = galleryItem;
    if(this.attachmentlistimage){
    setTimeout(() => {
         const index = this.attachmentlistimage.findIndex((el) => el.id === _id);
         if(this.galleryRefresh){
         this.galleryRefresh.openGallery(index);}
       }, 50); }
  }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }

  
       ngAfterViewChecked(): void {
        if (this.needRefresh) {
         
          // if (this.lightGallery){       
            this.galleryRefresh.refresh();
            
              if(this.galleryRefresh.galleryItems.length>1){
                const lastItem = this.galleryRefresh.galleryItems.slice(-1);
                this.galleryRefresh.galleryItems = lastItem;
              }
          // }
          this.needRefresh = false;
        }
       }
  
    
    
      onInitGallery = (detail) => {
        this.lightGallery = detail.instance;
        this.galleryRefresh = detail.instance;
        this.galleryRefresh.settings.licenseKey=this._authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey;
       // console.log(this.galleryRefresh)
        const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
        <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
        const $toolbar = this.lightGallery.$toolbar;
      
        $toolbar.append(customHelpButton);
    
    
       
        this.document.getElementById("lg-custom-button-help").addEventListener("click", () => {
          this.supportFunction();
        });
        
      };
      supportFunction() {
    
        Swal.fire({
          title: "Help!",
          text: "Information about the icons in the Light Gallery.",
          imageUrl: 'assets/images/pages/raw-icon-labels.png',
          showCancelButton: false,
          confirmButtonColor: '#7367F0',
          //cancelButtonColor: '#E42728',
          confirmButtonText: "Okay",
          cancelButtonText: "cancel",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
    
        })
    
      }

      GetCurrentLanguageJsonObject(currentAppLanguage) {
        var result;
        switch (currentAppLanguage) {
          case 'en':
            result = english;
            break;
          case 'in':
            result = gujarati;
            break;
          case 'hn':
            result = hindi;
            break;
        }
        return result;
      }
      
}
@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, searchValue: string, replaceValue: string): string {
    if (!value || !searchValue || replaceValue === undefined) {
      return value;
    }
    return value.split(searchValue).join(replaceValue);
  }

}

@Pipe({
  name: 'formatTableName'
})
export class FormatTableNamePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value; // Return the input if it's null or undefined

    // Remove the prefix "dbo.tblUser"
    const cleaned = value.replace('dbo.tblUser', '');

    // Use regex to insert space before each capital letter
    const formatted = cleaned.replace(/([A-Z])/g, ' $1').trim();

    return formatted;
  }

}


@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, limit: number = 20, completeWords: boolean = false, ellipsis: string = '...'): string {
    if (!value) return '';
    
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}