import { Status } from "app/enums";
export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    SCHOOL: {
      DIALOG_TITLE_ADD_SCHOOL: 'Add your School',
      DIALOG_TITLE_EDIT_SCHOOL: 'Edit a School',
      TAB_HEADING_ADDRESS_DETAILS: 'Address Details',
      TAB_HEADING_GALLERY: 'Gallery',
      TAB_HEADING_UPLOAD: 'Upload',
      TAB_HEADING_DOCUMENTS: 'Documents',
      TAB_HEADING_NOTES: 'Notes',
      INFO_TEXT: 'Add all the schools you have attended in the past, or currently attending',
      INFO_TEXT1: 'Select some Images, Documents, and Videos; you can upload them one at a time or select Upload All at once',
      INFO_TEXT2: 'All your uploaded images will be displayed here',
      INFO_TEXT4: 'Add any specific notes that you have for this School',
      INFO_TEXT3: 'All your documents will be displayed here in a list; you can download or delete them individually',
      SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to unlink this School Document?',
      SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT: 'Are you sure, you want to delete all these School Documents?',
      SWEET_ALERT_TITLE_SCHOOL_TEXT: 'Are you sure, you want to delete this School and all the attachments associated with it?',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CONFIRM_UNLINK_BUTTON_TEXT:'Yes, unlink it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'Toggle Calendar',
      TOOLTIP_TEXT_BUTTON_ADD_RECORD: 'Add Record',
      TOOLTIP_TEXT_BUTTON_ADD_A_SCHOOL: 'Add a School',
      TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP: 'Search on Map',
      TOOLTIP_TEXT_BUTTON_CANCEL: 'Cancel',
      TOOLTIP_TEXT_BUTTON_SAVE_CHANGES: 'Save Changes',
      TOOLTIP_TEXT_BUTTON_UPLOAD_ALL: 'Upload All',
      TOOLTIP_TEXT_BUTTON_REMOVE_ALL: 'Remove All',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD_ALL: 'Download All',
      TOOLTIP_TEXT_BUTTON_UPLOAD: 'Upload',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD: 'Download',
      TOOLTIP_TEXT_BUTTON_VIEW: 'View',
      TOOLTIP_TEXT_BUTTON_DELETE: 'Delete',
      TOOLTIP_TEXT_BUTTON_REMOVE: 'Remove',
      TOOLTIP_TEXT_ADDRESS_TAG: 'The Address Tag / Code is your own unique choice of words that you can use to search the Noosom Web System',
      TOOLTIP_TEXT_EDUCATIONAL_INSTITUTION_NAME: 'This is where you enter a School, College, or University Name like "City HighSchool" or "Oxford University"',
      TOOLTIP_TEXT_DEGREE_COMPLETED: 'Enter your Degree or Education Completed; this can be your diploma, technical, associates, bachelors, masters, or doctoral degrees',
      TOOLTIP_TEXT_STREET_NAME: 'This is a required field for mapping your location accurately; please enter the street name correctly',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2: 'This is an additional field for more precise location mapping',
      TOOLTIP_TEXT_TOWN_VILLAGE_CITY: 'Enter the Town, Village, or City of the address',
      TOOLTIP_TEXT_POSTAL_ZIPCODE: 'This is the Postal Zipcode associated with the address\'s country',
      TOOLTIP_TEXT_REGION_CONTINENT: 'Choose the Region or Continent of the address; for example, United Kingdom will be in Europe',
      TOOLTIP_TEXT_COUNTRY: 'Select the Country of the address; for example, England will be in Europe',
      TOOLTIP_TEXT_STATE_PROVINCE: 'Choose the State or Province of the address; for example, Rotuma is a state or island in Fiji',
      TOOLTIP_TEXT_CURRENT_ADDRESS: 'This is the Current school checkbox; you can select it or leave it blank according to your preference',
      TOOLTIP_TEXT_STAY_DATE_RANGE_1: 'Use this field for entering a date range of your stay at this address',
      TOOLTIP_TEXT_STAY_DATE_RANGE_2: 'Use this field for entering a date range of your stay at this address',
      HEADING_TEXT_SELECT_IMAGES: 'Select Images, Documents & Videos',
      HEADING_TEXT_UPLOAD_QUEUE: 'Upload Queue',
      HEADING_TEXT_QUEUE_PROGRESS: 'Queue Progress',
      TEXT_QUEUE_LENGTH: 'Queue Length:',
      TEXT_MB: 'MB',
      TEXT_FILE_TYPES: 'Allowed file types:',
      CONTROL_LABEL_ADDRESS_TAG: 'Address Tag / Code',
      CONTROL_LABEL_SCHOOL_NAME: 'School Name',
      CONTROL_LABEL_EDUCATION_RECEIVED: 'Education Received',
      CONTROL_LABEL_ADDRESS1: 'Street Name',
      CONTROL_LABEL_ADDRESS2: 'Address Line 2',
      CONTROL_LABEL_ADDRESS3: 'Address Line 3',
      CONTROL_LABEL_ADDRESS_TOWN: 'Town / City',
      CONTROL_LABEL_ADDRESS_STATE: 'State',
      CONTROL_LABEL_ADDRESS_POST_CODE: 'Post / Zip Code',
      CONTROL_LABEL_COUNTRY: 'Country',
      CONTROL_LABEL_REGION: 'Region',
      CONTROL_LABEL_DATE_FROM: 'Date From',
      CONTROL_LABEL_DATE_TO: 'Date To',
      CONTROL_LABEL_NOTES: 'Notes',
      CONTROL_LABEL_MULTIPLE: 'Multiple',
      LABEL_CURRENT_ADDRESS: 'Current School',
      PLACEHOLDER_STATE: 'Select State',
      PLACEHOLDER_COUNTRY: 'Select Country',
      PLACEHOLDER_CHOOSE_FILES: 'Choose Files',
      PLACEHOLDER_REGION: 'Select Region',
      BUTTON_TEXT_ADD_RECORD: 'Add Record',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'Save and close',
      BUTTON_TEXT_SAVE_AND_NEXT: 'Save and Next',
      BUTTON_TEXT_ABANDON: 'Abandon',
      BUTTON_TEXT_SEARCH_ON_MAP: 'Search on Map',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_UPLOAD_ALL: 'Upload All',
      BUTTON_TEXT_PAUSE_ALL: 'Pause All',
      BUTTON_TEXT_DOWNLOAD_ALL: 'Download All',
      BUTTON_TEXT_REMOVE_ALL: 'Remove All',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      COLUMN_HEADING_NAME: 'NAME',
      COLUMN_HEADING_SIZE: 'SIZE',
      COLUMN_HEADING_PROGRESS: 'PROGRESS',
      COLUMN_HEADING_STATUS: 'STATUS',
      COLUMN_HEADING_DATE_CREATED: 'DATE CREATED',
      COLUMN_HEADING_TYPE: 'TYPE',
      COLUMN_HEADING_ACTIONS: 'ACTIONS',
      TOAST_MESSAGE_SCHOOL_ADDED_SUCCESSFULLY: 'School added successfully.',
      TOAST_MESSAGE_SCHOOL_UPDATED_SUCCESSFULLY: 'School updated successfully.',
      INFO_MESSAGE_COMING_SOON: 'Coming Soon',
      WARNING_MESSAGE_SELECT_LOCATION: 'Please select location on a map.',
      ERROR_MESSAGE_REQUIRED_ADDRESS_TAG: 'The address tag is required!',
      ERROR_MESSAGE_REQUIRED_SCHOOL_NAME: 'The school name is required!',
      ERROR_MESSAGE_REQUIRED_EDUCATION_RECEIVED: 'The education received is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS1: 'The address1 is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS2: 'The address2 is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS3: 'The address3 is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_TOWN: 'The address town is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_STATE: 'The address state is required!',
      ERROR_MESSAGE_REQUIRED_ADDRESS_POST_CODE: 'The address post code is required!',
      ERROR_MESSAGE_REQUIRED_COUNTRY: 'The country is required!',
      ERROR_MESSAGE_REQUIRED_REGION: 'The region is required!',
      ERROR_MESSAGE_REQUIRED_DATE_FROM: 'The date from is required!',
      ERROR_MESSAGE_REQUIRED_DATE_TO: 'The date to is required!',
      ERROR_MESSAGE_MAX_LENGTH_50: 'You have typed too many characters, maximum allowed is 50',
    }
  }
};
