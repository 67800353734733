<!-- To Do List -->
<link href="https://cdn.syncfusion.com/ej2/27.1.48/material.css" rel="stylesheet">
<!-- <link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" rel="stylesheet"> -->
<!-- <div>
    <div class="card card-employee-task">
        <div class="card-header">
            <table>
                <tr>
                    <td width="90%">
                        <h4 class="card-heading-text-3">To Do List</h4>
                    </td>
                    <td><i data-feather="filter" class="font-medium-3 cursor-pointer"></i></td>
                    <td><i data-feather="maximize" class="font-medium-3 cursor-pointer"></i></td>
                </tr>
            </table>
        </div>
        <div class="card-body">
            <div class="card">
                <ul class="list-group list-group-flush">
                    <li *ngFor="let item of toDoListDataOutputModel?.ToDoListData" class="list-group-item">
                        <table>
                            <tr>
                                <td width="95%">{{item.Title}}</td>
                                <td>
                                    <span *ngIf="item.Tag==1" class="badge bg-success rounded-pill">D</span>
                                    <span *ngIf="item.Tag==2" class="badge bg-primary rounded-pill">W</span>
                                    <span *ngIf="item.Tag==3" class="badge bg-danger rounded-pill">S</span>
                                    <span *ngIf="item.Tag==4" class="badge bg-warning rounded-pill">D</span>
                                </td>
                            </tr>
                        </table>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div> -->
<!--/ To Do List -->
<!-- [ajaxSettings]='ajaxSettings' 
                 [fileSystemData]="folders"
                 [showThumbnail]='true' 
                 id='advanced-filemanager' 
                 [navigationPaneSettings]='{visible: true}' 
                 [toolbarSettings]='{visible: true}'
                 [toolbarItems]="toolbarItems" 
                 [height]="800" -->
                 <div class="card mb-0 pb-2">
                 <h5 cdkDragHandle class="card-header mb-0" style="justify-content: unset;">Noosom File Manager&nbsp;
                  <button class="close-buttonnew closenew" type="button" (click)="onCloseButtonClick()" placement="right"
                    ngbTooltip="Close">&times;</button>
                </h5>
<div >
    <ejs-filemanager #advancedfilemanager [(fileSystemData)]="folders" [showThumbnail]='true'  (dragAndDropSettings)="dragAndDropSettings"
        (fileOpen)="onFolderOpen($event)" (fileCreate)="onFileCreated($event)" (fileRemove)="onFileRemove($event)" (fileSelect)="onFileSelect($event)" (fileSelection)="onFileSelection($event)"
        [navigationPaneSettings]='{visible: paneYn}' (beforePopupClose)="popUpClose($event)" (navigate)="onNavigate($event)" 
        (beforePopupOpen)="popUpClose($event)" (folderCreate)="popUpClose($event)" (rename)="popUpClose($event)" [contextMenuSettings]="contextMenuSettings"
        [toolbarSettings]='toolbarSettings' (toolbarClick)="onToolbarClick($event)" (menuClick)="onMenuClick($event)"
        [toolbarItems]="toolbarItems" (failure)="onFailure($event)" (fileLoad)="onFolderExpand($event)" [path]="'/'"
        [height]="500" [allowDragAndDrop]="true" >
    </ejs-filemanager>
   
</div>
<ngb-pagination #pagination cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
class="floatingloadmore-button " *ngIf="attachmentlist.length>0" [collectionSize]='pagesNumber'
[pageSize]="80" (pageChange)="loadPage($event,'Images')" [(page)]="pageAdvancedEllipses"
[maxSize]="5" [rotate]="true" [boundaryLinks]="true">
<ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
    [class]="'font-weight-bolder'"></span>Prev
</ng-template>
<ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
    [class]="'font-weight-bolder'"></span>
</ng-template>
</ngb-pagination>

<input type="file" id="MultiFileUploaderAddress" class="custom-file-input" type="file" style="display: none;"
    (change)="openUploaderUpload()" #fileInput ng2FileSelect [uploader]="uploader" multiple id="file-upload-multiple" />

<!-- <button (click)="refreshFileManager()">Refresh File Manager</button> -->
 <div class="card-footer d-flex">
    <div class="align">
      <button type="submit"  class="btn btn-success mr-1" (click)="updateDoc()" [disabled]="loading" placement="top"
       ><span *ngIf="loading" 
          class="spinner-border spinner-border-sm mr-1"></span>Upload
      </button>
      <button type="submit"  class="btn btn-danger mr-1" (click)="onCloseButtonClick()" [disabled]="loading" placement="top"
       ><span *ngIf="loading" 
          class="spinner-border spinner-border-sm mr-1"></span>Cancel
      </button>
    </div>
  </div>
</div>