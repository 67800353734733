<link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}} {{btnNameJorD}} {{'QUICKPOST.HEADING_TEXT'|translate}}
    <!-- <button class="close-button" (click)="onCloseButtonClick()"
        placement="right">&times;</button> -->&nbsp;<span ngbTooltip={{infoTooltip}}>
      <span data-feather="info"></span>
    </span>
    <button type="button" class="close-button close" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" (click)="onCloseButtonClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </h5>
  <div class="card-body card-body-dialog">
    <form id="frmQuickpost" [formGroup]="frmQuickpost" (ngSubmit)="onSubmit(false)">
      <!-- <form >    -->
      <section id="basic-tabs-components">
        <div class="row">
          <div class="col">
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-pill-primary">
                <li [ngbNavItem]="1"(click)="onTabActive(1)">
                  <a ngbNavLink>{{btnNameJorD}} {{"QUICKPOST.HEADING_TEXT"| translate}}</a>
                  <ng-template ngbNavContent>
                    <div class="scroll-area">
                      <div class="row">
                        <div class="col-sm">
                          <div class="form-group">
                            <app-text-box #txtTitle [type]="'text'" [showRequiredSign]="true" formControlName="Title"
                              label="{{'QUICKPOST.CONTROL_LABEL_TITLE'|translate}}" for="Title" [maxlength]="100" [showInfoIcon]="true"
                              [tooltipInfo]="'QUICKPOST.TOOLTIP_TEXT_DIARY_DESCRIPTION'|translate" [position]="'bottom-left'"
                              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate"
                              [classes]="{ 'is-invalid': submitted && f.Title.errors }">
                            </app-text-box>
                            <span *ngIf="submitted && f.Title.errors">
                              <small *ngIf="f.Title.errors.required"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_TITLE'|translate}}</small>
                              <small *ngIf="f.Title.errors.maxlength"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_MAX_LENGTH_100'|translate}}</small>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-2 col-md">
                          <div class="form-group">
                            <label class="form-label">{{btnNameJorD}} {{'QUICKPOST.CONTROL_LABEL_DIARY_DATE'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DIARY_DATE_CREATED'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              <strong>*</strong></label>
                            <div class="input-group">
                              <input id="DiaryDate" name="DiaryDate" class="form-control" [placeholder]="dateformat"
                                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                formControlName="DiaryDate" for="DiaryDate" required bsDatepicker #dp="bsDatepicker">
                              <div class="input-group-append">
                                <button class="btn btn-outline-primary feather icon-calendar" (click)="dp.toggle()"
                                  type="button" rippleEffect placement="top"
                                  ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_BUTTON_CALENDAR' | translate}}"></button>
                              </div>
                              <span *ngIf="submitted && f.DiaryDate.errors">
                                <small *ngIf="f.DiaryDate.errors.required"
                                  class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_DIARY_DATE'|translate}}</small>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div style=" padding-left: 15px; padding-right: 15px;">
                          <div class="form-group">
                            <label class="form-label">{{btnNameJorD}} {{'QUICKPOST.CONTROL_LABEL_DIARY_TIME_FROM'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DIARY_START_TIME'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              <strong>*</strong></label>
                            <div class="input-group">
                              <ngb-timepicker [(ngModel)]="spinnerTPtime" [ngModelOptions]="{standalone: true}"
                                [spinners]="spinnerTP" formControlName="DiaryTimeFrom" [meridian]="timeformat"
                                (ngModelChange)="OnQuickPostTimeChangeFrom($event)"
                                [classes]="{ 'is-invalid': submitted && f.DiaryTimeFrom.errors }"></ngb-timepicker>
                            </div>
                            <span *ngIf="submitted && f.DiaryTimeFrom.errors">
                              <small *ngIf="f.DiaryTimeFrom.errors.required"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_DIARY_TIME'|translate}}</small>
                            </span>
                          </div>
                        </div>
                        <div style=" padding-left: 15px;">
                          <div class="form-group">
                            <label class="form-label">{{btnNameJorD}} {{'QUICKPOST.CONTROL_LABEL_DIARY_TIME_TO'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DIARY_END_TIME'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              <strong>*</strong></label>
                            <div class="input-group">
                              <ngb-timepicker [(ngModel)]="spinnerTftime" [ngModelOptions]="{standalone: true}"
                                [spinners]="spinnerTf" formControlName="DiaryTimeTo" [meridian]="timeformat"
                                (ngModelChange)="OnQuickPostTimeChange($event)"
                                [classes]="{ 'is-invalid': submitted && f.DiaryTimeTo.errors }"></ngb-timepicker>
                            </div>
                            <span *ngIf="submitted && f.DiaryTimeTo.errors">
                              <small *ngIf="f.DiaryTimeTo.errors.required"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_DIARY_TIME_TO'|translate}}</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-xl-4 col-md-3">
                          <div class="form-group">
                            <label class="form-label">{{'QUICKPOST.CONTROL_LABEL_LOCATION'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_SEARCH_MAP_BUTTON'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              </label>
                            <div class="input-group-prepend">
                              <button class="btn btn-outline-secondary feather icon-map-pin"
                                ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_BUTTON_LOCATION' | translate}}" type="button"
                                [disabled]="true" rippleEffect></button>
                              <div class="input-group">
                                <input type="text" class="form-control" id="Location" readonly="readonly"
                                  formControlName="Location" for="Location" [maxlength]="1000"
                                  placeholder="{{'QUICKPOST.PLACEHOLDER_SEARCH_LOCATION_HERE'|translate}}">
                                <span *ngIf="submitted && f.Location.errors">
                                  <small *ngIf="f.Location.errors.maxlength"
                                    class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_MAX_LENGTH_1000'|translate}}</small>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm" style="padding-top: 26px;">
                          <button type="button" class="btn btn-primary btn-block px-3" style="width:100%" [disabled]="loading"
                            placement="top" (click)="onSearchOnMapClick()"
                            ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP' | translate}}"><span
                              *ngIf="loading"></span><i data-feather="search" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block nowrap">
                            {{'QUICKPOST.BUTTON_TEXT_SEARCH_ON_MAP'|translate}}</span></button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-2 col-md-6">
                          <div class="form-group">
                            <label class="form-label" for=" QuickPostTypeId">{{'QUICKPOST.CONTROL_LABEL_POST_TYPE'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DEFAULT_PRIVACY_SETTING'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              <strong> *</strong></label>
                            <ng-select [items]="quickpostLoadDataOutputModel?.QuickPostTypeList" [required]="true" 
                              placeholder="{{'QUICKPOST.PLACEHOLDER_SELECT_POST_TYPE'|translate}}" selectOnTab="true"
                              bindLabel="QuickPostTypeName" bindValue="QuickPostTypeId" id="QuickPostTypeId" 
                              formControlName="QuickPostTypeId" (click)="onPostTypeChange($event)"
                              [classes]="{ 'is-invalid': submitted && f.QuickPostTypeId.errors }"></ng-select>
                            <span *ngIf="submitted && f.QuickPostTypeId.errors">
                              <small *ngIf="f.QuickPostTypeId.errors.required"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_POST_TYPE'|translate}}</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                          <div class="form-group">
                            <label class="form-label">{{'QUICKPOST.CONTROL_LABEL_EVENT' |translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DIARY_EVENT_LINKING'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                            </label>
                            <ng-select [required]="true" selectOnTab="true"
                              placeholder="{{'QUICKPOST.PLACEHOLDER_SELECT_EVENT'|translate}}"
                              readonly="readonly"></ng-select>
                            <!-- <span *ngIf="submitted && f.SexAtBirth.invalid">
                                    <small class="text-danger"
                                      *ngIf="f.SexAtBirth.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_SEX_AT_BIRTH'
                                      |
                                      translate}}</small>
                                  </span> -->
                          </div>
                        </div>
                        <div class="col-xl-7 col-md-12">
                          <div class="form-group">
                            <label class="form-label">{{'QUICKPOST.CONTROL_LABEL_TAG_CATEGORY' | translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_TAG_CODE_CATEGORY'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                            </label>
                            <ng-select [items]="customTags" [addTag]="addTagMethod" [multiple]="true" bindLabel="name"
                              placeholder="{{'QUICKPOST.PLACEHOLDER_ADD_SELECT_TAG_HERE'|translate}}" selectOnTab="true"
                              formControlName="TagCategory" for="TagCategory" [(ngModel)]="customTagselected"
                              [ngModelOptions]="{standalone: true}" (add)="selectAddTagMethod($event)"
                              (remove)="removeTagMethod($event)" (change)="dataModelChanged($event)">
                              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label multi-selector"> {{ item.name }}</span>
                                <span class="ng-value-icon right multi-selector" (click)="clear(item)" aria-hidden="true">×</span>
                              </ng-template>
                            </ng-select>
                            <!-- Selected value: {{ customTagselected | json }} -->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm">
                          <div class="form-group">
                            <label class="form-label">{{'QUICKPOST.CONTROL_LABEL_POST_CONTENT'|translate}}
                              <span placement="right"
                              ngbTooltip="{{'QUICKPOST.TOOLTIP_TEXT_DIARY_DESCRIPTION_EDITOR'|translate}}">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              <strong> *</strong></label>
                            <quill-editor [(ngModel)]="quillEditorContent" #editor [ngModelOptions]="{standalone: true}"
                              formControlName="PostContent" for="PostContent"
                              placeholder="{{'QUICKPOST.PLACEHOLDER_TYPE_HERE'|translate}}">
                              <div quill-editor-toolbar>
                                <span class="ql-formats">
                                  <select class="ql-font" [title]="'QUICKPOST.TOOLTIP_TEXT_TEXT_STYLE'| translate">
                                  </select>
                                </span>
                                <span class="ql-formats">
                                  <select class="ql-header" [title]="'QUICKPOST.TOOLTIP_TEXT_TEXT_SIZE'| translate">
                                  </select>
                                </span>
                                <span class="ql-formats">
                                  <select class="ql-color" [title]="'QUICKPOST.TOOLTIP_TEXT_TEXT_COLOUR'| translate">
                                  </select>
                                </span>
                                <span class="ql-formats">
                                  <select class="ql-align" [title]="'QUICKPOST.TOOLTIP_TEXT_TEXT_ALIGNMENT'| translate">
                                    <option selected></option>
                                    <option title="Center" value="center"></option>
                                    <option title="Right" value="right"></option>
                                    <option title="Justify" value="justify"></option>
                                  </select>
                                </span>
                                <span class="ql-formats">
                                  <button class="ql-bold" [title]="'QUICKPOST.TOOLTIP_TEXT_BOLD'| translate"></button>
                                  <button class="ql-italic" [title]="'QUICKPOST.TOOLTIP_TEXT_ITALIC'| translate"></button>
                                  <button class="ql-underline" [title]="'QUICKPOST.TOOLTIP_TEXT_UNDERLINE'| translate"></button>
                                </span>
                                <span class="ql-formats">
                                  <button class="ql-link" value="ordered" [title]="'QUICKPOST.TOOLTIP_TEXT_LINK'| translate" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                  <button class="ql-list" value="bullet" [title]="'QUICKPOST.TOOLTIP_TEXT_BULLET_LIST'| translate" type="button"></button>
                                  <button class="ql-list" value="ordered" [title]="'QUICKPOST.TOOLTIP_TEXT_NUMBERED_LIST'| translate" type="button"></button>
                                </span>

                                <span class="ql-formats">
                                  <button class="ql-clean" value="ordered" [title]="'QUICKPOST.TOOLTIP_TEXT_CLEAR_FORMAT'| translate" type="button"></button>
                                </span>
                              </div>
                            </quill-editor>

                            <span *ngIf="submitted && f.PostContent.errors">
                              <small *ngIf="f.PostContent.errors.required"
                                class="text-danger">{{'QUICKPOST.ERROR_MESSAGE_REQUIRED_POST_CONTENT'|translate}}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                      <ng-template #uploaderContent let-modal>
                        <div class="modal-header"> <p class="m-0">Select whom to share</p>
                          <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close" [attr.autofocus]="null">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      
                        <div tabindex="0" ngbAutofocus class="modal-body">
                          <div class="input-group input-group-merge mb-2">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon-search2"><i data-feather="search"></i></span>
                            </div>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Search..."
                              aria-label="Search..." (input)="searchLists($event.target.value)"
                              aria-describedby="basic-addon-search2"
                            />
                          </div>
                          <div class="list-container">

                            <div class="list-section todo-task-list-wrapper list-group" [perfectScrollbar]>
                              <h3>Friends</h3>
                              <label *ngIf="friendsList.length!=0">
                                <input type="checkbox"  (change)="toggleSelectAllFriends()"> Select All
                              </label>
                              <label *ngIf="friendsList.length<=0">
                                No Record to Show
                              </label>
                              <ul class="todo-task-list media-list" >
                                <li *ngFor="let friend of filteredFriendsList" class="todo-item">
                                  <label>
                                    <input  type="checkbox"  (change)="onCheckboxChange($event.target.checked, friend.ReceiverUserDetailsId,'friend')"   [checked]="isFolderSelected('friend',friend.ReceiverUserDetailsId)" class="folder-checkbox" > {{ friend.UserFriendFullName }}
                                  </label>
                                </li>
                              </ul>
                            </div>
                      
                            <!-- Family List Section -->
                            <div class="list-section">
                              <h3>Family</h3>
                              <label *ngIf="familyList.length!=0">
                                <input type="checkbox" (change)="toggleSelectAllFamily()"> Select All
                              </label>
                              <label *ngIf="familyList.length<=0">
                                No Record to Show
                              </label>
                              <ul>
                                <li *ngFor="let familyMember of filteredFamilyList">
                                  <label>
                                    <input type="checkbox"  (change)="onCheckboxChange($event.target.checked, familyMember.ReceiverUserDetailsId,'family')"  [checked]="isFolderSelected('family',familyMember.ReceiverUserDetailsId)" class="folder-checkboxfamily"> {{ familyMember.UserFamilyMemberFullName }}
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-success" (click)="share()"
                            rippleEffect>{{'QUICKPOST.CONTROL_LABEL_SHARE'|translate}}</button>
                          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')"
                            rippleEffect>{{'QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT'|translate}}</button>
                        </div>
                      </ng-template>  
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="2"  [disabled]="!_data && !IsForEdit">
                  <a (click)="onTabActive(2)" ngbNavLink>{{'QUICKPOST.TAB_HEADING_TRACKERS'|translate}}</a>
                  <ng-template ngbNavContent>
                    <div class="scroll-area">
                      <!-- <app-trackers></app-trackers> -->
                      <div class="row">
                        <div class="col-sm-4 pt-5">
                          <p class="font">{{'TRACKERS.HOW_IS_YOUR_MOOD_TEXT'|translate}}</p>
                        </div>
                        <div class="col-sm-8" style="overflow-x: auto; overflow-y: hidden;">

                          <!-- <span #mood *ngFor="let item of MoodItem"  type="button" class="btn" id="{{item.moodid}}"  value="{{item.moodid}}" (click)="OnClickMood(item.moodid)">
                              <img  src="{{item.moodpath}} ">
                              <p>{{item.moodname}}</p>
                            </span> -->

                          <fieldset class="emotion-rating labels">

                            <!-- INPUTS -->
                            <input *ngFor="let item of MoodItem" name="MoodId" value="rating{{item.moodid}}"
                              type="radio" id="rating{{item.moodid}}" formControlName="MoodId">
                            <!-- LABELS -->
                            <div class="labels">
                              <label *ngFor="let item of MoodItem" aria-describedby="{{item.moodname}}"
                                for="rating{{item.moodid}}">
                                <span aria-hidden="true" class="btn star"  (click)="OnClickMood(item.moodid)"><img src="{{item.moodpath}} "
                                   >
                                  <p>{{item.moodname}}</p>
                                </span>
                              </label>
                            </div>
                          </fieldset>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4 pt-5">
                          <p class="font">{{'TRACKERS.HOW_WOULD_YOU_RATE_TEXT'|translate}}</p>
                        </div>
                        <div class="col-sm-7" style="overflow-x: auto; overflow-y: hidden;">

                          <!-- <button *ngFor="let item of Ratings" type="button" class="btn" id='"{{item._id}}"' value="{{item._id}}" (click)="OnClickRating(item._id)" rippleEffect>
                          <img src="{{item._url}}">
                            <p>{{item._name}}</p>
                         </button> -->

                          <fieldset class="emotion-bar labels">

                            <!-- INPUTS -->
                            <input *ngFor="let item of Ratings" name="MoodRatingId" value="bar{{item._id}}" type="radio"
                              id="bar{{item._id}}" formControlName="MoodRatingId">
                            <!-- LABELS -->
                            <div class="labels">
                              <label *ngFor="let item of Ratings" aria-describedby="{{item._name}}"
                                for="bar{{item._id}}">
                                <span aria-hidden="true" class="btn star" (click)="OnClickRating(item._id)"><img src="{{item._url}}"
                                    >
                                  <p>{{item._name}}</p>
                                </span>
                              </label>
                            </div>
                          </fieldset>

                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="3"  [disabled]="!_data && !IsForEdit">
                  <a (click)="onTabActive(3)" ngbNavLink>{{'QUICKPOST.TAB_HEADING_UPLOAD'|translate}}</a>
                  <ng-template ngbNavContent>
                    <div class="scroll-area">
                      <!-- <app-upload></app-upload> -->
                      <div class="row">
                        <div class="col">
                          <div class="row ml-0">
                            <h4 class="font pt-4" style="width: fit-content;color: #323091;">{{'UPLOAD.HEADING_TEXT_SELECT_IMAGES' | translate}}
                          </h4>
                          <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                            <div ngbDropdown class="input-group-append">
                              <button
                                type="button"
                                class="btn btn-primary"
                                ngbDropdownToggle
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                rippleEffect
                              >
                                Browse
                              </button>
                              <div ngbDropdownMenu class="dropdown-menu-rightnew" >
                                <a ngbDropdownItem (click)="openFilemanager()">From Noosom Media</a>
                                <a ngbDropdownItem (click)="triggerFileUpload()">From External Device</a>
                              </div>
                            <!-- </div> -->
                          </div>
                              <!-- Hidden File Input -->
                              <div class="custom-file" style="display: none;">
                                <input type="file" id="MultiFileUploaderQuickPost" class="custom-file-input" (change)="onFileSelected($event)"
                                  ng2FileSelect [uploader]="uploader" multiple #fileInput />
                                <label class="custom-file-label font" for="file-upload-multiple">{{'UPLOAD.PLACEHOLDER_CHOOSE_FILES' | translate}}</label>
                              </div>
                          </fieldset></div>
                          <h5 style="color: #323091;">{{'UPLOAD.TEXT_FILE_TYPES' | translate}}
                            tiff, bmp, jpeg, png, image, jpg, mp4, mpeg, mov, avi, ogv, webm, 3gp, mpg, wmv, flv</h5>

                        </div>
                      </div>
                      <div class="row">
                        <div class="col overflow-auto">
                          <!-- <div class="d-flex justify-content-between mb-1">
                            <h4 class="font">{{'UPLOAD.HEADING_TEXT_UPLOAD_QUEUE' | translate}}</h4>
                            <p>{{'UPLOAD.TEXT_QUEUE_LENGTH' | translate}} {{ uploader?.queue?.length }}</p>
                          </div>

                          <table class="table">
                            <thead style="color: #323091;">
                              <tr>
                                <th width="50%" text-align="left">{{'UPLOAD.COLUMN_HEADING_NAME' | translate}}</th>
                                <th>{{'UPLOAD.COLUMN_HEADING_SIZE' | translate}}</th>
                                <th>{{'UPLOAD.COLUMN_HEADING_PROGRESS' | translate}}</th>
                                <th>{{'UPLOAD.COLUMN_HEADING_STATUS' | translate}}</th>
                                <th>{{'UPLOAD.COLUMN_HEADING_ACTIONS' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of uploader.queue">
                                <td>
                                  <strong>{{ item?.file?.name }}</strong>
                                </td>
                                <td *ngIf="uploader.options.isHTML5" nowrap>
                                  {{ item?.file?.size / 1024 / 1024 | number: '.2' }} {{'UPLOAD.TEXT_MB' | translate}}
                                </td>
                                <td *ngIf="uploader.options.isHTML5">
                                  <ngb-progressbar type="primary" striped="true" animated="true"
                                    [value]="item.progress"></ngb-progressbar>
                                </td>
                                <td class="text-center">
                                  <span *ngIf="item.isSuccess"><i data-feather="check-circle"></i></span>
                                  <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                                  <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                                </td>
                                <td nowrap>
                                  <button type="button" class="btn btn-raised btn-success btn-sm mr-50"
                                    (click)="item.upload()"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                    <span data-feather="upload"></span>
                                  </button>

                                  <button type="button" class="btn btn-raised btn-danger btn-sm" (click)="item.remove();checkQueueLength(uploader?.queue?.length)"
                                    rippleEffect>
                                    <i data-feather="trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div id="">
                            <p class="mt-1 font">{{'UPLOAD.HEADING_TEXT_QUEUE_PROGRESS' | translate}}</p>
                            <ngb-progressbar class="mb-1" type="primary" [striped]="true" [animated]="true"
                              [value]="uploader.progress"></ngb-progressbar>
                            <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                              (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length"
                              rippleEffect ngbTooltip="Upload All">
                              <span data-feather="upload" class="mr-25"></span> {{'UPLOAD.BUTTON_TEXT_UPLOAD_ALL' |
                              translate}}
                            </button>

                            <button type="button" class="btn btn-danger mb-1 mb-sm-0" (click)="uploader.clearQueue();checkQueueLength(uploader?.queue?.length)"
                              [disabled]="!uploader.queue.length" rippleEffect>
                              <span data-feather="trash" class="mr-25"></span> {{'UPLOAD.BUTTON_TEXT_REMOVE_ALL' |
                              translate}}
                            </button>
                          </div> -->
                          <div *ngIf="GalleryItems1.length==0 && DocumentList1.length==0"><img style="padding-left: 34%;" src="assets/images/pages/noimageuploaded.png" height="300px"></div>
                            <div id="animated-thumbnails-gallery">
                              <lightgallery id='QuickPostGallery' #QuickPostGallery [settings]="settings" [onInit]="onInitGallery1" [onBeforeClose]="onBeforeClose">
                                <a *ngFor="let item of GalleryItems1"
                                [attr.data-video]="item.type === 'Videos' ? item.data : null"
                                [attr.href]="item.type === 'Images' ? item.src : null"
                                [data-poster]="item.type === 'Videos' ? item.thumb :null"
                                 className="gallery-item"
                                data-sub-html="<p>{{item.id}}</p>"
                            >
                                <img *ngIf="item.type=='Images'"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    src="{{item.src}}" alt="{{item.id}}"
                                />
                                <img *ngIf="item.type=='Videos'"
                                    class="img-responsive justify-gallery" loading="lazy"
                                    [src]="item.thumb" alt="{{item.id}}"
                                />
                            </a>
                              </lightgallery>
                              <div>
                                <a  *ngFor="let item of DocumentList1" href={{item.DocumentPath}} target="_blank"  className="gallery-item">
                                <img
                                class="img-responsive justify-gallery" loading="lazy"
                                src="assets/images/icons/document-icon.png"/></a>
                              </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="4"  [disabled]="!_data && !IsForEdit">
                  <a (click)="onTabActive(4)" ngbNavLink>{{'QUICKPOST.TAB_HEADING_IMAGES_AND_VIDEOS'|translate}}</a>
                  <ng-template ngbNavContent>
                    <div class="scroll-area">
                      <!-- <app-gallery></app-gallery> -->
                      <div class="row justify-content-center">
                        <div class="col">
                          <div *ngIf="GalleryItems.length==0"><img style="padding-left: 34%;padding-right: 50%;" src="assets/images/pages/noimageuploaded.png" height="350px"></div>
                          <div id="animated-thumbnails-gallery">
                            <lightgallery #QuickPostGallery [settings]="settings" [onInit]="onInitGallery" [onBeforeClose]="onBeforeClose"
                              [onBeforeSlide]="onBeforeSlide">
                              <a *ngFor="let item of GalleryItems"
                              [attr.data-video]="item.type === 'Videos' ? [item.data] : null"
                              [attr.href]="item.type === 'Images' ? item.src : null"
                              [data-poster]="item.type === 'Videos' ? [item.thumb] :null"
                               className="gallery-item"
                              data-sub-html="<p>{{item.id}}</p>"
                          >
                              <img *ngIf="item.type=='Images'" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class"
                                  class="img-responsive justify-gallery"  container="body"
                                  src="{{item.src}}" alt="{{item.id}}"
                              />
                              <img *ngIf="item.type=='Videos'" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class"
                                  class="img-responsive justify-gallery" container="body"
                                  [src]="item.thumb" alt="{{item.id}}"
                              />
                              <ng-template #tooltipTemplate >
                                <div class="newpop-up">
                                  <p style="font-weight: normal; margin: 0px; padding: 0px">Document Name: <span
                                      style="font-weight: bold;">{{ item?.name }}</span></p>
                                  <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location: <span style="font-weight: bold;">{{ folder?.Location }}</span></p> -->
                                  <p style="font-weight: normal; margin: 0px; padding: 0px">Document Type: <span
                                      style="font-weight: bold;">{{ item?.type}}</span></p>
                                  <p style="font-weight: normal; margin: 0px; padding: 0px">Document Size: <span
                                      style="font-weight: bold;">{{ item?.size}}</span></p>
                                  <p style="font-weight: normal; margin: 0px; padding: 0px">Created Date: <span
                                        style="font-weight: bold;">{{ item?.createdDate|date:dateformat1}}</span></p>    
                                  <p style="font-weight: normal; margin: 0px; padding: 0px">Created Time: <span
                                     style="font-weight: bold;">{{ item?.createdTime|date:timeformat1}}</span></p>   
                                  <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location Count: <span style="font-weight: bold;">{{ indexi }}/{{ totalLocations }}</span></p> -->
                                </div>
                              </ng-template> 
                          </a>
                              <!-- <a data-lg-size="1536-2304" data-pinterest-text="Pin it3"
                                data-tweet-text="lightGallery slide  4" class="gallery-item"
                                data-src="https://images.unsplash.com/photo-1508766206392-8bd5cf550d1c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1536&q=80"
                                data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@rea_le' >Andrea Ledda</a></h4><p>Location - <a href='https://unsplash.com/s/photos/lago-goillet%2C-italy'>Lago Goillet, Italy</a>  Goillet Lake at 2561 meters above Breuil-Cervinia</p>">
                                <img class="img-responsive justify-gallery"
                                  src="https://images.unsplash.com/photo-1508766206392-8bd5cf550d1c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
                              </a> -->
                            </lightgallery>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ngb-pagination #pagination  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="floatingloadmore-button " *ngIf="GalleryItems.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Images')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                      [boundaryLinks]="true">
                      <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                          [class]="'font-weight-bolder'"></span>Prev
                      </ng-template>
                      <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                          [class]="'font-weight-bolder'"></span>
                      </ng-template>
                    </ngb-pagination>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</div>
<div class="card-footer d-flex">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit&&tab<4" class="btn btn-primary mr-1" [disabled]="loading" placement="top"
        ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}" (click)="(onSubmit(false))" ><span
        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'QUICKPOST.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}
      </button>

    <button type="submit" *ngIf="!IsForEdit" class="btn btn-outline-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_SAVE_AND_CLOSE' | translate}}" (click)="onSubmit(true)"><span
        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'QUICKPOST.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}
      </button>

    <button type="submit" *ngIf="IsForEdit" (click)="onSubmit(true)" class="btn btn-primary" placement="top"
      ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_SAVE_CHANGES'|translate}}">{{'QUICKPOST.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button"  *ngIf="(tab<2&&!IsForEdit)&&!IsForAbandon" class="btn btn-outline-secondary ml-2" placement="top"
      (click)="onCancelClick()"
      ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_CANCEL'|translate}}">{{'QUICKPOST.BUTTON_TEXT_CANCEL'|translate}}</button>

    <button type="button" *ngIf="IsForEdit" (click)="onDeleteClick()" class="btn btn-outline-danger  ml-2"
      placement="top"
      ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_DELETE'|translate}}">{{'QUICKPOST.BUTTON_TEXT_DELETE'|translate}}</button>

      <button type="button" *ngIf="!IsForEdit&&tab>=2 ||IsForAbandon" (click)="onDeleteClick()" class="btn btn-outline-danger ml-1" placement="top"
        ngbTooltip="{{'QUICKPOST.BUTTON_TEXT_ABANDON'|translate}}">{{'QUICKPOST.BUTTON_TEXT_ABANDON'|translate}}
      </button>
  </div>
  <div class="align" *ngIf="uploaderWarning" >Attachment Uploaded:{{uploadCount}}/{{uploader?.queue?.length}}</div>
</div>