export class Filemanagermodel {
}
export class TblUserFolders {
    UserFolderId?: number;
    UserFolderName: string;
    UserDetailsId: number;
    BunnyFolderId?: number;
    OrderSequence?: number;
    ActiveYn?: boolean;
    CreatedDate?: string;
    CreatedTime?: string;
    CreatedBy?: number;
    ChangedDate?: string;
    ChangedTime?: string;
    ChangedBy?: number;
  }

  export class DeleteMultipleInputModel
    {
       DeleteIDs:Array<number>;
       UserDetailsID:number;
    }

    export class TblUserFileManagerDocuments {
      UserFileManagerDocumentsId?: number;
      DocumentId?:number;
      UserDetailsId?: number;
      UserFolderId?: number;
      DocumentName?: string;
      DocumentOldName?: string;
      DocumentPath?: string;
      DocumentSubType?: string;
      Size?: string;
      SizeInBytes?: number;
      DocumentType?: string;
      FileCreatedDate?: string; // Use string or Date depending on your preference
      FileLastModifiedDate?: string; // Use string or Date depending on your preference
      FileCreatedLatitude?: number;
      FileCreatedLongitude?: number;
      AllFileMetadata?: string;
      CreatedDate?: string; // Use string or Date depending on your preference
      CreatedTime?: string;
      CreatedBy?: number;
      ChangedDate?: string; // Use string or Date depending on your preference
      ChangedTime?: string;
      ChangedBy?: number;
    }
    
  