import { Injectable } from '@angular/core';
import { MyGalleryListInputModel, MyGalleryListOutputModel, ViewGalleryInputModel } from 'app/Models/image-gallery-model.model';
import { GlobalService } from 'app/Services/global.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyImageGalleryService {

  constructor(public globalService: GlobalService) { }
  private sharedData: any;
  GetGalleryList(_Obj: MyGalleryListInputModel) {  //: Observable<MyGalleryListOutputModel[]> 
    const path = 'api/ImageGallery/GetMyGalleryList';
    return this.globalService.PostDataById(_Obj, path);
  }

 ViewGallery(_Obj: ViewGalleryInputModel) {  //: Observable<ViewGalleryOutputModel[]> 
    const path = 'api/ImageGallery/ViewGallery';
    return this.globalService.PostDataById(_Obj, path);
  }
  setSharedData(data: any) {
    this.sharedData = data;
  }

  getSharedData() {
    return this.sharedData;
  }

  GetUserFolderData(data) {  //UserDetailsId
    const path = 'api/UserFolder/GetUserFolderData';
    return this.globalService.PostDataById(data, path);
  }

  CreateUpdateUserFolder(data) { 
    const path = 'api/UserFolder/CreateUpdateUserFolder';
    return this.globalService.PostDataById(data, path);
  }

  GetUserFolderById(data) { 
    const path = 'api/UserFolder/GetUserFolderById';
    return this.globalService.PostDataById(data, path);
  }

  GetUserFilesByFolderId(data) { 
    const path = 'api/UserFileManager/GetUserFilesByFolderId';
    return this.globalService.PostDataById(data, path);
  }

  DeleteUserFolder(data) { 
    const path = 'api/UserFolder/DeleteUserFolder';
    return this.globalService.PostDataById(data, path);
  }

  UpdateDescription(data) { 
    const path = 'api/ImageGallery/UpdateDescription';
    return this.globalService.PostDataById(data, path);
  }

  CreateUpdateDocumentLinks(data){
    const path = 'api/UserQuickPost/CreateUpdateDocumentLinks';
    return this.globalService.PostDataById(data, path);
  }

  UpdateDefaultThumbnailAsync(_Obj) {  //: Observable<MyGalleryListOutputModel[]> 
    const path = 'api/ImageGallery/UpdateDefaultThumbnail';
    return this.globalService.PostDataById(_Obj, path);
  }


}
