<div *ngIf="schoolLoadDataOutputModel$ | async as schoolLoadData">
  <div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header"style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
      <span data-feather="info"></span>
    </span><button class="close-button close" type="button"
        (click)="onCloseButtonClick()" placement="right"
        ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_CLOSE' | translate}}">&times;</button>
    </h5>
    <div class="card-body card-body-dialog">
      <form [formGroup]="frmSchool" (ngSubmit)="onSubmit()">
        <section id="basic-tabs-components">
          <div class="row">
            <div class="col-12">
              <div class="card-body" style="padding-bottom: 0px;">
                <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-pill-primary">
                  <li [ngbNavItem]="1" (click)="onTabActive(1)">
                    <a class="font" ngbNavLink>{{'SCHOOL.TAB_HEADING_ADDRESS_DETAILS' | translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="schoolInfoText">
                          <i>{{'SCHOOL.INFO_TEXT' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="row">
                          <div class="col-xl col-md-12">
                            <div class="form-group mb-0">
                              <app-text-box #addressTag [type]="'text'" [showRequiredSign]="true" [showInfoIcon]="true"
                              [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_ADDRESS_TAG'|translate" [position]="'bottom'"
                                formControlName="AddressTag" [label]="'SCHOOL.CONTROL_LABEL_ADDRESS_TAG'|translate"
                                [maxlength]="50" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate"
                                aria-describedby="AddressTag" for="AddressTag"
                                [classes]="{ 'is-invalid': submitted && f.AddressTag.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.AddressTag.errors">
                                <small *ngIf="f.AddressTag.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_ADDRESS_TAG'|translate}}</small>
                                <small *ngIf="f.AddressTag.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                              </span>
                            </div>
                          </div>
                          <div class="col-xl col-md-12">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" [showRequiredSign]="true" formControlName="SchoolName"
                                [label]="'SCHOOL.CONTROL_LABEL_SCHOOL_NAME'|translate" [maxlength]="50"[showInfoIcon]="true"
                                [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_EDUCATIONAL_INSTITUTION_NAME'|translate" [position]="'bottom'"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" aria-describedby="SchoolName"
                                for="SchoolName" [classes]="{ 'is-invalid': submitted && f.SchoolName.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.SchoolName.errors">
                                <small *ngIf="f.SchoolName.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_SCHOOL_NAME'|translate}}</small>
                                <small *ngIf="f.SchoolName.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                              </span>
                            </div>
                          </div>
                          <div class="col-xl col-md-12">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" [showRequiredSign]="true"
                                formControlName="EducationReceived" [showInfoIcon]="true"
                                [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_DEGREE_COMPLETED'|translate" [position]="'bottom'"
                                [label]="'SCHOOL.CONTROL_LABEL_EDUCATION_RECEIVED'|translate"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate"
                                aria-describedby="EducationReceived" for="EducationReceived"
                                [classes]="{ 'is-invalid': submitted && f.EducationReceived.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.EducationReceived.errors">
                                <small *ngIf="f.EducationReceived.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_EDUCATION_RECEIVED'|translate}}</small>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-sm">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" [showRequiredSign]="true" formControlName="Address1"
                                [label]="'SCHOOL.CONTROL_LABEL_ADDRESS1'|translate" [maxlength]="50" [showInfoIcon]="true"
                                [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_STREET_NAME'|translate" [position]="'right'"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" aria-describedby="Address1"
                                for="Address1" [classes]="{ 'is-invalid': submitted && f.Address1.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.Address1.errors">
                                <small *ngIf="f.Address1.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_ADDRESS1' | translate}}</small>
                                <small *ngIf="f.Address1.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50' | translate}}</small>
                              </span>
                            </div>
                          </div>

                          <div class="col-lg-4 col-sm pt-1">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" formControlName="Address2" [showInfoIcon]="true"
                              [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1'|translate" [position]="'right'"
                                [label]="'SCHOOL.CONTROL_LABEL_ADDRESS2'|translate" [maxlength]="50"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" aria-describedby="Address2"
                                for="Address2">
                              </app-text-box>
                              <span *ngIf="submitted && f.Address2.errors">
                                <small *ngIf="f.Address2.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50' | translate}}</small>
                              </span>
                            </div>
                          </div>

                          <div class="col-lg-4 col-sm">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" formControlName="Address3" [showInfoIcon]="true"
                              [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2'|translate" [position]="'right'"
                                [label]="'SCHOOL.CONTROL_LABEL_ADDRESS3'|translate" [maxlength]="50"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" aria-describedby="Address3"
                                for="Address3">
                              </app-text-box>
                              <span *ngIf="submitted && f.Address3.errors">
                                <small *ngIf="f.Address3.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50' | translate}}</small>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-sm">
                            <div class="form-group mb-0" style="margin-top: -3px">
                              <app-text-box [type]="'text'" [showRequiredSign]="true" formControlName="AddressTown"
                                [label]="'SCHOOL.CONTROL_LABEL_ADDRESS_TOWN'|translate" [maxlength]="50" [showInfoIcon]="true"
                                [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_TOWN_VILLAGE_CITY'|translate" [position]="'right'"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" aria-describedby="AddressTown"
                                for="AddressTown" [classes]="{ 'is-invalid': submitted && f.AddressTown.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.AddressTown.errors">
                                <small *ngIf="f.AddressTown.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_ADDRESS_TOWN'|translate}}</small>
                                <small *ngIf="f.AddressTown.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                              </span>
                            </div>
                          </div>

                          <div class="col-lg-4 col-sm">
                            <div class="form-group mb-0">
                              <app-text-box [type]="'text'" [showRequiredSign]="true" formControlName="AddressPostCode"
                                [label]="'SCHOOL.CONTROL_LABEL_ADDRESS_POST_CODE'|translate" [maxlength]="50"
                                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE'|translate" [showInfoIcon]="true"
                                [tooltipInfo]="'SCHOOL.TOOLTIP_TEXT_POSTAL_ZIPCODE'|translate" [position]="'right'"
                                aria-describedby="AddressPostCode" for="AddressPostCode"
                                [classes]="{ 'is-invalid': submitted && f.AddressPostCode.errors }">
                              </app-text-box>
                              <span *ngIf="submitted && f.AddressPostCode.errors">
                                <small *ngIf="f.AddressPostCode.errors.required"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_ADDRESS_POST_CODE' |
                                  translate}}</small>
                                <small *ngIf="f.AddressPostCode.errors.maxlength"
                                  class="text-danger">{{'SCHOOL.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-3 col-sm col-lg-4 py-4" style="top:9px;">
                            <button type="button" class="btn btn-primary px-3" style="width:100%" [disabled]="loading"
                              placement="top" (click)="onSearchOnMapClick()"
                              ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP' | translate}}"><span
                                *ngIf="loading"></span><i data-feather="search" class="mr-0 mr-sm-1"></i><span
                                class="d-none d-lg-inline-block px-1">
                                {{'SCHOOL.BUTTON_TEXT_SEARCH_ON_MAP'|translate}}</span></button>
                          </div>
                        </div>
                        <div class="row">
                        
                          <div class="col-sm col-lg-4">
                            <div class="form-group" style="margin-top: -3px">
                              <label class="form-label"for="StateId">{{'SCHOOL.CONTROL_LABEL_ADDRESS_STATE'|translate}}
                                <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_STATE_PROVINCE'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                <strong>*</strong></label>
                              <ng-select [items]="schoolLoadData?.StateList" bindLabel="StateName" selectOnTab="true"
                                bindValue="StateId" id="StateId" name="StateId" formControlName="StateId"
                                [required]="true" [placeholder]="PlaceholderState"
                                (ngModelChange)="onStateChange($event)"></ng-select>
                              <span *ngIf="submitted && f.StateId.invalid">
                                <small class="text-danger"
                                  *ngIf="f.StateId.errors.required">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_ADDRESS_STATE'|translate}}</small>
                              </span>
                            </div>
                          </div>

                          <div class="col-sm col-lg-4">
                            <div class="form-group" style="margin-top: -3px">
                              <label class="form-label" for="CountryId">{{'SCHOOL.CONTROL_LABEL_COUNTRY' |translate}}
                                <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_COUNTRY'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                <strong> *</strong></label>
                              <ng-select [items]="schoolLoadData?.CountryList" bindLabel="CountryName"
                                selectOnTab="true" bindValue="CountryId" id="CountryId" name="CountryId"
                                formControlName="CountryId" [required]="true" [placeholder]="PlaceholderCountry"
                               ></ng-select>
                              <span *ngIf="submitted && f.CountryId.invalid">
                                <small class="text-danger"
                                  *ngIf="f.CountryId.errors.required">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_COUNTRY' |
                                  translate}}</small>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm col-lg-4">
                            <div class="form-group">
                              <label class="form-label" for="RegionId">{{'SCHOOL.CONTROL_LABEL_REGION' | translate}}
                                <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_REGION_CONTINENT'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                <strong> *</strong></label>
                              <ng-select [items]="schoolLoadData?.RegionList" bindLabel="RegionName" selectOnTab="true"
                                bindValue="RegionId" id="RegionId" name="RegionId" formControlName="RegionId"
                                [required]="true" [placeholder]="PlaceholderRegion"
                                ></ng-select>
                              <span *ngIf="submitted && f.RegionId.invalid">
                                <small class="text-danger"
                                  *ngIf="f.RegionId.errors.required">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_REGION' |
                                  translate}}</small>
                              </span>
                            </div>
                          </div>
                          
                        </div>
                        <div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="checked" 
                              formControlName="CurrentSchoolYn"  />
                            <label class="form-check-label" for="inlineCheckbox2">{{'SCHOOL.LABEL_CURRENT_ADDRESS'|translate}}
                              <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_CURRENT_ADDRESS'|translate}}" container="body" placement="right">
                                <span data-feather="help-circle"
                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span></label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm">
                            <div class="form-group">
                              <label class="form-label"
                                for="SchoolFromDate">{{'SCHOOL.CONTROL_LABEL_DATE_FROM'|translate}}
                                <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_STAY_DATE_RANGE_1'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                                <strong>*</strong></label>
                              <div class="input-group">
                                <input id="SchoolFromDate" name="SchoolFromDate" formControlName="SchoolFromDate"
                                  [maxDate]="maxFromDate" class="form-control"
                                  [class.is-invalid]="submitted && f.SchoolFromDate.errors" [bsValue]="date"
                                  [placement]="'top'" [placeholder]="dateformat"
                                  [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat: dateformat }"
                                  required bsDatepicker #dpFromDate="bsDatepicker">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-primary feather icon-calendar"
                                    (click)="dpFromDate.toggle()" type="button" rippleEffect placement="top"
                                    ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_CALENDAR' | translate}}"
                                    tabindex="-1"></button>
                                </div>
                              </div>
                              <span *ngIf="submitted && f.SchoolFromDate.invalid">
                                <small class="text-danger"
                                  *ngIf="f.SchoolFromDate.errors.required">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_DATE_FROM'|translate}}</small>
                              </span>
                            </div>
                          </div>
                          <div class="col-sm">
                            <div class="form-group">
                              <label class="form-label" for="SchoolToDate">{{'SCHOOL.CONTROL_LABEL_DATE_TO'|translate}}
                                <span ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_STAY_DATE_RANGE_2'|translate}}" placement="right">
                                  <span data-feather="help-circle"
                                    class="info-icon align-middle mr-sm-25 mr-0"></span></span></label>
                              <div class="input-group">
                                <input id="SchoolToDate" name="SchoolToDate" formControlName="SchoolToDate"
                                  class="form-control" [class.is-invalid]="submitted && f.SchoolToDate.errors"
                                  [bsValue]="date" [placement]="'top'" [placeholder]="dateformat"
                                  [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat: dateformat }"
                                   bsDatepicker #dpToDate="bsDatepicker"
                                  (ngModelChange)="OnSchoolToDateChange($event)">
                                <div class="input-group-append">
                                  <button class="btn btn-outline-primary feather icon-calendar" container="body"
                                    (click)="dpToDate.toggle()" type="button" rippleEffect placement="top"
                                    ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_CALENDAR' | translate}}"
                                    tabindex="-1"></button>
                                </div>
                              </div>
                              <span *ngIf="submitted && f.SchoolToDate.invalid">
                                <small class="text-danger"
                                  *ngIf="f.SchoolToDate.errors.required">{{'SCHOOL.ERROR_MESSAGE_REQUIRED_DATE_TO'|translate}}</small>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="2" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(2)" ngbNavLink>{{'SCHOOL.TAB_HEADING_NOTES'|translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="schoolInfoText">
                          <i>{{'SCHOOL.INFO_TEXT4' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <label for="basicTextarea"
                          style="color: #323091;">{{'SCHOOL.CONTROL_LABEL_NOTES'|translate}}</label>
                        <fieldset class="form-group">
                          <textarea class="form-control" formControlName="Notes" rows="14" #notes
                            placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                        </fieldset>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="3" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(3)" ngbNavLink>{{'SCHOOL.TAB_HEADING_UPLOAD'|translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="schoolInfoText">
                          <i>{{'SCHOOL.INFO_TEXT1' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="row">
                          <div class="col">
                            <div class="row ml-0">
                            <h4 class="font pt-4" style="width: fit-content;">{{'SCHOOL.HEADING_TEXT_SELECT_IMAGES' | translate}}</h4>
                            <!-- <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                                <div class="custom-file">
                                  <input type="file" id="MultiFileUploaderSchool" (change)="onFileSelected($event)" class="custom-file-input" type="file"
                                  ng2FileSelect [uploader]="uploader" multiple id="file-upload-multiple" />
                                  <button class="custom-file-label font btn btn-primary custom-file-upload-btn"
                                    for="customFile">Upload</button>
                                </div>
                            </fieldset> -->
                            <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                              <div ngbDropdown class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  ngbDropdownToggle
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  rippleEffect
                                >
                                  Browse
                                </button>
                                <div ngbDropdownMenu class="dropdown-menu-rightnew" >
                                  <a ngbDropdownItem (click)="openFilemanager()">From Noosom Media</a>
                                  <a ngbDropdownItem (click)="triggerFileUpload()">From External Device</a>
                                </div>
                              <!-- </div> -->
                            </div>
                                <!-- Hidden File Input -->
                                <div class="custom-file" style="display: none;">
                                  <input type="file" id="MultiFileUploaderSchool" class="custom-file-input" (change)="onFileSelected($event)"
                                    ng2FileSelect [uploader]="uploader" multiple #fileInput />
                                  <label class="custom-file-label font" for="file-upload-multiple">{{'UPLOAD.PLACEHOLDER_CHOOSE_FILES' | translate}}</label>
                                </div>
                              </fieldset>
                          </div>
                            <h5 style="color: #323091;">{{'SCHOOL.TEXT_FILE_TYPES' | translate}}
                              image, tiff, bmp, png, jpg, jpeg, pdf, mp4, mpeg, mov, avi, ogv, webm, 3gp, mpg, wmv, flv.
                            </h5>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col overflow-auto">
                            <!-- <div class="d-flex justify-content-between mb-1">
                              <h4 class="font">{{'SCHOOL.HEADING_TEXT_UPLOAD_QUEUE' | translate}}</h4>
                              <p>{{'SCHOOL.TEXT_QUEUE_LENGTH' | translate}} {{ uploader?.queue?.length }}</p>
                            </div>

                            <table class="table">
                              <thead style="color: #323091;">
                                <tr>
                                  <th width="50%" text-align="left">{{'SCHOOL.COLUMN_HEADING_NAME' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_SIZE' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_PROGRESS' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_STATUS' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_ACTIONS' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let item of uploader.queue">
                                  <td>
                                    <strong>{{ item?.file?.name }}</strong>
                                  </td>
                                  <td *ngIf="uploader.options.isHTML5" nowrap>
                                    {{ item?.file?.size / 1024 / 1024 | number: '.2' }} {{'SCHOOL.TEXT_MB' | translate}}
                                  </td>
                                  <td *ngIf="uploader.options.isHTML5">
                                    <ngb-progressbar type="primary" striped="true" animated="true"
                                      [value]="item.progress"></ngb-progressbar>
                                  </td>
                                  <td class="text-center">
                                    <span *ngIf="item.isSuccess"><i data-feather="check-circle"></i></span>
                                    <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                                    <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                                  </td>
                                  <td nowrap>
                                    <button type="button" class="btn btn-raised btn-success btn-sm mr-50"
                                      ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_UPLOAD' | translate}}"
                                      (click)="item.upload()"
                                      [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                      <span data-feather="upload"></span>
                                    </button>
                                    <button type="button" class="btn btn-raised btn-danger btn-sm"
                                      ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_REMOVE' | translate}}"
                                      (click)="item.remove();checkQueueLength(uploader?.queue?.length)" rippleEffect>
                                      <i data-feather="trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div>
                              <p class="mt-1 font">{{'SCHOOL.HEADING_TEXT_QUEUE_PROGRESS' | translate}}</p>
                              <ngb-progressbar class="mb-1" type="primary" [striped]="true" [animated]="true"
                                [value]="uploader.progress"></ngb-progressbar>
                              <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                                (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length"
                                rippleEffect ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_UPLOAD_ALL' | translate}}">
                                <span data-feather="upload" class="mr-25"></span> {{'SCHOOL.BUTTON_TEXT_UPLOAD_ALL' |
                                translate}}
                              </button>
                              <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                                ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_REMOVE_ALL' | translate}}"
                                (click)="uploader.clearQueue();checkQueueLength(uploader?.queue?.length)" [disabled]="!uploader.queue.length" rippleEffect>
                                <span data-feather="trash" class="mr-25"></span> {{'SCHOOL.BUTTON_TEXT_REMOVE_ALL' |
                                translate}}
                              </button>
                            </div> -->
                            <div *ngIf="GalleryItems1.length==0 && DocumentList1.length==0"><img style="padding-left: 34%;"
                              src="assets/images/pages/noimageuploaded.png" height="300px"></div>
                          <div id="animated-thumbnails-gallery">
                            <lightgallery #SchoolGallery id="SchoolGallery" [settings]="settings" [onBeforeClose]="onBeforeClose"
                              [onInit]="onInitGallery1" [onBeforeSlide]="onBeforeSlide">
                              <a *ngFor="let item of GalleryItems1"
                                [attr.data-video]="item.type === 'Videos' ? [item.data] : null"
                                [attr.href]="item.type === 'Images' ? item.src : null"
                                [data-poster]="item.type === 'Videos' ? [item.thumb] : null"
                                className="gallery-item" data-sub-html="<p>{{item.id}}</p>">
                                <img *ngIf="item.type=='Videos'" class="img-responsive justify-gallery" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class" container="body"
                                  [src]="item.thumb" alt="{{item.id}}" />
                                <img *ngIf="item.type=='Images'" class="img-responsive justify-gallery" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class" container="body"
                                  src="{{item.src}}" alt="{{item.id}}" />
                                  <ng-template #tooltipTemplate >
                                    <div class="newpop-up">
                                      <p style="font-weight: normal; margin: 0px; padding: 0px">Document Name: <span
                                          style="font-weight: bold;">{{ item?.name }}</span></p>
                                      <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location: <span style="font-weight: bold;">{{ folder?.Location }}</span></p> -->
                                      <p style="font-weight: normal; margin: 0px; padding: 0px">Document Type: <span
                                          style="font-weight: bold;">{{ item?.type}}</span></p>
                                      <p style="font-weight: normal; margin: 0px; padding: 0px">Document Size: <span
                                          style="font-weight: bold;">{{ item?.size}}</span></p>
                                      <p style="font-weight: normal; margin: 0px; padding: 0px">Created Date: <span
                                            style="font-weight: bold;">{{ item?.createdDate|date:dateformat}}</span></p>    
                                      <p style="font-weight: normal; margin: 0px; padding: 0px">Created Time: <span
                                         style="font-weight: bold;">{{ item?.createdTime|date:timeformat}}</span></p>   
                                      <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location Count: <span style="font-weight: bold;">{{ indexi }}/{{ totalLocations }}</span></p> -->
                                    </div>
                                  </ng-template> 
                              </a>
                            </lightgallery>
                            <div >
                              <a *ngFor="let item of DocumentList1" href={{item.DocumentPath}} target="_blank"  className="gallery-item">
                              <img
                              class="img-responsive justify-gallery" loading="lazy"
                              src="assets/images/icons/document-icon.png" 
                          /></a>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="4" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(4)" ngbNavLink>{{'SCHOOL.TAB_HEADING_GALLERY'|translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <p>This is gallery.</p> -->
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="schoolInfoText">
                          <i>{{'SCHOOL.INFO_TEXT2' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <div class="row justify-content-center">
                          <div class="col">
                            <div *ngIf="GalleryItems.length==0"><img style="padding-left: 34%;padding-right: 50%;"
                                src="assets/images/pages/noimageuploaded.png" height="350px"></div>
                            <div id="animated-thumbnails-gallery">
                              <lightgallery #SchoolGallery id="SchoolGallery" [settings]="settings" [onBeforeClose]="onBeforeClose"
                                [onInit]="onInitGallery" [onBeforeSlide]="onBeforeSlide">
                                <a *ngFor="let item of GalleryItems"
                                  [attr.data-video]="item.type === 'Videos' ? [item.data] : null"
                                  [attr.href]="item.type === 'Images' ? item.src : null"
                                  [data-poster]="item.type === 'Videos' ? [item.thumb] : null"
                                  className="gallery-item" data-sub-html="<p>{{item.id}}</p>">
                                  <img *ngIf="item.type=='Videos'" class="img-responsive justify-gallery" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class" container="body"
                                    [src]="item.thumb" alt="{{item.id}}" />
                                  <img *ngIf="item.type=='Images'" class="img-responsive justify-gallery" [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class" container="body"
                                    src="{{item.src}}" alt="{{item.id}}" />
                                    <ng-template #tooltipTemplate >
                                      <div class="newpop-up">
                                        <p style="font-weight: normal; margin: 0px; padding: 0px">Document Name: <span
                                            style="font-weight: bold;">{{ item?.name }}</span></p>
                                        <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location: <span style="font-weight: bold;">{{ folder?.Location }}</span></p> -->
                                        <p style="font-weight: normal; margin: 0px; padding: 0px">Document Type: <span
                                            style="font-weight: bold;">{{ item?.type}}</span></p>
                                        <p style="font-weight: normal; margin: 0px; padding: 0px">Document Size: <span
                                            style="font-weight: bold;">{{ item?.size}}</span></p>
                                        <p style="font-weight: normal; margin: 0px; padding: 0px">Created Date: <span
                                              style="font-weight: bold;">{{ item?.createdDate|date:dateformat1}}</span></p>    
                                        <p style="font-weight: normal; margin: 0px; padding: 0px">Created Time: <span
                                           style="font-weight: bold;">{{ item?.createdTime|date:timeformat}}</span></p>   
                                        <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location Count: <span style="font-weight: bold;">{{ indexi }}/{{ totalLocations }}</span></p> -->
                                      </div>
                                    </ng-template> 
                                </a>
                              </lightgallery>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ngb-pagination #pagination  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="floatingloadmore-button " *ngIf="GalleryItems.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Images')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                            [class]="'font-weight-bolder'"></span>Prev
                        </ng-template>
                        <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                            [class]="'font-weight-bolder'"></span>
                        </ng-template>
                      </ngb-pagination>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5" [disabled]="!_data && !IsForEdit">
                    <a class="font" (click)="onTabActive(5)" ngbNavLink>{{'SCHOOL.TAB_HEADING_DOCUMENTS'|translate}}</a>
                    <ng-template ngbNavContent>
                      <!-- <div class="row py-3">
                        <div class="col-sm" id="schoolInfoText">
                          <i>{{'SCHOOL.INFO_TEXT3' | translate}}</i>
                        </div>
                      </div> -->
                      <div class="scroll-area">
                        <!-- <p>This is documents.</p>-->
                        <div class="col">
                          <div class="d-flex justify-content-between mb-1" style="max-height: 350px;overflow-x: auto;">
                            <table class="table">
                              <thead style="color: #323091;">
                                <tr>
                                  <th text-align="left" width="40%">{{'SCHOOL.COLUMN_HEADING_NAME' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_SIZE' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_DATE_CREATED' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_TYPE' | translate}}</th>
                                  <th>{{'SCHOOL.COLUMN_HEADING_ACTIONS' | translate}}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let document of DocumentList">
                                  <td>
                                    {{document.DocumentOldName}}
                                  </td>
                                  <td>
                                    {{ document.Size }}
                                  </td>
                                  <td>
                                    {{document.FileCreatedDate | date: 'dd/MM/yyyy' }}
                                  </td>
                                  <td>
                                    {{ document.FileType }}
                                  </td>
                                  <td nowrap>
                                    <button type="button" ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_DOWNLOAD'|translate}}" class="btn btn-raised btn-success btn-sm mr-50"
                                      (click)="onDownloadDocument(document)" rippleEffect>
                                      <i data-feather="download"></i>
                                    </button>
                                    <button type="button" ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_DELETE'|translate}}" class="btn btn-raised btn-danger btn-sm mr-50"
                                      (click)="onDeleteDocument(document.UserSchoolDocumentId)" rippleEffect>
                                      <i data-feather="trash-2"></i>
                                    </button>
                                    <a href={{document.DocumentPath}} target="_blank"><button type="button" ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_VIEW'|translate}}"
                                      class="btn btn-raised btn-warning btn-sm" rippleEffect>
                                      <i data-feather="eye"></i>
                                  </button></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <ngb-pagination #pagination cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
                            class="floatingloadmore-button" style="top: 85%;" *ngIf="DocumentList.length>0"
                            [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Documents')"
                            [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
                            <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                [class]="'font-weight-bolder'"></span>Prev
                            </ng-template>
                            <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                [class]="'font-weight-bolder'"></span>
                            </ng-template>
                          </ngb-pagination>
                          <div id="">
                            <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                              ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_DOWNLOAD_ALL' |translate}}"
                              (click)="onDownloadAllDocument() || async" rippleEffect>
                              <span data-feather="download" class="mr-25"></span> {{'SCHOOL.BUTTON_TEXT_DOWNLOAD_ALL' |
                              translate}}
                            </button>
                            <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                              ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_REMOVE_ALL' |translate}}"
                              (click)="onDeleteAllDocument() || async" [disabled]="!DocumentList.length" rippleEffect>
                              <span data-feather="trash-2" class="mr-25"></span> {{'SCHOOL.BUTTON_TEXT_REMOVE_ALL' |
                              translate}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-1"></div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div class="card-footer d-flex">
    <div class="align">
      <button type="submit" *ngIf="!IsForEdit&&tab<5" class="btn btn-primary mr-1" [disabled]="loading" placement="top"
        ngbTooltip="{{'SCHOOL.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}" (click)="(onSubmit(false))"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>{{'SCHOOL.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}
      </button>

      <button type="submit" *ngIf="!IsForEdit" class="btn btn-outline-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'SCHOOL.BUTTON_TEXT_SAVE_AND_CLOSE' | translate}}" (click)="onSubmit(true)"><span *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>{{'SCHOOL.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}
      </button>

      <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
        ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_SAVE_CHANGES' | translate}}" (click)="onSubmit(true)"><span
          *ngIf="loading"
          class="spinner-border spinner-border-sm mr-1"></span>{{'SCHOOL.BUTTON_TEXT_SAVE_CHANGES'|translate}}
      </button>

      <button type="button" *ngIf="(tab<2||IsForEdit)&&!IsForAbandon" (click)="onCancelClick()"
        class="btn btn-outline-secondary ml-1" placement="top"
        ngbTooltip="{{'SCHOOL.TOOLTIP_TEXT_BUTTON_CANCEL' | translate}}">{{'SCHOOL.BUTTON_TEXT_CANCEL'|translate}}
      </button>

      <button type="button" *ngIf="!IsForEdit&&tab>=2 || IsForAbandon" (click)="OnSchoolDeleteClick()"
        class="btn btn-outline-danger ml-1" placement="top"
        ngbTooltip="{{'SCHOOL.BUTTON_TEXT_ABANDON'|translate}}">{{'SCHOOL.BUTTON_TEXT_ABANDON'|translate}}
      </button>
    </div>
    <div class="align" *ngIf="uploaderWarning">Attachment Uploaded:{{uploadCount}}/{{uploader?.queue?.length}}</div>
  </div>
</div>
<!-- </mat-dialog-content> -->