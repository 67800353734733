import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    FILEMANAGER: {
      HEADING_LABEL_FILE_MANAGER: 'फ़ाइल प्रबंधक',
      LABEL_ADD_FOLDER: 'फ़ोल्डर जोड़ें',
      LABEL_DELETE_FOLDER: 'फ़ोल्डर हटाएं',
      LABEL_SELECT_ALL: 'सभी का चयन करें',
      LABEL_FOLDER_NAME_REQUIRED: 'फ़ोल्डर का नाम आवश्यक है!',
      LABEL_CHARACTER_COUNT:'अक्षर गणना',
      LABEL_MAX_CHARACTERS_20: 'आपने बहुत अधिक वर्ण टाइप किए हैं, अधिकतम अनुमति 20 है',
      LABEL_CONFIRM: 'पुष्टि करें',
      LABEL_CANCEL: 'रद्द करें',
      LABEL_DELETE: 'हटाएं',
      LABEL_VIEW: 'देखें',
      LABEL_UPDATE: 'अपडेट',
      LABEL_PLACEHOLDER: 'फोल्डर का नाम दर्ज करें',
      LABEL_CREATE_FOLDER: 'फोल्डर बनाएं',
      LABEL_UPDATE_FOLDER: 'फोल्डर अपडेट करें',
      LABEL_TOOLTIP: 'चयन को टॉगल करें'

    },
    BLOGS: {
      CONTROL_LABEL_VIEW_PUBLIC_BLOG: 'मेरा सार्वजनिक ब्लॉग देखें',
      LABEL_COVER_PICTURE: 'आपकी कवर तस्वीर',
      LABEL_CROP: 'क्रॉप करें',
      LABEL_RESET: 'रीसेट करें',
      LABEL_YOUR_PROFILE_PICTURE: 'आपकी प्रोफाइल तस्वीर',
      LABEL_DATE_RANGE: 'दिनांक सीमा',
      LABEL_MONTH: 'महीना',
      LABEL_FROM_DATE: 'प्रारंभ तिथि',
      LABEL_TO_DATE: 'समाप्ति तिथि',
      LABEL_SEARCH: 'खोजें',
      LABEL_ON_THIS_DAY: 'इस दिन',
      LABEL_YEARS_AGO: 'साल पहले',
      LABEL_STAY_ANONYMOUS: 'गुमनाम रहें',
      LABEL_YES: 'हाँ',
      LABEL_NO: 'नहीं',
      LABEL_ABOUT_ME: 'मेरे बारे में',
      LABEL_JOINED: 'शामिल हुए',
      LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      LABEL_MY_PERSONAL_URL: 'मेरा व्यक्तिगत यूआरएल',
      LABEL_LIVES: 'रहते हैं',
      TOOLTIP_TEXT_COPY: 'कॉपी',
      TOOLTIP_TEXT_EXTERNAL_LINK: 'एक नए टैब में खोलें',
    }
  }
};
