export class PastimeModel {
}
export class UserPastimeModel {
    UserPastimeId: number;
    UserDetailsId: number;
    DateFrom:string;
    DateTo: string;
    PastimeName: string;
    ShortDescription: string;
    LongDescription: string;
    Notes:string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
  }

  export class TblUserPastimes {
    UserPastimeId: number;
    UserDetailsId: number;
    DateFrom: string;
    DateTo: string;
    PastimeName: string;
    ShortDescription: string;
    LongDescription: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
  }
  
  
  export class PastimeLoadDataInputModel
{
  UserPastimeId:number;
  GetMetaDataYN?:boolean;
  docYN?:boolean;
}

export class PastimeLoadDataOutputModel {
  UserPastime: TblUserPastimes;
  PastimeDocumentList: Array<PastimeDocumentListOutputModel>;
}

export class PastimeDocumentListOutputModel {
  DocumentId: number;
  UserDetailsId: number;
  UserPastimeId: number;
  DocumentName: string;
  DocumentOldName: string;
  DocumentPath: string;
  DocumentSubType: string;
  Size: string;
  DocumentType: string;
  FileCreatedDate: string;
  FileLastModifiedDate: string;
  FileCreatedLatitude: number;
  FileCreatedLongitude: number;
  AllFileMetadata: string;
  CreatedDate: string;
  CreatedTime: string;
  CreatedBy: number;
  ChangedDate: string;
  ChangedTime: string;
  ChangedBy: number;
}

export class UserHobbyModel {
  UserHobbyId: number;
  UserDetailsId: number;
  DateFrom: string;
  DateTo: string;
  HobbyName: string;
  ShortDescription: string;
  LongDescription: string;
  Notes:string;
  CreatedDate: string;
  CreatedTime: string;
  CreatedBy: number;
  ChangedDate: string;
  ChangedTime: string;
  ChangedBy: number;
}

export class TblUserHobbies
{
  UserHobbyId: number;
  UserDetailsId: number;
  DateFrom: string;
  DateTo: string;
  HobbyName: string;
  ShortDescription: string;
  LongDescription: string;
  Notes:string;
  CreatedDate: string;
  CreatedTime: string;
  CreatedBy: number;
  ChangedDate: string;
  ChangedTime: string;
  ChangedBy: number;
}

export class HobbyLoadDataOutputModel
{
    UserHobby:TblUserHobbies;
    HobbyDocumentList:Array<HobbyDocumentListOutputModel>
}
export class HobbyLoadDataInputModel
{
    UserHobbyId:number;
    GetMetaDataYN?:boolean;
    docYN?:boolean;
}

export class HobbyDocumentListOutputModel {
  DocumentId: number;
  UserDetailsId: number;
  UserHobbyId: number;
  DocumentName: string;
  DocumentOldName: string;
  DocumentPath: string;
  DocumentSubType: string;
  Size: string;
  DocumentType: string;
  FileCreatedDate: Date ;
  FileLastModifiedDate: Date ;
  FileCreatedLatitude: number ;
  FileCreatedLongitude: number ;
  AllFileMetadata: string;
  CreatedDate: string;
  CreatedTime: string;
  CreatedBy: number;
  ChangedDate: string;
  ChangedTime: string;
  ChangedBy: number;
}

