export class Enums {

}
export enum Status {
    Success = 0,
    Error = 1,
    UserNotFound = 2,
    InvalidCredential = 3,
    AccountLocked = 4,
    EmailNotVarified = 5,
    OtpVerifiedSuccess = 6,
    InvalidOTP = 7,
    InvalidUserId = 8,
    InvalidData = 9,
    DataNotFound = 10,
    ExceptionError = 11,
    RecordAdded = 12,
    RecordUpdated = 13,
    StorageFull = 14,
    Warning = 15,
    Information = 16,
    PGError = 18
}
export enum FileUploadCategory {
    ProfilePicture = 0,
    Post = 1
}

export enum ApplicationType {
    Web = 1,
    App = 2
}
export enum RandomStringType {
    DIGITS,
    UPPERCASE,
    LOWERCASE
}

export enum ActionType {
    Edit = 1,
    ViewMap = 2,
    Delete = 3,
    Download = 4,
    SeeRecord = 5,
    linkRecord = 6,
    viewRecord = 7,
    docDescription = 8
}

export enum MapModalType {
    AddressModel = 1,
    SchoolModel = 2,
    SearchString = 3,
    LatLong = 4,
    HealthProviderModel = 5,
    TblUserHealthProviders = 6,
    QuickPostModel = 7
}

export enum AttachmentType {
    Sample = 0,
    Address = 1,
    School = 2,
    QuickPost = 3,
    Blog = 4,
    NoosomCloud = 5,
}

export enum AchievementType {
    MyAchievement = 1,
    MyEducationalAchievement = 2,
    MyProfessionalAchievement = 3,
    MyMiscellaneousAchievement = 4,
}

export enum FamilyType {
    OwnFamily = 1,
    BirthFamily = 2,
    OtherFamily = 3,
}

export enum NoosomPlans {
    Noosom_Free_Forever = 1,
    Premium_1 = 2,
    Premium_2 = 3,
}

export enum FileType {
    All = 0,
    Images = 1,
    Documents = 2,
    Videos = 3
}

export enum NoosomCategoryID {
    User = 1,
    Test = 2,
    Staff = 3,
    Demo = 4,
    MemberofStaff = 5
}

export enum DAYFILTER {
    Today = 0,
    Tomorrow = 1,
    Yesterday = 2,
    This_Week = 3,
    Next_Week = 4,
    Previous_Week = 5,
    This_Month = 6,
    Next_Month = 7,
    Previous_Month = 8,
    This_Year = 9,
    Next_Year = 10,
    Previous_Year = 11,
    Custom = 12
}

export enum Language{
    English = 1,
    Hindi = 2
}

export enum TimeFormat {
    'HH:mm' = 1,
    'hh:mm:ss' = 2
}

export enum DateFormat {
    'dd/MM/yyyy' = 1,
    'dd-MM-yyyy' = 2,
    'dd.MM.yyyy' = 3,
    'dd MMM yyyy' = 4,
    'dd MMMM yyyy' = 5,
    'd/M/yyyy' = 6,
    'd-M-yyyy' = 7,
    'd.M.yyyy' = 8,
    'd MMM yyyy' = 9,
    'd MMMM yyyy' = 10,
    'dd/MM/y' = 11,
    'dd-MM-y' = 12,
    'dd.MM.y' = 13,
    'dd MMM y' = 14,
    'dd MMMM y' = 15,
    'd/M/y' = 16,
    'd-M-y' = 17,
    'd.M.y' = 18,
    'd MMM y' = 19,
    'd MMMM y' = 20
}
  export enum QuickPostType
        {
    All = 0,
    Private = 1,
    Family = 2,
    Friends = 3,
    Public = 4
}

export enum RecordsType
{
   All = 0, 
   Address = 1,
   School = 2,
   Insurance = 3,
   Professional = 4,
   Medication = 5,
   Vaccination = 6,
   Awards = 7,
   Achievement = 8,
   Jobs = 9,
   Friends = 10,
   Family = 11,
   QuickPost = 12,
   LifeStory = 13,
   MeOnTheWeb = 14,
   Pastime = 15,
   Hobby = 16,
   Legals = 17
}

export enum RelationType
{
    Friends = 1,
    Families = 2,
    // Quickpost = 3,
    // Blog = 4 
}

export enum SharedType
{
    SharedWithMe = 1,
    SharedByMe = 2 
}