import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/Classes/constant';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { EncrDecrService } from './encr-decr.service';
import { Role, User } from 'app/auth/models';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CountryISO } from 'ngx-intl-tel-input';
import { AuthenticationService } from 'app/auth/service';
import { Http } from '@angular/http';
import { Platform } from '@angular/cdk/platform';
import { RandomStringType } from 'app/enums';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  User: User = new User();
  //UserId = 35;
  FirstName: string = "";
  dateFormat: string;
  timeFormat: string;
  private urls = new BehaviorSubject<string[]>([]);
  currentUrls = this.urls.asObservable();
  constructor(
    private _http: HttpClient,
    private http: Http,
    private EncrDecr: EncrDecrService,
    private _authenticationService: AuthenticationService,
    private _platform: Platform
  ) { }

  GetData(path: string) {
    return this._http
      .get<any>(Constant.API + path)
      .pipe(
        map(result => {
          let decrypted = this.EncrDecr.get(Constant.EncrDecrKey, result.data);
          return decrypted;
        })
      );
  }

  GetMaxBirthDate(): Date {
    var date = new Date();
    //date.setDate(date.getDate() - 6);
    date.setFullYear(date.getFullYear() - 12);
    //$("#searchDateFrom").val((date.getMonth()) + '/' + (date.getDate()) + '/' + (date.getFullYear()));
    //console.log('max bdate');
    //console.log(date);
    return date;
  }

  base64ToArrayBuffer(base64) {
    //console.log('base64ToArrayBuffer');
    var binaryString = window.atob(base64);
    //console.log(binaryString);
    var binaryLen = binaryString.length;
    //console.log(binaryLen);
    var bytes = new Uint8Array(binaryLen);
    //console.log(bytes);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    //console.log(bytes);
    return bytes;
  }
  saveByteArray(fileName, byte, _type) {
    //console.log('saveByteArray');
    var blob = new Blob([byte], { type: _type });
    //console.log(blob);
    var link = document.createElement('a');
    //console.log(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    //console.log(link);
    link.style.visibility = 'hidden';
    //console.log(link);
    document.body.appendChild(link);
    //console.log('1');
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
      //console.log('2');
      //console.log('3');
    }, 50);
    //link.click();
  }

  GetDataWithAuthentication(path: string): Observable<any> {
    //var encrypted = { RequestMessage: this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(body)) };
    return this._http.get(Constant.API + path, { headers: this.SetHeaders() });
  }

  PostDataById(body: any, path: string): Observable<any> {
    var encrypted = { RequestMessage: this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(body)) };
    return this._http.post(Constant.API + path, encrypted, { headers: this.SetHeaders() });
  }

  PostDataWithoutId( path: string): Observable<any> {
    return this._http.post(Constant.API + path, { headers: this.SetHeaders() });
  }

  PostDataAnonymous(body: any, path: string): Observable<any> {
    var encrypted = { RequestMessage: this.EncrDecr.set(Constant.EncrDecrKey, JSON.stringify(body)) };
    return this.http.post(Constant.API + path, encrypted);
  }

  PostDataWithoutEncryption(body: any, path: string): Observable<any> {
    return this._http.post(Constant.API + path, body, { headers: this.SetHeaders() });
  }

  SignupTest() {
    //debugger;
    this.SetHeaders();
    this._http.get(Constant.API + 'api/Account/SignupTest1', { headers: this.SetHeaders() }).subscribe(data => {
    },
      error => {
        console.log('Log the error here: ', error);
      });
  }

  GetDateObject(date: NgbDateStruct): Date {
    return new Date(date.year, date.month, date.day);
  }

  GetCountryISOObject(shortCountryName: string): CountryISO {
    // console.log('shortCountryName');
    // console.log(shortCountryName);

    var Result: CountryISO.India;
    for (let key in CountryISO) {
      if (CountryISO[key] == shortCountryName) {
        // console.log('matched key:');
        // console.log(key);
        Result = CountryISO[key];
      }
    }
    // console.log(Result);
    return Result;
  }
  SetHeaders() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ` + this._authenticationService.currentUserValue.Token
    });
    return headers;
  }
  getLocalDate(StrDate: string): Date {
    var tempDate: Date = new Date(StrDate);
    var Year: number = tempDate.getFullYear();
    var Month: number = tempDate.getMonth();
    var Days: number = tempDate.getDate();
    var Hours: number = tempDate.getHours();
    var Minutes: number = tempDate.getMinutes()
    var Seconds: number = tempDate.getMilliseconds();
    var OffSetMin: number = (new Date().getTimezoneOffset());
    var TotalMinutes: number = (+Minutes) + (+OffSetMin) * -1;

    var dateTemp = new Date();
    dateTemp.setFullYear(Year);
    dateTemp.setMonth(Month);
    dateTemp.setDate(Days);
    dateTemp.setHours(Hours);
    dateTemp.setMinutes(TotalMinutes);
    dateTemp.setSeconds(Seconds);
    return dateTemp
  }

  GetUserCDNFolderPath(): string {
    let _res = '';
    if (this._authenticationService.currentUserValue)
      _res = Constant.CDNStorageRootURL + Constant.CDNStorageZoneName + Constant.CDNDefaultFolder + this._authenticationService.currentUserValue.CdnFolderName;
    return _res;
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }
  public getIPAddress() {
    return this._http.get("https://api.ipify.org/?format=json");
  }
  public GetBrowserName(): string {
    var browserName = "Other";

    if (this._platform.BLINK) {
      browserName = "Chrome";
    }
    else if (this._platform.EDGE) {
      browserName = "Microsoft Edge";
    }
    else if (this._platform.FIREFOX) {
      browserName = "Firefox";
    }
    else if (this._platform.SAFARI) {
      browserName = "Safari";
    }
    else if (this._platform.TRIDENT) {
      browserName = "Internet Explorer";
    }
    else if (this._platform.WEBKIT) {
      browserName = "Opera";
    }
    return browserName;
  }
  GetRandomString(type: RandomStringType, length: number): string {
    var data = "";
    var result = "";
    if (type == RandomStringType.DIGITS) {
      data = "0123456789"
    }
    else if (type == RandomStringType.UPPERCASE) {
      data = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    }
    else {
      data = "abcdefghijklmnopqrstuvwxyz"
    }
    for (var x = 1; x <= length; x++) {
      result += data[Math.floor(Math.random() * (data.length - 1))];
    }
    return result;
  }
  getSumByKey = (arr, key): number => {
    return arr.reduce((accumulator, current) => accumulator + Number(current[key]), 0)
  }
setDateFormat(format: string): void {
  if (format) {
    // Encrypt and store the date format in local storage
    const encryptedFormat = this.EncrDecr.set(Constant.EncrDecrKey, format);
    localStorage.setItem('dateformat', encryptedFormat);
    // Decrypt and set the date format
    this.dateFormat = format;
  }
}

// Get function to retrieve the date format
getDateFormat(): string {
  if (this.dateFormat) {
    return this.dateFormat;
  } else {
    const storedFormat = localStorage.getItem('dateformat');
    if (storedFormat) {
      this.dateFormat = this.EncrDecr.get(Constant.EncrDecrKey, storedFormat);
    } else {
      this.dateFormat = 'dd/MM/YYYY'; // Set your default date format here
    }
    return this.dateFormat;
  }
}


  setTimeFormat(format: string): void {
    if (format) {
      let timeFormat: string;
      if (format === '24 Hr') {
        timeFormat = 'HH:mm';
      } else if (format === '12 Hr') {
        timeFormat = 'hh:mm a';
      }
      if (timeFormat) {
        localStorage.setItem('timeformat', this.EncrDecr.set(Constant.EncrDecrKey, timeFormat));
        this.timeFormat = timeFormat;
      }
    }
  }
  
  // Get function to retrieve the date format
  getTimeFormat(): string {
    if (this.timeFormat) {
      return this.timeFormat;
    } else {
      const storedFormat = localStorage.getItem('timeformat');
      if (storedFormat) {
        this.timeFormat = this.EncrDecr.get(Constant.EncrDecrKey, storedFormat);
      } else {
        this.timeFormat = 'HH:mm';
      }
      return this.timeFormat;
    }
  }

  addUrls(newUrls: string[]) {
    const current = this.urls.getValue();
    this.urls.next([...current, ...newUrls]);
  }

  clearUrls() {
    this.urls.next([]);
  }


  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(this.EncrDecr.set(Constant.EncrDecrKey, value)));
  }

  // Method to get an item from localStorage
  getItem(key: string) {
    const item = this.EncrDecr.get(Constant.EncrDecrKey, JSON.parse(localStorage.getItem(key)));
    return item ;
  }
  
}
