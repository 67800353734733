import { Component, OnInit } from '@angular/core';
import { locale as english } from 'app/layout/components/menu/i18n/en';
import { locale as french } from 'app/layout/components/menu/i18n/fr';
import { locale as german } from 'app/layout/components/menu/i18n/de';
import { locale as portuguese } from 'app/layout/components/menu/i18n/pt';
import { locale as gujarati } from 'app/layout/components/menu/i18n/gj';
import { locale as hindi } from 'app/layout/components/menu/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { Constant } from 'app/Classes/constant';
import { RelationType, Status } from 'app/enums';
import { Observable } from 'rxjs';
import { E } from 'assets/extensions/extensions';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { GlobalService } from 'app/Services/global.service';
import { Router } from '@angular/router';
import { FriendsService } from 'app/about-me/friends/friends.service';
import { OwnFamilyService } from 'app/about-me/own-family/own-family.service';
import { FamilyMemberLoadDataOutputModel, FriendLoadDataOutputModel, TblUserFamilyMembers, TblUserFriends } from 'app/about-me/about-me-model.model';
import { MessagingService } from 'app/Services/messaging.service';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  styleUrls: ['./navbar-notification.scss'],
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public notification
  currentAppLanguage: any;
  public error = '';
  public loading = false;
  requestModel: any[]=[];
  userFriend:TblUserFriends;
  friendLoadDataOutputModel:FriendLoadDataOutputModel;
  userFamily:TblUserFamilyMembers;
  familyMemberLoadDataOutputModel:FamilyMemberLoadDataOutputModel;
  friendFamilydata: any;
  message: any;
  private intervalId: any;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService,
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private _toastrService: CustomToastrService,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private globalService: GlobalService,
    private loadingScreenService: LoadingScreenService,
    public friendService:FriendsService,
    public familyService: OwnFamilyService,
    private _encryptDecryptService: EncrDecrService,
    private messagingService: MessagingService
  ) {    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
      this._translateService.onLangChange.subscribe(lang => {
        // console.log('lang');
        // console.log(lang.lang);
        setTimeout(() => {
          let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
          if (_LangJsonObj)
            this.currentAppLanguage = _LangJsonObj;
        }, 100);
      })}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;

    });

   this.getnotification();
   if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'NEW_PUSH_NOTIFICATION') {
        console.log('New push notification received in component');
        this.getnotification();
       // this.handleNewNotification(event.data.payload); // Trigger the method when a notification arrives
      }
    });
  }
//  this.startRefreshing();
  }

  // ngOnDestroy(): void {
  //   this.stopRefreshing();
  // }

  // startRefreshing(): void {
  //   this.getnotification();
  //   this.intervalId = setInterval(() => {
  //     this.getnotification();
  //   }, 10000); // 10 seconds
  // }

  // stopRefreshing(): void {
  //   clearInterval(this.intervalId);
  // }

  getnotification(){
    if(this._authenticationService.currentUserValue){
      var inputModel = {
        UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
        ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
      };
      this.GetUserConnectionRequests(inputModel)
    }
  }

  GetUserConnectionRequests(inputModel) {
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this._notificationsService.GetUserConnectionRequests(inputModel)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.Success) {
            var connectionRequestData = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, result.data)))
            this.notification = connectionRequestData;
           // console.log(this.notification)
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
           this.loadingScreenService.stopLoading();
        }
      );
  }

  updateConnection(model,status){
    var connectionType = model
    this.loadingScreenService.startLoading();
     if(connectionType?.UserFamilyMemberId){
      this.requestModel.push({
        UserConnectionRequestId:connectionType?.UserConnectionRequestId,
        UserDetailsId:connectionType?.UserDetailsId,
        UserFamilyMemberId:connectionType?.UserFamilyMemberId,
        UserFriendId:null,
        ConnectionRequestStatusId:status,
        receiverUserDetailsId:connectionType?.ReceiverUserDetailsId,
        CreatedDate : E.GetTodayDateStringOnly(),
        CreatedTime : E.GetTodayTimeStringOnly(),
        CreatedBy : connectionType?.UserDetailsId,
        ChangedDate : E.GetTodayDateStringOnly(),
        ChangedTime : E.GetTodayTimeStringOnly(),
        ChangedBy : connectionType?.UserDetailsId
      })
      this.getUserRelationByType(model.EmailAddress,RelationType.Families)
     }
     else if(connectionType?.UserFriendId){
      this.requestModel.push({
        UserConnectionRequestId:connectionType?.UserConnectionRequestId,
        UserDetailsId:connectionType?.UserDetailsId,
        UserFamilyMemberId:null,
        UserFriendId:connectionType?.UserFriendId,
        ConnectionRequestStatusId:status,
        receiverUserDetailsId:connectionType?.ReceiverUserDetailsId,
        CreatedDate : E.GetTodayDateStringOnly(),
        CreatedTime : E.GetTodayTimeStringOnly(),
        CreatedBy : connectionType?.UserDetailsId,
        ChangedDate : E.GetTodayDateStringOnly(),
        ChangedTime : E.GetTodayTimeStringOnly(),
        ChangedBy : connectionType?.UserDetailsId
      })
      this.getUserRelationByType(model.EmailAddress,RelationType.Friends)
     }
    // console.log(this.requestModel)
    setTimeout(() => {
      this.loadingScreenService.startLoading();
      const path = 'api/UserConnectionRequest/CreateUpdateUserConnectionRequest'
      let response: Observable<any>;
      response = this.globalService.PostDataAnonymous(this.requestModel[0], path);
      response.subscribe(
            async (data) => {
           //  console.log(data)
             var _data = JSON.parse(data._body)
            // console.log(_data, JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, _data.data))))
              this.loadingScreenService.stopLoading();
              if (_data.status == Status.RecordAdded) {
               // console.log(JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, _data.data))))
                this._toastrService.Success("Request Action Completed !", Constant.SuccessToastrTitle);
                if(status==3){
                if(connectionType?.UserFamilyMemberId){
                  await this.setCreateUpdateFamily(model,status);
                }
                  else if(connectionType?.UserFriendId){
                   await this.setCreateUpdateFriend(model,status);
                  }}
                  if(status==4){
                    if(this._authenticationService.currentUserValue){
                      var inputModel = {
                        UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                        ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
                      };
                      this.GetUserConnectionRequests(inputModel);}
                  }
              }
              else  if (_data.status == Status.RecordUpdated) {
                 this._toastrService.Success("Request Action Completed !", Constant.SuccessToastrTitle);
                 if(status==3){
                 if(connectionType?.UserFamilyMemberId){
                  await this.setCreateUpdateFamily(model,status);
                }
                  else if(connectionType?.UserFriendId){
                   await this.setCreateUpdateFriend(model,status);
                  }}
                  if(status==4){
                     
            if(this._authenticationService.currentUserValue){
              var inputModel = {
                UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
              };
              this.GetUserConnectionRequests(inputModel);}
                  }
               }
              else if(_data.status == Status.Error){
              //  this._toastrService.Warning(_data.message, Constant.WarningToastrTitle);
                setTimeout(() => {
                  this._router.navigate(['/pages/miscellaneous/linkused']);
                }, 1000);
              }
            },
            (error) => {
            //  this._toastrService.Error(error, Constant.ErrorToastrTitle);
             // this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );
    }, 500);
  }

  callUpdateFunction(model,status){
    this.requestModel=[];
    var connectionType = model
    if(connectionType?.UserFamilyMemberId){
      this.userFamily = new TblUserFamilyMembers();
      this.updateConnection(model,status)
    }
    else if(connectionType?.UserFriendId){
      this.userFriend= new TblUserFriends();
      this.updateConnection(model,status)
    }
  }

  // getFriendLoadData(model,status){
  //   this.loadingScreenService.startLoading();
  //   // console.log(this.achievementsLoadDataInputModel);
  //   let response: Observable<any>;
  //   this.friendService.GetUserFriendLoadData(model?.UserFriendId)
  //     .subscribe(
  //       (data) => {
  //         this.loadingScreenService.stopLoading();
  //         if (data.status == Status.Success) {
  //           this.friendLoadDataOutputModel = <FriendLoadDataOutputModel>JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, data.data)));
  //         // console.log(this.friendLoadDataOutputModel)
  //           if (this.friendLoadDataOutputModel.UserFriend) {
  //             this.userFriend = this.friendLoadDataOutputModel.UserFriend;
  //             this.userFriend.ConnectionRequestStatusId = status;
  //            console.log(this.userFriend)
  //           this.updateConnection(model,status);
  //           }
  //         }
  //         else {
  //           this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
  //         }
  //       },
  //       (error) => {
  //         this.error = error;
  //         this.loadingScreenService.stopLoading();
  //       }
  //     );
  // }

  // getFamilyLoadData(model, status){
  //   this.loadingScreenService.startLoading();
  //   let response: Observable<any>;
  //   this.familyService.GetUserFamilyMemberLoadData(model?.UserFamilyMemberId)
  //     .subscribe(
  //       (data) => {
  //         this.loadingScreenService.stopLoading();
  //         if (data.status == Status.Success) {
  //           this.familyMemberLoadDataOutputModel = <FamilyMemberLoadDataOutputModel>JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, data.data)));
  //            //console.log(this.familyMemberLoadDataOutputModel)
  //           if (this.familyMemberLoadDataOutputModel.UserFamilyMember) {
  //             this.userFamily = this.familyMemberLoadDataOutputModel.UserFamilyMember;
  //             this.userFamily.ConnectionRequestStatusId = status;
  //             this.updateConnection(model,status);
  //           }
  //         }
  //         else {
  //           this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
  //         }
  //       },
  //       (error) => {
  //         this._toastrService.Error(E.ConvertToString(error), Constant.ErrorToastrTitle);
  //         this.error = error;
  //         this.loadingScreenService.stopLoading();
  //       }
  //     );
  // }

  getUserRelationByType(email,relation){
    var requestData={
       UserDetailsId:this._authenticationService.currentUserValue.UserDetailsId,
       EmailAddress:email,
       relationType:relation
     }
     if(relation == RelationType.Friends){this.friendFamilydata = new TblUserFriends();}
     else if(relation == RelationType.Families){this.friendFamilydata = new TblUserFamilyMembers();}
     setTimeout(() => {
       this.loadingScreenService.startLoading();
      // console.log(requestData)
       const path = 'api/UserConnectionRequest/GetUserRelationByType'
       let response: Observable<any>;
       response = this.globalService.PostDataAnonymous(requestData, path);
       response.subscribe(
             async (data) => {
             // console.log(data)
              var _data = JSON.parse(data._body)
           this.friendFamilydata = JSON.parse((this._encryptDecryptService.get(Constant.EncrDecrKey, _data.data)))
               this.loadingScreenService.stopLoading();
             },
             (error) => {
              // this._toastrService.Error(error, Constant.ErrorToastrTitle);
             //  this.error = error;
               this.loadingScreenService.stopLoading();
             }
           );
     }, 500);
   }

  setCreateUpdateFriend(model,status){
    if (this.friendFamilydata == null) {
      this.userFriend = new TblUserFriends();
    }

    if(this.friendFamilydata){
          this.userFriend = this.friendFamilydata;
    }
    else{
    this.userFriend.FirstName = model.UserFriendFirstName;
    this.userFriend.LastName = model.UserFriendLastName;
    this.userFriend.EmailAddress = model.EmailAddress;}
    this.userFriend.CreatedBy = this._authenticationService.currentUserValue.UserDetailsId;
    this.userFriend.ConnectionRequestStatusId = 3
    this.userFriend.CreatedDate = E.GetTodayDateStringOnly();
    this.userFriend.CreatedTime = E.GetTodayTimeStringOnly();
    this.userFriend.ChangedDate = E.GetTodayDateStringOnly();
    this.userFriend.ChangedTime = E.GetTodayTimeStringOnly();
    this.userFriend.UserDetailsId = this._authenticationService.currentUserValue.UserDetailsId;
    this.userFriend.ChangedBy = this._authenticationService.currentUserValue.UserDetailsId;
    this.loading = true;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    //console.log(this.userFriend)
    this.friendService.CreateUpdateUserFriend(this.userFriend)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            if(this._authenticationService.currentUserValue){
              var inputModel = {
                UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
              };
              this.GetUserConnectionRequests(inputModel);
              this.friendService.setGridRefresh(true);
            }
          }
          else if (result.status == Status.RecordUpdated) {
            if(this._authenticationService.currentUserValue){
              var inputModel = {
                UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
              };
              this.GetUserConnectionRequests(inputModel);
              this.friendService.setGridRefresh(true);
            }
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }

  setCreateUpdateFamily(model,status){
    if (this.friendFamilydata == null) {
      this.userFamily = new TblUserFamilyMembers();
    }
    if(this.friendFamilydata){
     this.userFamily = this.friendFamilydata;
    }else{
    this.userFamily.FirstName = model.UserFamilyMemberFirstName;
    this.userFamily.LastName = model.UserFamilyMemberLastName;
    this.userFamily.EmailAddress = model.EmailAddress;
    this.userFamily.FamilyMemberTypeId = model.UserFamilyMemberType;}
    this.userFamily.CreatedBy = this._authenticationService.currentUserValue.UserDetailsId;;
    this.userFamily.ConnectionRequestStatusId = 3
    this.userFamily.CreatedDate = E.GetTodayDateStringOnly();
    this.userFamily.CreatedTime = E.GetTodayTimeStringOnly();
    this.userFamily.ChangedDate = E.GetTodayDateStringOnly();
    this.userFamily.ChangedTime = E.GetTodayTimeStringOnly();
    this.userFamily.UserDetailsId = this._authenticationService.currentUserValue.UserDetailsId;;
    this.userFamily.ChangedBy = this._authenticationService.currentUserValue.UserDetailsId;;
    this.loadingScreenService.startLoading();
    let response: Observable<any>;
    //console.log(this.userFamily)
    this.familyService.CreateUpdateUserFamilyMember(this.userFamily)
      .subscribe(
        (result) => {
          this.loading = false;
          this.loadingScreenService.stopLoading();
          var message = ""; var errormessage = "";
       //  console.log(result)
          if (result.status == Status.RecordAdded) {
            if(this._authenticationService.currentUserValue){
              var inputModel = {
                UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
              };
              this.GetUserConnectionRequests(inputModel);
              this.familyService.setGridRefresh(true);
            }
          }
          else if (result.status == Status.RecordUpdated) {
            if(this._authenticationService.currentUserValue){
              var inputModel = {
                UserDetailsId: this._authenticationService.currentUserValue.UserDetailsId,
                ReceiverUserDetailsId:this._authenticationService.currentUserValue.UserDetailsId
              };
              this.GetUserConnectionRequests(inputModel);
              this.familyService.setGridRefresh(true);
            }
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
        },
        (error) => {
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }
  
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}
