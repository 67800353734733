<link href="https://cdn.quilljs.com/1.3.6/quill.snow.css" rel="stylesheet">
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <h5 cdkDragHandle class="card-header" style="justify-content: unset;">{{dialogTitle}}&nbsp;<span
            ngbTooltip={{infoTooltip}}>
            <span data-feather="info"></span>
        </span><button class="close-button close" type="button"
            ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" (click)="onCloseButtonClick()"
            placement="right">&times;</button>
    </h5>
    <div class="card-body card-body-dialog">
        <form id="frmLegal" [formGroup]="frmLegal" (ngSubmit)="onSubmit(false)">
            <!-- <form >    -->
            <section id="basic-tabs-components">
                <div class="row">
                    <div class="col">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-pill-primary">
                                <li [ngbNavItem]="1" (click)="onTabActive(1)">
                                    <a class="font" ngbNavLink>{{'LEGALS.TAB_HEADING_LEGAL_DETAILS' | translate}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="scroll-area">
                                            <div class="row">
                                                <div class="form-group mb-0 col-sm-12">
                                                    <app-text-box [type]="'text'" #entryname
                                                        [label]="'LEGALS.CONTROL_LABEL_LEGAL'|translate"
                                                        [showRequiredSign]="true" [maxlength]="100"
                                                        [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
                                                        aria-describedby="LegalDocumentName" for="LegalDocumentName"
                                                        formControlName="LegalDocumentName"
                                                        [classes]="{ 'is-invalid': submitted && f.LegalDocumentName.errors }">
                                                    </app-text-box>
                                                    <span *ngIf="submitted && f.LegalDocumentName.errors">
                                                        <small *ngIf="f.LegalDocumentName.errors.required"
                                                            class="text-danger">{{'LEGALS.ERROR_MESSAGE_LEGAL_DOC_NAME'|translate}}</small>
                                                        <small *ngIf="f.LegalDocumentName.errors.maxlength"
                                                            class="text-danger">{{'MEONWEB.ERROR_MESSAGE_MAX_LENGTH_100'|translate}}</small>
                                                    </span>
                                                </div>
                                                <div class="form-group col-sm-12" style="height:245px">
                                                    <label style="color: #323091;" class="form-label"
                                                        placement="right">{{'LEGALS.CONTROL_LABEL_NOTES'|translate}}</label>
                                                    <quill-editor formControlName="Notes"
                                                        placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}">
                                                        <div quill-editor-toolbar>
                                                            <span class="ql-formats">
                                                                <select class="ql-font"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_TEXT_STYLE'| translate">
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-header"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_TEXT_SIZE'| translate">
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-color"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_TEXT_COLOUR'| translate">
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_TEXT_ALIGNMENT'| translate">
                                                                    <option selected></option>
                                                                    <option title="Center" value="center"></option>
                                                                    <option title="Right" value="right"></option>
                                                                    <option title="Justify" value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button class="ql-bold"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_BOLD'| translate"></button>
                                                                <button class="ql-italic"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_ITALIC'| translate"></button>
                                                                <button class="ql-underline"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_UNDERLINE'| translate"></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button class="ql-link" value="ordered"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_LINK'| translate"
                                                                    type="button"></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button class="ql-list" value="bullet"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_BULLET_LIST'| translate"
                                                                    type="button"></button>
                                                                <button class="ql-list" value="ordered"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_NUMBERED_LIST'| translate"
                                                                    type="button"></button>
                                                            </span>

                                                            <span class="ql-formats">
                                                                <button class="ql-clean" value="ordered"
                                                                    [title]="'LEGALS.TOOLTIP_TEXT_CLEAR_FORMAT'| translate"
                                                                    type="button"></button>
                                                            </span>
                                                        </div>
                                                    </quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>

                                <li [ngbNavItem]="2" [disabled]="!_data && !IsForEdit">
                                    <a class="font" (click)="onTabActive(2)" ngbNavLink>{{'LEGALS.TAB_HEADING_UPLOAD' |
                                        translate}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="scroll-area">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="row ml-0">
                                                        <h4 class="font pt-4" style="width: fit-content;">
                                                            {{'LEGALS.HEADING_TEXT_SELECT_IMAGES' |
                                                            translate}}: </h4>
                                                        <!-- <fieldset class="form-group mb-0 ml-2 "
                                                            style="margin-top: 18px;">
                                                            <div class="custom-file">
                                                                <input type="file" id="MultiFileUploaderLegal"
                                                                    (change)="onFileSelected($event)"
                                                                    class="custom-file-input" type="file" ng2FileSelect
                                                                    [uploader]="uploader" multiple
                                                                    id="file-upload-multiple" />
                                                                <button
                                                                    class="custom-file-label font btn btn-primary custom-file-upload-btn"
                                                                    for="customFile">Upload</button>
                                                            </div>
                                                        </fieldset> -->
                                                        <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                                                            <div ngbDropdown class="input-group-append">
                                                              <button
                                                                type="button"
                                                                class="btn btn-primary"
                                                                ngbDropdownToggle
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                rippleEffect
                                                              >
                                                                Browse
                                                              </button>
                                                              <div ngbDropdownMenu class="dropdown-menu-rightnew" >
                                                                <a ngbDropdownItem (click)="openFilemanager()">From Noosom Media</a>
                                                                <a ngbDropdownItem (click)="triggerFileUpload()">From External Device</a>
                                                              </div>
                                                            <!-- </div> -->
                                                          </div>
                                                              <!-- Hidden File Input -->
                                                              <div class="custom-file" style="display: none;">
                                                                <input type="file" id="MultiFileUploaderLegal" class="custom-file-input" (change)="onFileSelected($event)"
                                                                  ng2FileSelect [uploader]="uploader" multiple #fileInput />
                                                                <label class="custom-file-label font" for="file-upload-multiple">{{'UPLOAD.PLACEHOLDER_CHOOSE_FILES' | translate}}</label>
                                                              </div>
                                                            </fieldset>
                                                    </div>
                                                    <h5 style="color: #323091;">{{'LEGALS.TEXT_FILE_TYPES' |
                                                        translate}} "pdf", "images"</h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col overflow-auto">
                                                    <!-- <div class="d-flex justify-content-between mb-1">
                                                        <h4 class="font">{{'LEGALS.HEADING_TEXT_UPLOAD_QUEUE' |
                                                            translate}}</h4>
                                                        <p>{{'LEGALS.TEXT_QUEUE_LENGTH' | translate}} {{
                                                            uploader?.queue?.length }}</p>
                                                    </div>

                                                    <table class="table">
                                                        <thead style="color: #323091;">
                                                            <tr>
                                                                <th width="50%" text-align="left">
                                                                    {{'LEGALS.COLUMN_HEADING_NAME' | translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_SIZE' | translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_PROGRESS' | translate}}
                                                                </th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_STATUS' | translate}}
                                                                </th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_ACTIONS' | translate}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of uploader.queue">
                                                                <td>
                                                                    {{ item?.file?.name }}
                                                                </td>
                                                                <td *ngIf="uploader.options.isHTML5" nowrap>
                                                                    {{ item?.file?.size / 1024 / 1024 | number: '.2' }}
                                                                    {{'LEGALS.TEXT_MB' |
                                                                    translate}}
                                                                </td>
                                                                <td *ngIf="uploader.options.isHTML5">
                                                                    <ngb-progressbar type="primary" striped="true"
                                                                        animated="true"
                                                                        [value]="item.progress"></ngb-progressbar>
                                                                </td>
                                                                <td class="text-center">
                                                                    <span *ngIf="item.isSuccess"><i
                                                                            data-feather="check-circle"></i></span>
                                                                    <span *ngIf="item.isCancel"><i
                                                                            data-feather="slash"></i></span>
                                                                    <span *ngIf="item.isError"><i
                                                                            data-feather="x-circle"></i></span>
                                                                </td>
                                                                <td nowrap>
                                                                    <button type="button"
                                                                        class="btn btn-raised btn-success btn-sm mr-50"
                                                                        (click)="item.upload()"
                                                                        [disabled]="item.isReady || item.isUploading || item.isSuccess"
                                                                        rippleEffect>
                                                                        <span data-feather="upload"></span>
                                                                    </button>
                                                                    <button type="button"
                                                                        class="btn btn-raised btn-danger btn-sm"
                                                                        (click)="item.remove();checkQueueLength(uploader?.queue?.length)" rippleEffect>
                                                                        <i data-feather="trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <div id="">
                                                        <p class="mt-1 font">{{'LEGALS.HEADING_TEXT_QUEUE_PROGRESS' |
                                                            translate}}</p>
                                                        <ngb-progressbar class="mb-1" type="primary" [striped]="true"
                                                            [animated]="true"
                                                            [value]="uploader.progress"></ngb-progressbar>
                                                        <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                                                            (click)="uploader.uploadAll()"
                                                            [disabled]="!uploader.getNotUploadedItems().length"
                                                            rippleEffect ngbTooltip="Upload All">
                                                            <span data-feather="upload" class="mr-25"></span>
                                                            {{'LEGALS.BUTTON_TEXT_UPLOAD_ALL' |
                                                            translate}}
                                                        </button>

                                                        <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                                                            (click)="uploader.clearQueue();checkQueueLength(uploader?.queue?.length)"
                                                            [disabled]="!uploader.queue.length" rippleEffect>
                                                            <span data-feather="trash" class="mr-25"></span>
                                                            {{'LEGALS.BUTTON_TEXT_REMOVE_ALL' |
                                                            translate}}
                                                        </button>
                                                    </div> -->
                                                    <div *ngIf="GalleryItems1.length==0 && DocumentList1.length==0"><img
                                                            style="padding-left: 34%;"
                                                            src="assets/images/pages/noimageuploaded.png"
                                                            height="300px"></div>
                                                    <div id="animated-thumbnails-gallery">
                                                        <lightgallery #AddressGallery [settings]="settings"
                                                            [onInit]="onInitGallery1">
                                                            <a *ngFor="let item of GalleryItems1"
                                                                [attr.data-video]="item.type === 'Videos' ? item.data : null"
                                                                [attr.href]="item.type === 'Images' ? item.src : null"
                                                                [data-poster]="item.type === 'Videos' ? item.thumb :null"
                                                                className="gallery-item"
                                                                data-sub-html="<p>{{item.id}}</p>">
                                                                <img *ngIf="item.type=='Images'"
                                                                    class="img-responsive justify-gallery"
                                                                    loading="lazy" src="{{item.src}}"
                                                                    alt="{{item.id}}" />
                                                                <img *ngIf="item.type=='Videos'"
                                                                    class="img-responsive justify-gallery"
                                                                    loading="lazy" [src]="item.thumb"
                                                                    alt="{{item.id}}" />
                                                            </a>
                                                        </lightgallery>
                                                        <div>
                                                            <a *ngFor="let item of DocumentList1"
                                                                href={{item.DocumentPath}} target="_blank"
                                                                className="gallery-item">
                                                                <img class="img-responsive justify-gallery"
                                                                    loading="lazy"
                                                                    src="assets/images/icons/document-icon.png" /></a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>

                                <li [ngbNavItem]="3" [disabled]="!_data && !IsForEdit">
                                    <a class="font" (click)="onTabActive(3)" ngbNavLink>{{'LEGALS.TAB_HEADING_DOCUMENT'
                                        | translate}}</a>
                                    <ng-template ngbNavContent>
                                        <!-- <div class="row py-3 pl-3">
                                            <div class="col-sm">
                                                <i>{{'MYPASTIME.SECTION_TEXT2'|translate}}</i>
                                            </div>
                                        </div> -->
                                        <div class="scroll-area">
                                            <div class="col overflow-auto">
                                                <div class="d-flex justify-content-between mb-1" style="max-height: 350px;overflow-x: auto;">
                                                    <table class="table">
                                                        <thead style="color: #323091;">
                                                            <tr>
                                                                <th text-align="left" width="40%">
                                                                    {{'LEGALS.COLUMN_HEADING_NAME' | translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_SIZE' | translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_DATE_CREATED' |
                                                                    translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_TYPE' | translate}}</th>
                                                                <th>{{'LEGALS.COLUMN_HEADING_ACTIONS' | translate}}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let document of DocumentList">
                                                                <td>
                                                                    {{document.DocumentOldName}}
                                                                </td>
                                                                <td>
                                                                    {{ document.Size }}
                                                                </td>
                                                                <td>
                                                                    {{document.FileCreatedDate | date: 'dd/MM/yyyy' }}
                                                                </td>
                                                                <td>
                                                                    {{ document.FileType }}
                                                                </td>
                                                                <td nowrap>
                                                                    <button type="button" ngbTooltip="{{'LEGALS.TOOLTIP_TEXT_BUTTON_DOWNLOAD'|translate}}"
                                                                        class="btn btn-raised btn-success btn-sm mr-50"
                                                                        (click)="onDownloadDocument(document)"
                                                                        rippleEffect>
                                                                        <i data-feather="download"></i>
                                                                    </button>
                                                                    <button type="button" ngbTooltip="{{'LEGALS.TOOLTIP_TEXT_BUTTON_DELETE'|translate}}"
                                                                        class="btn btn-raised btn-danger btn-sm mr-50"
                                                                        (click)="onDeleteDocument(document.DocumentId)"
                                                                        rippleEffect>
                                                                        <i data-feather="trash-2"></i>
                                                                    </button>
                                                                    <a href={{document.DocumentPath}}
                                                                        target="_blank"><button type="button" ngbTooltip="{{'LEGALS.TOOLTIP_TEXT_BUTTON_VIEW'|translate}}"
                                                                            class="btn btn-raised btn-warning btn-sm"
                                                                            rippleEffect>
                                                                            <i data-feather="eye"></i>
                                                                        </button></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                                <ngb-pagination #pagination cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
                                                class="floatingloadmore-button" style="top: 85%;" *ngIf="DocumentList.length>0"
                                                [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Documents')"
                                                [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
                                                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                                    [class]="'font-weight-bolder'"></span>Prev
                                                </ng-template>
                                                <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                                    [class]="'font-weight-bolder'"></span>
                                                </ng-template>
                                              </ngb-pagination>
                                                <div id="">
                                                    <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                                                        (click)="onDownloadAllDocument() || async"
                                                        ngbTooltip="{{'LEGALS.BUTTON_TEXT_DOWNLOAD_ALL' | translate}}"
                                                        rippleEffect>
                                                        <span data-feather="download" class="mr-25"></span>
                                                        {{'LEGALS.BUTTON_TEXT_DOWNLOAD_ALL' |
                                                        translate}}
                                                    </button>
                                                    <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                                                        (click)="onDeleteAllDocument() || async"
                                                        ngbTooltip="{{'LEGALS.BUTTON_TEXT_REMOVE_ALL' | translate}}"
                                                        rippleEffect>
                                                        <span data-feather="trash-2" class="mr-25"></span>
                                                        {{'LEGALS.BUTTON_TEXT_REMOVE_ALL' |
                                                        translate}}
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- <ng-template #uploaderContent let-modal>   // (click)="onviewDocument(document)" this is the event we are using previously to show document
                                                <div class="modal-header">
                                                  <h3 class="modal-title m-0" style="color:#52057f">View Document</h3>
                                                  <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="iframe-container">
                                                    <iframe [src]="docPath" width="100%" height="500px" ></iframe>
                                                </div>
                                                </div>
                                                <div class="modal-footer">
                                                  <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')"
                                                    rippleEffect>
                                                    Close
                                                  </button>
                                                </div>
                                  
                                              </ng-template> -->
                                        </div>
                                    </ng-template>

                                </li>

                                <li [ngbNavItem]="4" [disabled]="!_data && !IsForEdit">
                                    <a class="font" (click)="onTabActive(4)" ngbNavLink>{{'LEGALS.TAB_HEADING_IMAGES' |
                                        translate}}</a>
                                    <ng-template ngbNavContent>
                                        <div class="scroll-area">
                                            <div class="row justify-content-center">
                                                <div class="col">
                                                    <div *ngIf="GalleryItems.length==0"><img
                                                            style="padding-left: 34%;padding-right: 50%;"
                                                            src="assets/images/pages/noimageuploaded.png"
                                                            height="350px"></div>
                                                    <div id="animated-thumbnails-gallery">
                                                        <lightgallery #AddressGallery [settings]="settings"
                                                            [onInit]="onInitGallery">
                                                            <a *ngFor="let item of GalleryItems"
                                                                [attr.data-video]="item.type === 'Videos' ? item.data : null"
                                                                [attr.href]="item.type === 'Images' ? item.src : null"
                                                                [data-poster]="item.type === 'Videos' ? item.thumb :null"
                                                                className="gallery-item" 
                                                                data-sub-html="<p>{{item.id}}</p>">
                                                                <img *ngIf="item.type=='Images'"  [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class"
                                                                    class="img-responsive justify-gallery" container="body"
                                                                    loading="lazy" src="{{item.src}}"
                                                                    alt="{{item.id}}" />
                                                                <img *ngIf="item.type=='Videos'"  [ngbTooltip]="tooltipTemplate"  tooltipClass="my-custom-class"
                                                                    class="img-responsive justify-gallery" container="body"
                                                                    loading="lazy" [src]="item.thumb"
                                                                    alt="{{item.id}}" />
                                                                    <ng-template #tooltipTemplate >
                                                                        <div class="newpop-up">
                                                                          <p style="font-weight: normal; margin: 0px; padding: 0px">Document Name: <span
                                                                              style="font-weight: bold;">{{ item?.name }}</span></p>
                                                                          <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location: <span style="font-weight: bold;">{{ folder?.Location }}</span></p> -->
                                                                          <p style="font-weight: normal; margin: 0px; padding: 0px">Document Type: <span
                                                                              style="font-weight: bold;">{{ item?.type}}</span></p>
                                                                          <p style="font-weight: normal; margin: 0px; padding: 0px">Document Size: <span
                                                                              style="font-weight: bold;">{{ item?.size}}</span></p>
                                                                          <p style="font-weight: normal; margin: 0px; padding: 0px">Created Date: <span
                                                                                style="font-weight: bold;">{{ item?.createdDate|date:dateformat1}}</span></p>    
                                                                          <p style="font-weight: normal; margin: 0px; padding: 0px">Created Time: <span
                                                                             style="font-weight: bold;">{{ item?.createdTime|date:timeformat}}</span></p>   
                                                                          <!-- <p style="font-weight: normal; margin: 0px; padding: 0px">Location Count: <span style="font-weight: bold;">{{ indexi }}/{{ totalLocations }}</span></p> -->
                                                                        </div>
                                                                      </ng-template> 
                                                            </a>
                                                        </lightgallery>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ngb-pagination #pagination cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
                                        class="floatingloadmore-button " *ngIf="GalleryItems.length>0" [collectionSize]='pagesNumber'
                                        [pageSize]="20" (pageChange)="loadPage($event,'Images')" [(page)]="pageAdvancedEllipses"
                                        [maxSize]="5" [rotate]="true" [boundaryLinks]="true">
                                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                        </ng-template>
                                        <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                        </ng-template>
                                      </ngb-pagination>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-1"></div>
                        </div>
                    </div>
                </div>
            </section>
        </form>

    </div>
</div>
<div class="card-footer d-flex">
    <div class="align">
        <button type="submit" *ngIf="!IsForEdit&&tab<4" class="btn btn-primary mr-1" [disabled]="loading"
            placement="top" ngbTooltip="{{'LEGALS.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}"
            (click)="(onSubmit(false))"><span *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"></span>{{'LEGALS.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}
        </button>

        <button type="submit" *ngIf="!IsForEdit" (click)="onSubmit(true)" class="btn btn-outline-primary"
            placement="top"
            ngbTooltip="{{'LEGALS.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}">{{'LEGALS.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}</button>

        <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
            ngbTooltip="{{'LEGALS.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit(true)"><span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"></span>{{'LEGALS.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

        <button type="button" *ngIf="(tab<2||IsForEdit)&&!IsForAbandon" class="btn btn-outline-secondary ml-2"
            placement="top" (click)="onCloseButtonClick()"
            ngbTooltip="{{'LEGALS.BUTTON_TEXT_CANCEL'|translate}}">{{'LEGALS.BUTTON_TEXT_CANCEL'|translate}}
        </button>

        <button type="button" *ngIf="!IsForEdit&&tab>=2 || IsForAbandon" (click)="OnLegalAbandon()"
            class="btn btn-outline-danger ml-1" placement="top"
            ngbTooltip="{{'LEGALS.BUTTON_TEXT_ABANDON'|translate}}">{{'LEGALS.BUTTON_TEXT_ABANDON'|translate}}
        </button>
    </div>
    <div class="align" *ngIf="uploaderWarning">Attachment Uploaded:{{uploadCount}}/{{uploader?.queue?.length}}</div>
</div>