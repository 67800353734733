import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;

  constructor() {
    this.connect();
  }

  connect() {
    const currentURL = window.location.href;
    const sitename = currentURL.split('/').slice(0, 3).join('/');
    // console.log(sitename)
    let socketUrl: string='wss://localhost:44345/ws';
    if (sitename.includes('https://app.noosom.com')) {
      socketUrl = 'wss://api.noosom.com/ws';
  } else if (sitename.includes('https://test.noosom.com')) {
      socketUrl = 'wss://apitest.noosom.com/ws';
  } else if (sitename.includes('http://localhost:4200')) {
      socketUrl = 'wss://localhost:44345/ws'; // Use WSS for localhost in HTTPS mode
  }
    this.socket$ = webSocket(socketUrl);
    this.socket$.subscribe(
      (message) => console.log('Received message:', message),
      (err) => console.error('WebSocket error:', err),
      () => console.log('WebSocket connection closed')
    );
  }

  sendMessage(msg: any) {
    this.socket$.next(msg);
  }

  close() {
    this.socket$.complete();
  }
}
