<!-- <p>my-medication works!</p> -->
<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header" style="display: flex; justify-content: space-between; align-items: center;">
    <div>
      {{dialogTitle}}&nbsp;
      <span ngbTooltip={{infoTooltip}}>
        <span data-feather="info"></span>
      </span>
    </div>
    <div class="button-group">
      <button *ngIf="connectionStatus && showIcon" ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_CONNECTION_STATUS'|translate}}" class="btn btn-sm border-0 {{connectionStatus}} mr-3"  type="button">
        <i data-feather="flag" class="font-medium-3 cursor-pointer"></i>
      </button>
      <button class="close-button close" ngbTooltip="{{'COMMON.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" type="button" (click)="onCloseButtonClick()" placement="right">&times;</button>
    </div>
  </h5> 
    <div class="card-body card-body-dialog">
      <!-- <div class="row py-3 pl-3">
        <div class="col-sm">
          <i>{{'MY_FAMILIES.SECTION_TEXT_FRIEND'|translate}}</i>
        </div>
      </div> -->
        <form id="frmFriend" [formGroup]="frmFriend" (ngSubmit)="onSubmit()">
          <!-- <form >  -->
            <div class="row scroll-area">
            <br>
            <br>
            <div class="col-lg-4 col-sm-12 form-group mb-0">
              <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.FirstName.errors }" #FirstName [showInfoIcon]="true"
              [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_FIRST_NAME'|translate" [position]="'right'"
              [label]="'MY_FAMILIES.CONTROL_LABEL_FIRST_NAME'| translate" [showRequiredSign]="true" [maxlength]="50"
              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="FirstName"
              for="FirstName">
            </app-text-box>
            <span *ngIf="submitted && f.FirstName.errors">
              <small *ngIf="f.FirstName.errors.required"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_FIRST_NAME'|translate}}</small>
              <small *ngIf="f.FirstName.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
            </span>
            </div> 
            <div class="col-lg-4 col-sm-12 form-group mb-0">
              <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.MiddleName.errors }" [showInfoIcon]="true"
              [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_MIDDLE_NAME'|translate" [position]="'right'"
              [label]="'MY_FAMILIES.CONTROL_LABEL_MIDDLE_NAME'| translate" formControlName="MiddleName"
              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [maxlength]="50"
              for="MiddleName">
            </app-text-box>
            <span *ngIf="submitted && f.MiddleName.errors">
              <small *ngIf="f.MiddleName.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
            </span>
            </div>
            <div class="col-lg-4 col-sm-12 form-group mb-0">
              <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.LastName.errors }" [showInfoIcon]="true"
              [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_LAST_NAME'|translate" [position]="'right'"
              [label]="'MY_FAMILIES.CONTROL_LABEL_LAST_NAME'| translate" [showRequiredSign]="true" [maxlength]="50"
              [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="LastName"
              for="LastName">
            </app-text-box>
            <span *ngIf="submitted && f.LastName.errors">
              <small *ngIf="f.LastName.errors.required"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_LAST_NAME'|translate}}</small>
              <small *ngIf="f.LastName.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
            </span>
            </div>
            
            <!-- <div class="row padding-left"> -->
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="form-label" for="LastRefillDate">{{'MY_FAMILIES.CONTROL_LABEL_DOB' |translate}}
                    <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_DATE_OF_BIRTH'|translate}}" placement="right">
                      <span data-feather="help-circle"
                        class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                    </label>
                  <div class="input-group border-color">
                    <input id="LastRefillDate" name="LastRefillDate" formControlName="DateofBirth" [class]="{ 'is-invalid': submitted && f.DateofBirth.errors }"
                      [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}" [maxDate]="maxDate" [placeholder]="dateformat"
                       required bsDatepicker #dpLast="bsDatepicker" class="form-control">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary feather icon-calendar" (click)="dpLast.toggle()"
                        type="button" rippleEffect placement="top" 
                        ngbTooltip="{{'MY_MEDICATION_FORM.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                    </div>
                  </div> 
                  <span *ngIf="submitted && f.DateofBirth.errors">
                    <small class="text-danger" *ngIf="f.DateofBirth.errors.required">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_DATE_OF_BIRTH'
                      | translate}}</small>
                  </span>
                </div>
              </div>
              <div class="col-sm-8">
                <!-- <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.EmailAddress.errors }" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$"
                [label]="'MY_FAMILIES.CONTROL_LABEL_EMAIL_ADDRESS'| translate" [maxlength]="320"  ngModel [email]="true"
                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="EmailAddress"
                for="EmailAddress">
              </app-text-box>
              <span *ngIf="submitted && f.EmailAddress.errors">
                <small *ngIf="f.EmailAddress.errors.maxlength"
                  class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_320'|translate}}</small>
                  <small *ngIf="f.EmailAddress.errors.email || f.EmailAddress.errors.pattern"
                  class="text-danger">{{'ABOUTME.ERROR_MESSAGE_INVALID_EMAIL'|translate}}</small>
              </span> -->

               
              <label class="form-label" for="LastRefillDate">{{'MY_FAMILIES.CONTROL_LABEL_EMAIL_ADDRESS'| translate }}
                <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_FRIENDS_EMAIL'|translate}}" placement="right">
                  <span data-feather="help-circle"
                    class="info-icon align-middle mr-sm-25 mr-0"></span></span>
              </label>
            <div class="input-group">
              <input
              ngModel
                type="text" [readOnly]="(userFriend?.ConnectionRequestStatusId == 2) || (userFriend?.ConnectionRequestStatusId == 3)"
                class="{ 'is-invalid': submitted && f.EmailAddress.errors } form-control"
                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
                aria-describedby="button-addon1" (ngModelChange)="updateCharacterCount($event)"
                [maxlength]="320" (focusout)="focusoutEmail(frmFriend)"
                formControlName="EmailAddress"
              />
              <div class="input-group-append" id="button-addon1">
                <button class="btn btn-outline-primary" [disabled]="!IsForEdit" type="button" (click)="sendEmail()" rippleEffect><i-tabler style="height:14px; width:14px" name='send'></i-tabler></button>
              </div>
            </div>
            <p  class="text-right mb-0">{{'ABOUTME.CHARACTER_COUNT'|translate}}: {{ characterCount }} / {{ maxlength }}</p>
            <span *ngIf="submitted && f.EmailAddress.errors">
              <small *ngIf="f.EmailAddress.errors.maxlength"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_320'|translate}}</small>
                <small *ngIf="f.EmailAddress.errors.email || f.EmailAddress.errors.pattern"
                class="text-danger">{{'ABOUTME.ERROR_MESSAGE_INVALID_EMAIL'|translate}}</small>
            </span>
            <span *ngIf="f.EmailAddress.errors">
              <small *ngIf="f.EmailAddress.errors.email || f.EmailAddress.errors.exist"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_EXIST_EMAIL'|translate}}</small>
            </span>
              </div>
      
              <!-- <div class="col-sm-12">
                <app-text-box [type]="'text'"
                [label]="'MY_FAMILIES.CONTROL_LABEL_PERSONAL_URL'| translate" 
                [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" formControlName="PersonalWebUrl"
                for="PersonalWebUrl">
              </app-text-box>
              </div> -->

              <div class="col-lg-6 col-sm-12 pb-1">
                <label class="form-label" for="LastRefillDate">{{'MY_FAMILIES.CONTROL_LABEL_PERSONAL_URL'| translate }}
                  <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_PERSONAL_URL1'|translate}}" placement="right">
                    <span data-feather="help-circle"
                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  </label><span> <a> <img align="right" height="18px" width="18px" src="assets/images/icons/copy.svg"
                    (click)="copyText(this.frmFriend.get('PersonalWebUrl').value)"> </a> </span>
                <div class="input-group">
                  <input
                    type="text"
                    [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
                    class="form-control"
                    aria-describedby="button-addon2"
                    formControlName="PersonalWebUrl"
                  />
                  <div class="input-group-append" id="button-addon2">
                    <button class="btn btn-outline-primary" [disabled]="!IsForEdit" type="button" (click)="openURL(this.frmFriend.get('PersonalWebUrl').value)" rippleEffect><i data-feather="external-link"></i></button>
                  </div>
                </div>
              </div>
          
               
              <div class="col-lg-6 col-sm-12 pb-1">
                <label class="form-label" for="LastRefillDate">{{'MY_FAMILIES.CONTROL_LABEL_NOOSOM_URL'| translate }}
                  <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_NOOSOM_URL1'|translate}}" placement="right">
                    <span data-feather="help-circle"
                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  </label><span> <a> <img align="right" height="18px" width="18px" src="assets/images/icons/copy.svg"
                    (click)="copyText(this.frmFriend.get('NoosomWebUrl').value)"> </a> </span>
                <div class="input-group">
                  <input
                    type="text"
                    [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate"
                    class="{ 'is-invalid': submitted && f.NoosomWebUrl.errors } form-control"
                    aria-describedby="button-addon2"
                    [maxlength]="100" (ngModelChange)="updateCharacterCount2($event)"
                    formControlName="NoosomWebUrl"
                  />
                  <div class="input-group-append" id="button-addon2">
                    <button class="btn btn-outline-primary" [disabled]="!IsForEdit" type="button" (click)="openURL(this.frmFriend.get('NoosomWebUrl').value)" rippleEffect><i data-feather="external-link"></i></button>
                  </div>
                </div>
                <p  class="text-right mb-0">{{'ABOUTME.CHARACTER_COUNT'|translate}}: {{ characterCount2 }} / {{ maxlength2 }}</p>
                <span *ngIf="submitted && f.NoosomWebUrl.errors">
                  <small *ngIf="f.NoosomWebUrl.errors.maxlength"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_100'|translate}} hello</small>
                </span>
              </div>
              
              <div class="col-lg-6 col-sm-12 pb-2">
                <label class="form-label d-block" for="ContactNumber1">{{'MY_FAMILIES.CONTROL_LABEL_CONTACT1'| translate}}
                  <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_MOBILE'|translate}}" placement="right">
                    <span data-feather="help-circle"
                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                </label>
                <ngx-intl-tel-input [separateDialCode]="true" [searchCountryFlag]="true" name="ContactNumber1"
                  [maxlength]="12" [phoneValidation]="true" formControlName="ContactNumber1" [selectedCountryISO]="SelectedCountryISO1"
                   [class]="{ 'is-invalid': submitted && f.ContactNumber1.errors }"
                  id="ContactNumber1" [customPlaceholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate">
                </ngx-intl-tel-input>
                <span *ngIf="submitted && f.ContactNumber1.errors">
                  <small *ngIf="f.ContactNumber1.errors.required"
                    class="text-danger">{{'MY_FAMILIES.ERROR_MESSAGE_REQUIRED_MOBILE_NUMBER'
                    | translate}}</small>
                  <small *ngIf="f.ContactNumber1.errors.maxlength"
                    class="text-danger">{{'MY_FAMILIES.ERROR_MESSAGE_MAX_LENGTH_12'
                    | translate}}</small>
                  <small *ngIf="f.ContactNumber1.errors && !f.ContactNumber1.errors.required"
                    class="text-danger">{{'MY_FAMILIES.ERROR_MESSAGE_INVALID_DATA'
                    | translate}}</small>
                </span>
              </div>
              <div class="col-lg-6 col-sm-12 pb-2">
                <label class="form-label d-block" for="ContactNumber2">{{'MY_FAMILIES.CONTROL_LABEL_CONTACT2' | translate}}
                  <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_ALTERNATE_CONTACT'|translate}}" placement="right">
                    <span data-feather="help-circle"
                      class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                </label>
                <ngx-intl-tel-input [separateDialCode]="true" [searchCountryFlag]="true" name="ContactNumber2"
                  [class]="{ 'is-invalid': submitted && f.ContactNumber2.errors }" [phoneValidation]="true"
                  formControlName="ContactNumber2"  id="ContactNumber2" [selectedCountryISO]="SelectedCountryISO2"
                  [customPlaceholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate">
                </ngx-intl-tel-input>
                <span *ngIf="submitted && f.ContactNumber2.errors">
                  <small *ngIf="f.ContactNumber2.errors" class="text-danger">{{'MY_FAMILIES.ERROR_MESSAGE_INVALID_DATA'|
                    translate}}</small>
                  <small *ngIf="f.ContactNumber2.errors.maxlength"
                    class="text-danger">{{'PROFESSIONAL.ERROR_MESSAGE_MAX_LENGTH_12'
                    | translate}}</small>
                </span>
              </div>
              <div class="col-lg-4 col-sm-12 form-group mb-0">
                <app-text-box [type]="'text'" [label]="'MY_FAMILIES.CONTROL_LABEL_ADDRESS1'| translate"
                 [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
                 [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_STREET_NAME'|translate" [position]="'right'"
                  aria-describedby="Address1" [maxlength]="50" for="Address1" formControlName="Address1"
                  [classes]="{ 'is-invalid': submitted && f.Address1.errors }">
                </app-text-box>
                <span *ngIf="submitted && f.Address1.errors">
                  <small *ngIf="f.Address1.errors.required"
                    class="text-danger">{{'MY_FAMILIES.ERROR_MESSAGE_REQUIRED_ADDRESS'|translate}}</small>
                  <small *ngIf="f.Address1.errors.maxlength"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                </span>
              </div>
              <div class="col-lg-4 col-sm-12 form-group mb-0">
                <app-text-box [type]="'text'" [label]="'MY_FAMILIES.CONTROL_LABEL_ADDRESS2'| translate"
                  [classes]="{ 'is-invalid': submitted && f.Address2.errors }" [showInfoIcon]="true"
                  [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_1'|translate" [position]="'right'"
                  [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address2" [maxlength]="50"
                  for="Address2" formControlName="Address2">
                </app-text-box>
                <span *ngIf="submitted && f.Address2.errors">
                  <small *ngIf="f.Address2.errors.maxlength"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                </span>
              </div>
              <div class="col-lg-4 col-sm-12 form-group mb-0">
                <app-text-box [type]="'text'" [label]="'MY_FAMILIES.CONTROL_LABEL_ADDRESS3'| translate"
                  [classes]="{ 'is-invalid': submitted && f.Address3.errors }" [showInfoIcon]="true"
                  [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_ADDITIONAL_LOCATION_FIELD_2'|translate" [position]="'right'"
                  [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="Address3" [maxlength]="50"
                  for="Address3" formControlName="Address3">
                </app-text-box>
                <span *ngIf="submitted && f.Address3.errors">
                  <small *ngIf="f.Address3.errors.maxlength"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                </span>
              </div>
              <div class="col-lg-4 col-sm-12 form-group mb-0">
                <app-text-box [type]="'text'" [classes]="{ 'is-invalid': submitted && f.AddressTown.errors }"
                  [label]="'MY_FAMILIES.CONTROL_LABEL_ADDRESS_TOWN'| translate" [showInfoIcon]="true"
                  [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_TOWN_VILLAGE_CITY'|translate" [position]="'right'"
                  [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="" [maxlength]="50"
                  for="AddressTown" formControlName="AddressTown">
                </app-text-box>
                <span *ngIf="submitted && f.AddressTown.errors">
                  <small *ngIf="f.AddressTown.errors.required"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESSTOWN'|translate}}</small>
                    <small *ngIf="f.AddressTown.errors.maxlength"
                    class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>    
                </span>
              </div>
             
                <div class="col-lg-4 col-sm-12 form-group mb-0">
                  <app-text-box [type]="'text'" [label]="'MY_FAMILIES.CONTROL_LABEL_ADDRESS_POSTALCODE'| translate"
                     [maxlength]="50" [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" [showInfoIcon]="true"
                     [tooltipInfo]="'MY_FAMILIES.TOOLTIP_TEXT_POSTAL_ZIPCODE'|translate" [position]="'right'"
                    aria-describedby="" [classes]="{ 'is-invalid': submitted && f.AddressPostCode.errors }"
                    for="AddressPostCode" formControlName="AddressPostCode">
                  </app-text-box>
                  <span *ngIf="submitted && f.AddressPostCode.errors">
                    <small *ngIf="f.AddressPostCode.errors.required"
                      class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESSPOSTCODE'|translate}}</small>
                      <small *ngIf="f.AddressPostCode.errors.maxlength"
              class="text-danger">{{'ABOUTME.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>  
                  </span>
                </div>
                <div class="col-sm py-4" style="top:9px;">
                  <button type="button" class="btn btn-primary" style="width:100%" [disabled]="loading" placement="top"
                   (click)="onSearchOnMapClick()"
                    ngbTooltip="{{'MY_FAMILIES.BUTTON_TEXT_SEARCH_ON_MAP' | translate}}"><span></span><i data-feather="search"
                      class="mr-0 mr-sm-1"></i>
                    {{'MY_FAMILIES.BUTTON_TEXT_SEARCH_ON_MAP'|translate}}</button>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <label class="form-label" for="StateId">{{'MY_FAMILIES.CONTROL_LABEL_ADDRESS_STATE' | translate}}
                    <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_STATE_PROVINCE'|translate}}" placement="right">
                      <span data-feather="help-circle"
                        class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  </label>
                  <ng-select [items]="friendLoadDataOutputModel?.StateList" bindLabel="StateName" selectOnTab="true"
                    bindValue="StateId" id="StateId" name="StateId" (ngModelChange)="onStateChange($event)"
                    placeholder="{{'MY_FAMILIES.PLACEHOLDER_SELECT_STATE'|translate}}" formControlName="StateId"
                    [class]="{ 'is-invalid': submitted && f.StateId.errors }"
                    ></ng-select>
                  <span *ngIf="submitted && f.StateId.errors">
                    <small *ngIf="f.StateId.errors.required"
                      class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_STATE'|translate}}</small>
                  </span>
                </div>
                <div class="col-lg-4 col-sm-12 pb-2">
                  <label class="form-label" for="CountryId">{{'MY_FAMILIES.CONTROL_LABEL_COUNTRY' |translate}}
                    <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_COUNTRY'|translate}}" placement="right">
                      <span data-feather="help-circle"
                        class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  </label>
                  <ng-select [items]="friendLoadDataOutputModel?.CountryList" bindLabel="CountryName" selectOnTab="true"
                    bindValue="CountryId" id="CountryId" name="CountryId" 
                    placeholder="{{'MY_FAMILIES.PLACEHOLDER_SELECT_COUNTRY'|translate}}" formControlName="CountryId"
                    [class]="{ 'is-invalid': submitted && f.CountryId.errors }"
                    ></ng-select>
                     <!--(ngModelChange)="onCountryChange($event)"  -->
                  <span *ngIf="submitted && f.CountryId.errors">
                    <small *ngIf="f.CountryId.errors.required"
                      class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_COUNTRY'|translate}}</small>
                  </span>
                </div>
                <div class="col-lg-4 col-sm-12 pb-2">
                  <label class="form-label" for="RegionId">{{'MY_FAMILIES.CONTROL_LABEL_REGION' |translate}}
                    <span ngbTooltip="{{'MY_FAMILIES.TOOLTIP_TEXT_REGION_CONTINENT'|translate}}" placement="right">
                      <span data-feather="help-circle"
                        class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                  </label>
                  <ng-select  [items]="friendLoadDataOutputModel?.RegionList" bindLabel="RegionName" selectOnTab="true" 
                    bindValue="RegionId" id="RegionId" name="RegionId" 
                    placeholder="{{'MY_FAMILIES.PLACEHOLDER_SELECT_REGION'|translate}}" formControlName="RegionId"
                    [class]="{ 'is-invalid': submitted && f.RegionId.errors }"
                    ></ng-select>
                    <!-- (ngModelChange)="onRegionChange($event)" -->
                  <span *ngIf="submitted && f.RegionId.errors">
                    <small *ngIf="f.RegionId.errors.required"
                      class="text-danger">{{'ABOUTME.ERROR_MESSAGE_REQUIRED_ADDRESS_REGION'|translate}}</small>
                  </span>
                </div>
          </div>
          </form> 
    </div>      
    </div>
<div class="card-footer">
    <div class="align">
      <button type="submit" *ngIf="!IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top" (click)="onSubmit()"
        ngbTooltip="{{'MY_FAMILIES.BUTTON_TEXT_ADD_A_FRIEND'|translate}}">{{'MY_FAMILIES.BUTTON_TEXT_ADD_A_FRIEND'|translate}}</button>

        <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'COMMON.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit()"><span *ngIf="loading"
        class="spinner-border spinner-border-sm mr-1"></span>{{'COMMON.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>
  
      <button type="button"  class="btn btn-outline-secondary ml-2" placement="top"
      (click)="onCloseButtonClick()"
        ngbTooltip="{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}">{{'MY_MEDICATION_FORM.BUTTON_TEXT_CANCEL'|translate}}</button>
    </div>
  </div>