import { Status } from "app/enums";

export const locale = {
  lang: 'hn',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'ईमेल पता',
      PLACEHOLDER_EMAIL_ADDRESS: 'यहाँ लिखो...',
      PLACEHOLDER_TYPE_HERE: 'यहाँ लिखो...',
      PLACEHOLDER_MOBILE_NUMBER: '०१२३४५६७८९',
      BUTTON_TEXT_SAVE_CHANGES: 'परिवर्तनों को सुरक्षित करें',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'ईमेल की आवश्यकता है!',
      ERROR_MESSAGE_INVALID_EMAIL: 'कृपया अपना ईमेल पता प्रारूप में दर्ज करें:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'सर्वर से कनेक्ट नहीं हो सका.',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'उपयोगकर्ता नहीं मिल सका।' }
        ],
    },
    GRIDVIEW: {
      BUTTON_TEXT_VIEW_ALL_ON_A_MAP: 'मानचित्र पर सभी देखें',
      CONTROL_LABEL_SEARCH: 'खोजे',
      CONTROL_LABEL_SHOW: 'दिखाएँ',
      CONTROL_LABEL_SHOW_ALL: 'सभी दिखाएँ',
      CHARACTER_COUNT: 'अक्षर गणना',
      OPTION_VALUE_TEXT_5: '५',
      OPTION_VALUE_TEXT_10: '१०',
      OPTION_VALUE_TEXT_15: '१५',
      OPTION_VALUE_TEXT_20: '२०',
      OPTION_VALUE_TEXT_25: '२५',
      PLACEHOLDER_SEARCH: 'खोजे',
    },
    GRIDACTION: {
      TOOLTIP_TEXT_BUTTON_EDIT_RECORD: 'रिकॉर्ड संपादित करें',
      TOOLTIP_TEXT_BUTTON_VIEW_ON_MAP: 'मानचित्र पर देखें',
      TOOLTIP_TEXT_BUTTON_DELETE_RECORD: 'रिकॉर्ड हटाएं',
      TOOLTIP_TEXT_BUTTON_LINK_RECORD: 'इस व्यक्ति से जुड़ें',
      TOOLTIP_TEXT_BUTTON_DOC_DESCRIPTION: 'दस्तावेज़ विवरण',
      TOOLTIP_TEXT_BUTTON_DOWNLOAD_RECORD: 'डाउनलोड',
      TOOLTIP_TEXT_BUTTON_SEE_RECORD: 'रिकॉर्ड देखें',
      TOOLTIP_TEXT_BUTTON_VIEW_RECORD: 'रिकॉर्ड देखें',
      TOOLTIP_TEXT_ADD_NEW_RECORD: 'नया रिकॉर्ड जोड़ें'

    },
  }
};
