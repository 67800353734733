import { Status } from "app/enums";

export const locale = {
  lang: 'en',
  data: {
    COMMON:{
      CONTROL_LABEL_EMAIL_ADDRESS:'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS:'Type here...',
      PLACEHOLDER_TYPE_HERE:'Type here...',
      PLACEHOLDER_MOBILE_NUMBER:'0123456789',
      BUTTON_TEXT_SAVE_CHANGES:'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS:'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL:'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED:'Could not connect with server.',
      COMMON_STATUS:
      [
        {ErrorCode:Status.UserNotFound,ErrorMessage:'User could not be found.'}
      ],
    },
    MENU:{
      CONTROL_LABEL_VERSION:'Version',
      CONTROL_LABEL_UPDATED_ON:'Updated On',
      CONTROL_LABEL_SUPPORT:'Support',
      CONTROL_LABEL_FEEDBACK:'Feedback',
      TOOLTIP_TEXT_BUTTON_VERSION:'Version',
      TOOLTIP_TEXT_BUTTON_UPDATED_ON:'Updated On',
      TOOLTIP_TEXT_BUTTON_SUPPORT:'Support',
      TOOLTIP_TEXT_BUTTON_FEEDBACK:'Feedback',
      TOOLTIP_TEXT_LANGUAGE:'Language',
      TOOLTIP_TEXT_TOGGLE_SKIN:'Toggle Skin',
      TOOLTIP_TEXT_NOTIFICATION:'Notification',
      TOOLTIP_TEXT_USER:'User',
      CALENDAR:'Calendar',
      UPLOAD:'Upload',
      ADD_JOURNAL_ENTRY:'Add a Journal Entry',
      ADD_DIARY_ENTRY:'Add a Diary Entry'
    },
    NAVBAR:{
      CONTROL_LABEL_MY_NOOSOM_PROFILE:'My Noosom Profile',
      CONTROL_LABEL_SETTINGS:'Settings',
      CONTROL_LABEL_PRICING:'Pricing',
      CONTROL_LABEL_GIVE_FEEDBACK:'Give Feedback',
      CONTROL_LABEL_SUPPORT:'Support / FAQ',
      CONTROL_LABEL_LOGOUT:'Logout',
      CONTROL_LABEL_SEARCH:'Search'
    },
  }
};
