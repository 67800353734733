import { AttachmentType } from "app/enums";

export class OutputModel {
    Status: number;
    Id: number;
    Message: string;
}

export class DeleteInputModel {
    DeleteID: number;
    UserDetailsID: number;
    RecordID?:number;
}
export class DownloadFileInputModel {
    DownloadID: number;
    FilePath: string;
    UserDetailsID: number;
}
export class DownloadFileOutputModel {
    FileName: string;
    FileMimeType: string;
    FileBase64String: string;
    FileDownloadPath: string;
    AccessKey: string;
}

export class DeleteMultipleInputModel
{
        DeleteIDs:Array<number> ;
        UserDetailsID : number;
        RecordId?:number;
}
export class DeleteAttachmentInputModel
{
    DeleteID: number;
    UserDetailsID: number;
    RecordID: number;
}

export class UpdateDefaultThumbnailInputModel
{
    UserDetailsId: number;
    GalleryType: AttachmentType;
    ParentId: number;
    AttachmentId: number;

}

