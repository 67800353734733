import { AfterContentInit, AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'app/auth/service';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { Observable, Subject, Subscription, async } from 'rxjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { map, takeUntil } from 'rxjs/operators';
import { AddressDocumentListOutputModel, AddressLoadDataOutputModel, TblUserAddresses } from '../address-model.model';
import { Status, Enums, MapModalType, RecordsType } from 'app/enums';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { E } from 'assets/extensions/extensions';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbnail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import autoPlay from 'lightgallery/plugins/autoplay';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import { MapAddressComponent } from '../map-address/map-address.component';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MapAddressDataModel } from '../map-address/map-address-data-model.model';
import { LightGallery } from 'lightgallery/lightgallery';
import Swal from 'sweetalert2';
import { DeleteAttachmentInputModel, DeleteInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { SelectSyncFileManagerComponent } from 'app/sync-file-manager/select-sync-file-manager/select-sync-file-manager.component';
// import { title } from 'process';
import { NgbModal, NgbNav, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Router, RouterLink } from '@angular/router';
import { GeneralService } from 'app/Services/general.service';
@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent implements OnInit, AfterContentInit {
  @ViewChild('addressTag') addressTag: TextBoxComponent;
  @ViewChild('notes') notes: ElementRef;
  @ViewChild('AddressGallery') lightGallery!: LightGallery;
  @ViewChild('popover', { static: false }) popover: NgbPopover;
  @ViewChild('fileInput') fileInput;
  @ViewChild('pagination', { static: false }) pagination: ElementRef;
  @ViewChild('nav') nav: NgbNav;
  public loading = false;
  public submitted = false;
  currentAppLanguage: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public error = '';
  public dialogTitle: string;
  public PlaceholderState: any;
  public PlaceholderCountry: any;
  public PlaceholderRegion: any;
  public frmAddress: FormGroup;
  public IsForEdit: boolean = false;
  public IsForAbandon: boolean = false;
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  public DocumentList: AddressDocumentListOutputModel[] = [];
  public DocumentList1: AddressDocumentListOutputModel[] = [];
  public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  public downloadFileInputModel: DownloadFileInputModel;
  public _ImageUrlAPI: string = Constant.API + 'api/UserAddress/UploadUserAddressDocuments';
  public hasBaseDropZoneOver: boolean = false;
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderAddress",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "pdf", "video","mp4", "mpeg", "mov", "avi", "ogv", "webm","flv"]  // "doc", "docx", "xls", "xlsx", "ppt",
  });
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  GalleryItems: any = [];
  GalleryItems1: any = [];
  _data: number = 0;
  date: Date = new Date();
  addressLoadDataOutputModel$: Observable<AddressLoadDataOutputModel>;
  addressLoadDataOutputModel: AddressLoadDataOutputModel = new AddressLoadDataOutputModel();
  userAddress: TblUserAddresses;
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager:false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };

  private needRefresh = false;
  private subSaveAddress$?: Subscription;
  private subDownloadAddressAtt$?: Subscription;
  private subDeleteAddressAtt$?: Subscription;
  private subDeleteAddress$?: Subscription;
  deleteInputModel: DeleteInputModel = new DeleteInputModel();
  private subStateList$?: Subscription;
  private subCountryList$?: Subscription;
  public tab = 1;
  galleryRefresh:LightGallery;
  UserAddressDocument: any;
  cache: { [key: string]: string } = {};
  infoTooltip:string;
  uploadCount=0;
  dateformat: string;
  timeformat: string;
  dateformat1: string;
  page=0;

  constructor(
    private _formBuilder: FormBuilder,
    private _toastrService: CustomToastrService,
    private dialogRef: MatDialogRef<AddAddressComponent>,
    private globalService: GlobalService,
    private encrDecr: EncrDecrService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private loadingScreenService: LoadingScreenService,
    private modalService: NgbModal,
    private _router: Router,
    private generalService : GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    this._data = data;
    this.settings.licenseKey=this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    this.dateformat=this.globalService.getDateFormat().toUpperCase();
    this.dateformat1=this.globalService.getDateFormat();
    this.timeformat = this.globalService.getTimeFormat();
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.addressTag)
        this.addressTag.textBox.nativeElement.focus();
        if (this.notes)
        this.notes.nativeElement.focus();
    }, 500);
  }
  ngAfterViewChecked(): void {
    if (this.needRefresh) {
     // if (this.lightGallery)
        this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
  }

  onBeforeClose = async (detail) => {
    this.galleryRefresh.refresh();
  }

  fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
    if(this.hasBaseDropZoneOver == false && this.uploader.queue.length>0){
      this.uploader.uploadAll();
    }
  }

  onInitGallery = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customDeleteButton = `<button id="lg-custom-button-delete" style="padding-top:8px" ngbTooltip="tag" class="svg-icon lg-icon lg-group"><img class="svg-icon" title="Delete" style="height:28px;" src="assets/images/icons/trash.png"></button>`;
    const customDownloadButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" title="Download" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customDeleteButton);
    $toolbar.append(customDownloadButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
     await this.onDeleteDocument(idname.alt)
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, id) => id !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
      //detail.instance.refresh();
    })

    document.getElementById("lg-custom-button-download").addEventListener("click", () => {
      const downloadDocument: AddressDocumentListOutputModel = new AddressDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      if(idname.src){
      downloadDocument.DocumentPath = idname.src;}
      else if(idname.video){
        downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
      }
      downloadDocument.UserAddressDocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };

  onInitGallery1 = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customDeleteButton = `<button id="lg-custom-button-delete1" style="padding-top:8px" ngbTooltip="tag" class="svg-icon lg-icon lg-group"><img class="svg-icon" title="Delete" style="height:28px;" src="assets/images/icons/trash.png"></button>`;
    const customDownloadButton = `<button id="lg-custom-button-download1" class="custombutton12 lg-icon"><img style="height:30px" title="Download" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help1" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customDeleteButton);
    $toolbar.append(customDownloadButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete1").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
     await this.onDeleteDocument(idname.alt)
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, id) => id !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
      //detail.instance.refresh();
    })

    document.getElementById("lg-custom-button-download1").addEventListener("click", () => {
      const downloadDocument: AddressDocumentListOutputModel = new AddressDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      if(idname.src){
      downloadDocument.DocumentPath = idname.src;}
      else if(idname.video){
        downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
      }
      downloadDocument.UserAddressDocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help1").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };


  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }


  // loadData(){
  //   const path = 'api/UserAddress/GetUserAddressLoadData';
  //   let response: Observable<any>;
  //   response = this.globalService.PostDataById(this._data, path);
  //   response.subscribe(
  //     (result) => {
  //       this.loadingScreenService.stopLoading();
  //       if (result.status == Status.Success) {
  //         this.addressLoadDataOutputModel = <AddressLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
  //         if (this.addressLoadDataOutputModel.AddressDocumentList) {
  //           this.addressLoadDataOutputModel.AddressDocumentList.forEach((doc) => {
  //           if (doc.FileType.toLocaleLowerCase() == "images") {
  //               this.AddItemToGallery(doc.UserAddressDocumentId, doc.DocumentPath, doc.DocumentOldName, doc.FileType);
  //               //this.needRefresh = true;
  //             }
  //           });
  //         }
  //        // return this.quickpostLoadDataOutputModel;
  //       }
  //       else {
  //       }
  //     },
  //     (error) => {
  //       this._toastrService.Error(error, Constant.ErrorToastrTitle);
  //       this.error = error;
  //       this.loadingScreenService.stopLoading();
  //     }
  //   );
  // }

  ngOnInit(): void {
    this.userAddress = new TblUserAddresses();
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.ADDRESS.DIALOG_TITLE_ADD_ADDRESS;
    this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT;
    this.frmAddress = this._formBuilder.group({
      AddressTag: ['', Validators.required],
      AddressName: ['', Validators.required],
      Address1: ['', Validators.required],
      Address2: [''],
      Address3: [''],
      AddressTown: ['', Validators.required],
      CurrentAddressYn: [],
      StateId: [, Validators.required],
      AddressPostCode: ['', Validators.required],
      CountryId: [, Validators.required],
      RegionId: [, Validators.required],
      AddressFromDate: [, Validators.required],
      AddressToDate: [],
      Notes: []
    });
    if (typeof this._data !== 'undefined') {
      if (this._data > 0) {   //Note: API is being called to load region list in add a new address.
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.ADDRESS.DIALOG_TITLE_EDIT_ADDRESS;
      }
      //setTimeout(() => {
      //this.loadingScreenService.startLoading();
      const path = 'api/UserAddress/GetUserAddressLoadData';
      var input = {
        recordId: this._data,
        docYN:false
      }
      this.addressLoadDataOutputModel$ = this.globalService.PostDataById(input, path)
        .pipe(
          map((data) => {
            if (data.status == Status.Success) {
              this.addressLoadDataOutputModel = <AddressLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
              if (this.addressLoadDataOutputModel.AddressDocumentList) {
                this.addressLoadDataOutputModel.AddressDocumentList.forEach(async(doc) => {
                  if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                    this.DocumentList.push(doc);
                  }
                  else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
                    await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName,doc.Size ,doc.DocumentSubType, doc.CreatedDate, doc.CreatedTime);
                    this.needRefresh = true;
                  }
                });
              }

              if (this.addressLoadDataOutputModel.UserAddress) {
                this.userAddress = this.addressLoadDataOutputModel.UserAddress;
                this.frmAddress.setValue({
                  Address1: this.userAddress.Address1,
                  Address2: this.userAddress.Address2,
                  Address3: this.userAddress.Address3,
                  AddressFromDate: E.ConvertToDate(this.userAddress.AddressFromDate),
                  AddressToDate: E.ConvertToDate(this.userAddress.AddressToDate),
                  AddressTag: this.userAddress.AddressTag,
                  AddressName: this.userAddress.AddressName,
                  AddressPostCode: this.userAddress.AddressPostCode,
                  AddressTown: this.userAddress.AddressTown,
                  RegionId: this.userAddress.RegionId,
                  CountryId: this.userAddress.CountryId,
                  StateId: this.userAddress.StateId,
                  Notes: this.userAddress.Notes,
                  CurrentAddressYn: this.userAddress.CurrentAddressYn,
                });
               // this.toggleSelection(this.userAddress.CurrentAddressYn)
                // if (this.userAddress.CountryId) {
                //   this.frmAddress.get('CountryId').enable();
                // }
                if (this.userAddress.StateId) {
                  this.frmAddress.get('StateId').enable();
                }
              }
              return this.addressLoadDataOutputModel;
            }
            else {
              this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
            }
          },
            (error: string) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              //this.loadingScreenService.stopLoading();
            }
          ));
      //}, 500);
      //}
    }
    this.PlaceholderRegion = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_REGION;
    this.PlaceholderCountry = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_COUNTRY;
    this.PlaceholderState = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_STATE;
    this.frmAddress.get('CountryId').disable();
    this.frmAddress.get('RegionId').disable();
    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserAddressID', value: E.ConvertToString(this._data) }]
      });
      //this.uploader.setOptions({ url: fileItem.url + fileItem.file.name });
      return fileItem;
    };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   alert('File uploaded');
    // };
    this.uploader.response.subscribe(result => {
      if (result) {
        this.loadingScreenService.startLoading();
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
            this.uploaderWarning=true;
            this.loadingScreenService.startLoading();
        }else if(this.uploader.progress==100){
          this.uploaderWarning=false;
          this.forgotuploadWarning=false;
         // this.uploadCount = 0;
          this.loadingScreenService.stopLoading();
        }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _AddressDocument = <AddressDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
        //  console.log(_AddressDocument,'------------------------------------ load -----------------------------------------')
          if (_AddressDocument.DocumentSubType.toLocaleLowerCase() == "documents") {
            this.DocumentList.push(_AddressDocument);
            this.DocumentList1.push(_AddressDocument);
          }
          else if (_AddressDocument.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
            this.AddItemToGallery(_AddressDocument.DocumentId, _AddressDocument.DocumentPath, _AddressDocument.DocumentOldName, _AddressDocument.Size,_AddressDocument.DocumentSubType, _AddressDocument.CreatedDate, _AddressDocument.CreatedTime);
            this.AddItemToGallery1(_AddressDocument.DocumentId, _AddressDocument.DocumentPath, _AddressDocument.DocumentOldName, _AddressDocument.Size,_AddressDocument.DocumentSubType, _AddressDocument.CreatedDate, _AddressDocument.CreatedTime);
            this.needRefresh = true;
          }
        }
        else if(_result.status == Status.StorageFull){
          this.loadingScreenService.stopLoading();
         // this.uploader.destroy();
          this.uploader.cancelAll();
          // this.uploader.removeFromQueue();
          // this.uploader.cancelItem()
          this.uploader.clearQueue()
          Swal.fire({
            title: "Storage Full",
            text: _result.message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText: "Buy More Storage",
            cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          })
          .then(
            (async (result) => {
              if (result.value) {
                //this.uploader.clearQueue();
                this.dialogRef.close(true);
                this._router.navigate(['/pages/pricing'])
              }
            })
          );
              
        }
      }
     // this.loadingScreenService.stopLoading();
    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
  }

  loadPage(event,filetype){
    // this.savePosition();
     this.pageAdvancedEllipses
    this.getAttachmentList(filetype,event)
   // setTimeout(() => this.restorePosition(), 0);
   }

   getAttachmentList(FileType?,page?) {
    this.GalleryItems=[]
    this.DocumentList = [];
    //this.needRefresh = true;
    //if(page)
    this.page = page
    var getMyAttachmentsInputModel = {
      RecordId: this._data,
      Page:this.page,
      Pagesize:20,
      FileType:FileType,
    //  QuickPostType:QuickPostType.Public,
      GetMetaDataYN:false
    }
    this.loadingScreenService.startLoading();
    this.generalService.GetUserAddressDocumentsById(getMyAttachmentsInputModel).subscribe(async result => {
      if (result) {
        if (result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          if (_attachmentlist) {
            _attachmentlist.forEach(async(doc) => {
              if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                this.DocumentList.push(doc);
              }
              else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
                await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName,doc.Size ,doc.DocumentSubType, doc.CreatedDate, doc.CreatedTime);
                this.needRefresh = true;
              }
              if(_attachmentlist[0]?.TotalNumberOfRecords>0)
                this.pagesNumber=_attachmentlist[0]?.TotalNumberOfRecords;
            });
         } 
        this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(result.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }

  checkQueueLength(queuLength){
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
          this.uploaderWarning=false;
        }
  }

  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string, CreatedDate:string, CreatedTime:string): Promise<any> {
   // this.loadingScreenService.startLoading();
    const loadingItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems1.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems1.findIndex(item => item.id === _id);
    this.GalleryItems1[index] = galleryItem;
  }

  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string, CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems.findIndex(item => item.id === _id);
    this.GalleryItems[index] = galleryItem;
  }


  onFileSelected(event){
    this.uploader.uploadAll();
  }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }

  onTabActive(tabNumber:number){
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT;}
   else if(tabNumber==2){
    this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT3;
      setTimeout(() => {
      if (this.notes)
      this.notes.nativeElement.focus();
    },500)
  }
  else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT1;}
  else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT2;
    this.getAttachmentList('Images');
  }
  else if(tabNumber==5){this.infoTooltip= this.currentAppLanguage.data.ADDRESS.INFO_TEXT4;
    this.getAttachmentList('Documents');
  }
    this.tab=tabNumber;
  }

  // toggleSelection(event){
  //   if(event==true){
  //     setTimeout(() => {
  //       this.frmAddress.get('AddressToDate').setValue(null)
  //       this.frmAddress.get('AddressToDate').setErrors(null)
  //      this.todateRequired=false;
  //     }, 100);
 
  //   }
  //   else {
  //     this.frmAddress.get('AddressToDate').setErrors({ 'required': true })
  //     this.todateRequired=true;
  //   }
  // }

  onSubmit(tabs: boolean) {
    if (typeof this.userAddress.Latitude === 'undefined' || typeof this.userAddress.Longitude === 'undefined' || this.userAddress.Latitude == null || this.userAddress.Longitude == null) {
      this._toastrService.Warning(this.currentAppLanguage.data.ADDRESS.WARNING_MESSAGE_SELECT_LOCATION, Constant.WarningToastrTitle);
      this.onSearchOnMapClick();
      return;
    }
     if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }

    this.submitted = true;
    if (this.frmAddress.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userAddress == null) {
      this.userAddress = new TblUserAddresses();
    }
    this.userAddress.AddressTag = this.frmAddress.get('AddressTag').value;
    this.userAddress.AddressName = this.frmAddress.get('AddressName').value;
    this.userAddress.Address1 = this.frmAddress.get('Address1').value;
    this.userAddress.Address2 = this.frmAddress.get('Address2').value;
    this.userAddress.Address3 = this.frmAddress.get('Address3').value;
    this.userAddress.AddressTown = this.frmAddress.get('AddressTown').value;
    this.userAddress.AddressPostCode = this.frmAddress.get('AddressPostCode').value;
    this.userAddress.RegionId = this.frmAddress.get('RegionId').value;
    this.userAddress.AddressFromDate = E.GetDateStringOnly(this.frmAddress.get('AddressFromDate').value);
    this.userAddress.AddressToDate = E.GetDateStringOnly(this.frmAddress.get('AddressToDate').value);
    this.userAddress.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    this.userAddress.CountryId = this.frmAddress.get('CountryId').value;
    this.userAddress.StateId = this.frmAddress.get('StateId').value;
    this.userAddress.Notes = this.frmAddress.get('Notes').value;
    if (this.frmAddress.get('CurrentAddressYn').value)
      this.userAddress.CurrentAddressYn = this.frmAddress.get('CurrentAddressYn').value;
    else { this.userAddress.CurrentAddressYn = false }
    if (this.frmAddress.get('CountryId').value) {
      this.userAddress.AddressCounty = this.addressLoadDataOutputModel.CountryList.filter(c => c.CountryId == this.frmAddress.get('CountryId').value)[0].CountryName; }
    if (E.ConvertToNumber(this.userAddress.UserAddressesId) == 0 && this.IsForEdit == false) {
      this.userAddress.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAddress.CreatedDate = E.GetTodayDateStringOnly();
      this.userAddress.CreatedTime = E.GetTodayTimeStringOnly();
      this.userAddress.ChangedDate = E.GetTodayDateStringOnly();
      this.userAddress.ChangedTime = E.GetTodayTimeStringOnly();}
    else {
      this.userAddress.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userAddress.ChangedDate = E.GetTodayDateStringOnly();
      this.userAddress.ChangedTime = E.GetTodayTimeStringOnly();}
    if (this.tab <= 2 || this.IsForEdit) {
      this.loading = true;
      const path = 'api/UserAddress/CreateUpdateUserAddress';
      let response: Observable<any>;
    response = this.globalService.PostDataById(this.userAddress, path)
    response.subscribe(
        (result) => {
          this.loading = false;
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = this.currentAppLanguage.data.ADDRESS.TOAST_MESSAGE_ADDRESS_ADDED_SUCCESSFULLY;
            this.tab = this.tab + 1;
            this.nav.select(this.tab)
            if(this.tab==2){
              setTimeout(() => {
              if (this.notes)
              this.notes.nativeElement.focus();
            },500)
          }
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserAddressId;
            this.userAddress.UserAddressesId=parsedObject.Data.UserAddressId;
            this.userAddress.UserAddressId=parsedObject.Data.UserAddressId;
            this.IsForAbandon=true;
          }
          else if (result.status == Status.RecordUpdated) {
            message = this.currentAppLanguage.data.ADDRESS.TOAST_MESSAGE_ADDRESS_UPDATED_SUCCESSFULLY;
            if(!tabs)
            {this.tab=this.tab+1
            this.nav.select(this.tab) 
          }
          if(this.tab == 4){
            this.getAttachmentList('Images');
           }
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            if ((this.tab == 1) || tabs) {
              this.dialogRef.close(true);
              this.frmAddress.reset();
            this.frmAddress.patchValue({
              AddressFromDate: new Date(),
              AddressToDate: new Date()
            });}
            (<any>this.frmAddress.get('AddressTag')).nativeElement.focus();
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else if (this.tab >= 2 && this.tab <= 5) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      if(this.tab == 5){
        this.getAttachmentList('Documents');
       }
      if (tabs) {
        this.dialogRef.close(true);
        this.frmAddress.reset();
      }
    }
  }

  async OnAddressDeleteClick() {
    Swal.fire({
      title: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TITLE_ADDRESS_TEXT,
      text: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const UserAddressId = this._data
            const a = await this.DeleteMyAddress(UserAddressId);
          }
        })
      );
  }

  async DeleteMyAddress(UserAddressId: number) {
    this.loadingScreenService.startLoading();
    this.deleteInputModel = {
      DeleteID: UserAddressId,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      const path = 'api/UserAddress/DeleteUserAddress';
      this.subDeleteAddress$ = this.globalService.PostDataById(this.deleteInputModel, path).subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          var result = JSON.parse(JSON.stringify(data));
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: E.ConvertToString(result.message),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            this.dialogRef.close(true);
            this.frmAddress.reset();
            Result = true;
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  async onDownloadAllDocument() {
    for (let index = 0; index < this.DocumentList.length; index++) {
      const _doc = this.DocumentList[index];
      var _res = await this.DownloadMyAddressDocument(_doc, true);
      if (_res) {
        if ((index + 1) === this.DocumentList.length) {
          this._toastrService.Success('Downloaded All Successfully.', Constant.SuccessToastrTitle);
        }
      } else {
        break;
      }
    }
  }
  async onDownloadDocument(document: AddressDocumentListOutputModel) {
    if (document) {
      await this.DownloadMyAddressDocument(document);
    }
  }
  async DownloadMyAddressDocument(Document: AddressDocumentListOutputModel, IsDownloadAll = false) {
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.UserAddressDocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      const path = 'api/UserAddress/DownloadUserAddressDocuments';
      this.subDownloadAddressAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  async onDeleteAllDocument() {
    let UserAddressDocumentIds: number[] = [];
    if (this.DocumentList) {
      this.DocumentList.forEach(_doc => {
        UserAddressDocumentIds.push(_doc.UserAddressDocumentId);
      });
      if (UserAddressDocumentIds.length > 0) {
        Swal.fire({
          title: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TITLE_ALL_ADDRESS_DOCUMENT_TEXT,
          text: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TEXT,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
          cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .then(
            (async (result) => {
              if (result.value) {
                const _res = await this.DeleteMyAddressDocument(UserAddressDocumentIds);
              }
            })
          );
      }
    }
  }
  // async onDeleteDocument(userAddressDocumentId: number) {
  //   let UserAddressDocumentIds: number[] = [];
  //   if (userAddressDocumentId) {
  //     UserAddressDocumentIds.push(userAddressDocumentId);
  //     Swal.fire({
  //       title: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT,
  //       text: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TEXT,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#7367F0',
  //       cancelButtonColor: '#E42728',
  //       confirmButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
  //       cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
  //       customClass: {
  //         confirmButton: 'btn btn-primary',
  //         cancelButton: 'btn btn-danger ml-1'
  //       }
  //     })
  //       .then(
  //         (async (result) => {
  //           if (result.value) {
  //             const a = await this.DeleteMyAddressDocument(UserAddressDocumentIds);
  //           }
  //         })
  //       );
  //   }
  // }
  // async DeleteMyAddressDocument(UserAddressDocumentIds: Array<number>) {
  //   this.loadingScreenService.startLoading();
  //   if (UserAddressDocumentIds.length < 1) {
  //     return false;
  //   }
  //   this.deleteDocumentsInputModel = [];
  //   UserAddressDocumentIds.forEach(userAddressDocumentId => {
  //     this.deleteDocumentsInputModel.push({
  //       DeleteID: userAddressDocumentId,
  //       UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
  //     });
  //   });
  //   let promise = new Promise((resolve, reject) => {
  //     var Result: boolean;
  //     const path = 'api/UserAddress/DeleteUserAddressDocuments';
  //     this.subDeleteAddressAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
  //       (data) => {
  //         this.loadingScreenService.stopLoading();
  //         var result = JSON.parse(JSON.stringify(data));
  //         if (result.status == Status.Success) {
  //           Swal.fire({
  //             icon: 'success',
  //             title: 'Deleted!',
  //             text: E.ConvertToString(result.message),
  //             customClass: {
  //               confirmButton: 'btn btn-success'
  //             }
  //           });
  //           this.DocumentList = this.DocumentList.filter(x => !UserAddressDocumentIds.includes(x.UserAddressDocumentId));
  //           const indexToRemove: number = this.GalleryItems.findIndex(item => item.id == UserAddressDocumentIds);
  //           if (indexToRemove !== -1) {
  //             this.GalleryItems.splice(indexToRemove, 1);
  //           }
  //           Result = true;
  //         }
  //         else {
  //           this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  //           Result = false;
  //         }
  //         resolve(Result);
  //       },
  //       (error) => {
  //         this._toastrService.Error(error, Constant.ErrorToastrTitle);
  //         this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  //         this.loadingScreenService.stopLoading();
  //       }
  //     );
  //   });
  //   return await promise;
  // }

  async onDeleteDocument(userAddressDocumentId: number) {
    if (!userAddressDocumentId) {
      return;
    }
  
    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteMyAddressDocument([userAddressDocumentId]);
  
      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }
  
  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TITLE_ADDRESS_DOCUMENT_TEXT,
        text: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CONFIRM_UNLINK_BUTTON_TEXT,
        cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }
  
  async DeleteMyAddressDocument(UserAddressDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
  
    try {
      if (UserAddressDocumentIds.length < 1) {
        return false;
      }
  
      this.deleteDocumentsInputModel = [];
       this.UserAddressDocument = UserAddressDocumentIds
      UserAddressDocumentIds.forEach((userAddressDocumentId) => {
        this.deleteDocumentsInputModel.push({
          DeleteID: userAddressDocumentId,
          UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
          RecordID: this._data
        });
      });
  
      const result = await this.postDataAndHandleResponse();
  
      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }
  
  private postDataAndHandleResponse(): Promise<boolean> {
    console.log(this.deleteDocumentsInputModel)
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserAddress/DeleteUserAddressDocuments';
      this.subDeleteAddressAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));
  
          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }
  
  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted!',
      text: E.ConvertToString(result.message),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });
  
    // Modify this part as needed
    this.DocumentList = this.DocumentList.filter((x) => !this.UserAddressDocument.includes(x.UserAddressDocumentId));
    this.DocumentList1 = this.DocumentList1.filter((x) => !this.UserAddressDocument.includes(x.UserAddressDocumentId));
    const indexToRemove: number = this.GalleryItems.findIndex((item) => item.id == this.UserAddressDocument);
    if (indexToRemove !== -1) {
      this.GalleryItems.splice(indexToRemove, 1);
    }
    const indexToRemovenew: number = this.GalleryItems1.findIndex((item) => item.id == this.UserAddressDocument);
    if (indexToRemovenew !== -1) {
      this.GalleryItems1.splice(indexToRemovenew, 1);
    }
  }
  
  private handleFailure(result: any): void {
    this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  }
  
  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }
  

  onComingSoon() {
    this._toastrService.Info(this.currentAppLanguage.data.ADDRESS.INFO_MESSAGE_COMING_SOON, Constant.InfoToastrTitle);
  }
  onCancelClick() {
    this.dialogRef.close(false);
  }
  onSearchOnMapClick() {
    if (E.ConvertToString(this.frmAddress.get('Address1').value) != '' && E.ConvertToString(this.frmAddress.get('AddressTown').value) != ''
      && E.ConvertToString(this.frmAddress.get('AddressPostCode').value) != '') {
      this.openDialogMapAddress();
    }
    else {
      this._toastrService.Error(this.currentAppLanguage.data.ADDRESS.ALERT_MESSAGE_REQUIRED_FIELDS, Constant.ErrorToastrTitle);
    }
  }
  onStateChange(event) {
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetCountryRegionId';
      var list = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            var countryRegion = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.frmAddress.get('CountryId').setValue(countryRegion?.CountryId);
            this.frmAddress.get('RegionId').setValue(countryRegion?.RegionId);
         //   this.familyMemberLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
          //  if (this.familyMemberLoadDataOutputModel.StateList != null) {
          //    this.frmFamily.get('StateId').enable();
          //  }
          }
        },
        (error) => {
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_STATE;
      this.frmAddress.get('RegionId').setValue(null);
      this.PlaceholderRegion = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_REGION;
      this.frmAddress.get('RegionId').disable();
      this.frmAddress.get('CountryId').setValue(null);
      this.PlaceholderCountry = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_COUNTRY;
      this.frmAddress.get('CountryId').disable();
    }
  }
  GetMapSearchString(): string {
    let result = '';
    if (this.frmAddress.get('Address1').value)
      result += (this.frmAddress.get('Address1').value + ', ');
    if (this.frmAddress.get('AddressTown').value)
      result += (this.frmAddress.get('AddressTown').value + ', ');
    if (this.frmAddress.get('AddressPostCode').value)
      result += (this.frmAddress.get('AddressPostCode').value);
    return result;
  }
  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%"
    dialogConfig.height = "55%";
    dialogConfig.data = new MapAddressDataModel(MapModalType.SearchString, this.GetMapSearchString());
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            this.userAddress.Latitude = result.data.lat;
            this.userAddress.Longitude = result.data.long;
          }
        }
        // this.loadData();
      }
    );
  }
  OnAddressToDateChange(event) {
    let datefrom = this.frmAddress.get('AddressFromDate').value;
    let dateto = this.frmAddress.get('AddressToDate').value;
    if (datefrom && dateto) {
      if (datefrom.getTime() > dateto.getTime()) {
        this.frmAddress.get('AddressFromDate').setValue(dateto);
      }
    }
    if (dateto)
      this.maxFromDate = dateto;
  }
  onCountryChange(event: any) {
    this.addressLoadDataOutputModel.StateList = null;
    this.frmAddress.get('StateId').setValue(null);
    this.PlaceholderState = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_STATE;
    this.frmAddress.get('StateId').disable();
    if (event != null) {
      this.PlaceholderCountry = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/State/GetStateList';
      this.subStateList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.addressLoadDataOutputModel.StateList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.addressLoadDataOutputModel.StateList != null) {
              this.frmAddress.get('StateId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderCountry = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_COUNTRY;
    }
  }
  onRegionChange(event: any) {
    this.addressLoadDataOutputModel.CountryList = null;
    this.frmAddress.get('CountryId').setValue(null);
    this.PlaceholderCountry = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_COUNTRY;
    this.frmAddress.get('CountryId').disable();
    if (event != null) {
      this.PlaceholderRegion = "";
      //this.loadingScreenService.startLoading();
      const path = 'api/Country/GetCountryList';
      this.subCountryList$ = this.globalService.PostDataAnonymous(event, path).subscribe(
        (data) => {
          //this.loadingScreenService.stopLoading();
          var result = JSON.parse(data._body);
          if (result.status == Status.Success) {
            this.addressLoadDataOutputModel.CountryList = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            if (this.addressLoadDataOutputModel.CountryList != null) {
              this.frmAddress.get('CountryId').enable();
            }
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          //this.loadingScreenService.stopLoading();
        }
      );
    }
    else {
      this.PlaceholderRegion = this.currentAppLanguage.data.ADDRESS.PLACEHOLDER_REGION;
    }
  }
  onCloseButtonClick() {
    this.dialogRef.close(false);
  }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmAddress.controls;
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }


  openFilemanager(){
    // using for old filemanager uploads
   // this.openDialog(UploadManagerComponent)
   var matdata = {
    RecordId : this._data,
    RecordType: RecordsType.Address
   }
   this.openDialog(SelectSyncFileManagerComponent,matdata)
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.height="60%"
    dialogConfig.position={
      top:"10%"
    }
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
            if(data){
              this.nav.select(4);
              this.getAttachmentList('Images');
            }
      }
    );
  }

  ngOnDestroy() {
    if (this.subSaveAddress$)
      this.subSaveAddress$.unsubscribe();
    if (this.subDownloadAddressAtt$)
      this.subDownloadAddressAtt$.unsubscribe();
    if (this.subDeleteAddressAtt$)
      this.subDeleteAddressAtt$.unsubscribe();
    if (this.subStateList$)
      this.subStateList$.unsubscribe();
    if (this.subCountryList$)
      this.subCountryList$.unsubscribe();
    if (this.subDeleteAddress$)
      this.subDeleteAddress$.unsubscribe();
    if (this.uploader && this.uploader.response)
      this.uploader.response.unsubscribe();
  }
}
