<div class="card" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h5 cdkDragHandle class="card-header"style="justify-content: unset;">{{dialogTitle}}&nbsp;<span ngbTooltip={{infoTooltip}}>
    <span data-feather="info"></span>
  </span><button class="close-button close" ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_BUTTON_CLOSE'|translate}}" type="button"
      (click)="onCloseButtonClick()" placement="right">&times;</button>
  </h5>
  <div class="card-body card-body-dialog">
    <form id="frmInsurance" [formGroup]="frmInsurance" (ngSubmit)="onSubmit(false)">
      <!-- <form >    -->
      <section id="basic-tabs-components">
        <div class="row">
          <div class="col">
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-pill-primary">
                <li [ngbNavItem]="1" (click)="onTabActive(1)">
                  <a class="font" ngbNavLink>{{'INSURANCE.TAB_HEADING_INSURANCE_DETAILS' | translate}}</a>
                  <ng-template ngbNavContent>
                    <!-- <div class="row py-3 pl-3">
                      <div class="col-sm">
                       <i>{{'INSURANCE.SECTION_TEXT'|translate}}</i>
                      </div>
                    </div> -->
                    <div class="scroll-area"> 
                      <div class="row">
                        <div class="form-group col-lg-6 col-sm-12">
                          <label class="form-label" for="InsuranceTypeId">{{'INSURANCE.CONTROL_LABEL_INSURANCE_TYPE' |translate}}
                            <span ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_INSURANCE_TYPE'|translate}}" placement="bottom">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                            <strong> *</strong>
                          </label>
                          <ng-select #InsuranceType [items]="insuranceLoadDataOutputModel?.InsuranceTypeList"
                            bindLabel="InsuranceTypeName" selectOnTab="true" bindValue="InsuranceTypeId"
                            id="InsuranceTypeId" formControlName="InsuranceTypeId"
                            [classes]="{ 'is-invalid': submitted && f.InsuranceTypeId.errors }"
                            placeholder="{{'INSURANCE.PLACEHOLDER_SELECT_INSURANCE_TYPE'|translate}}"></ng-select>
                          <span *ngIf="submitted && f.InsuranceTypeId.errors">
                            <small *ngIf="f.InsuranceTypeId.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_INSURANCE_TYPE'|translate}}</small>
                          </span>
                        </div>
                        <div class="col-lg-6 col-sm-12 form-group mb-0">
                          <app-text-box [type]="'text'" 
                            [label]="'INSURANCE.CONTROL_LABEL_POLICY_NUMBER' | translate" [maxlength]="50"
                            [classes]="{ 'is-invalid': submitted && f.PolicyNumber.errors }" 
                            [showInfoIcon]="true"
                            [tooltipInfo]="'INSURANCE.TOOLTIP_TEXT_POLICY_NUMBER'|translate" [position]="'right'"
                            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="PolicyNumber"
                            for="PolicyNumber" formControlName="PolicyNumber">
                          </app-text-box>
                          <span *ngIf="submitted && f.PolicyNumber.errors">
                            <small *ngIf="f.PolicyNumber.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_POLICY_NUMBER'|translate}}</small>
                            <small *ngIf="f.PolicyNumber.errors.maxlength"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                          </span>
                        </div>
                        <div class=" col-sm-12 form-group mb-0">
                          <app-text-box [type]="'text'" [label]="'INSURANCE.CONTROL_LABEL_INSURANCE_NAME' | translate"
                            [showRequiredSign]="true" [maxlength]="100" [showInfoIcon]="true"
                            [tooltipInfo]="'INSURANCE.TOOLTIP_TEXT_INSURANCE_NAME'|translate" [position]="'right'"
                            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="InsuranceName"
                            for="InsuranceName" formControlName="InsuranceName"
                            [classes]="{ 'is-invalid': submitted && f.InsuranceName.errors }">
                          </app-text-box>
                          <span *ngIf="submitted && f.InsuranceName.errors">
                            <small *ngIf="f.InsuranceName.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_INSURANCE_NAME'|translate}}</small>
                            <small *ngIf="f.InsuranceName.errors.maxlength"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_MAX_LENGTH_100'|translate}}</small>
                          </span>
                        </div>
                        <div class="col-sm-12 col-lg-6 form-group mb-0">
                          <app-text-box [type]="'text'" 
                            [label]="'INSURANCE.CONTROL_LABEL_CARD_NUMBER' | translate" [maxlength]="50"
                            [classes]="{ 'is-invalid': submitted && f.CardNumber.errors }" 
                            [showInfoIcon]="true"
                            [tooltipInfo]="'INSURANCE.TOOLTIP_TEXT_CARD_NUMBER'|translate" [position]="'right'"
                            [placeholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate" aria-describedby="CardNumber"
                            for="CardNumber" formControlName="CardNumber">
                          </app-text-box>
                          <span *ngIf="submitted && f.CardNumber.errors">
                            <small *ngIf="f.CardNumber.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_CARD_NUMBER'|translate}}</small>
                            <small *ngIf="f.CardNumber.errors.maxlength"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_MAX_LENGTH_50'|translate}}</small>
                          </span>
                        </div>
                        <div class="form-group col-lg-6 col-sm-12">
                          <label class="form-label d-block"
                            for="ContactNumber">{{'INSURANCE.CONTROL_LABEL_INSURANCE_CONTACT_NUMBER' |translate}}
                            <span ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_INSURANCE_CONTACT_NUMBER'|translate}}" placement="right">
                              <span data-feather="help-circle"
                                class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                          </label>
                          <ngx-intl-tel-input [separateDialCode]="true" [searchCountryFlag]="true" name="ContactNumber"
                            [phoneValidation]="true" [maxlength]="12" formControlName="ContactNumber"
                            [selectedCountryISO]="SelectedCountryISO" id="ContactNumber"
                            [classes]="{ 'is-invalid': submitted && f.ContactNumber.errors }"
                            [customPlaceholder]="'COMMON.PLACEHOLDER_TYPE_HERE' | translate">
                          </ngx-intl-tel-input>
                          <span *ngIf="submitted && f.ContactNumber.errors">
                            <small *ngIf="f.ContactNumber.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_CONTACT_NUMBER'
                              | translate}}</small>
                            <small *ngIf="f.ContactNumber.errors.maxlength"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_MAX_LENGTH_12'
                              | translate}}</small>
                            <small *ngIf="f.ContactNumber.errors && !f.ContactNumber.errors.required"
                              class="text-danger">{{'INSURANCE.ERROR_MESSAGE_INVALID_CONTACT_NUMBER'
                              | translate}}</small>
                          </span>
                        </div>
                        <div class="col-sm">
                          <div class="form-group">
                            <label class="form-label" for="PolicyStartDate">{{'INSURANCE.CONTROL_LABEL_POLICY_START_DATE' |translate}}
                              <span ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_POLICY_START_DATE'|translate}}" placement="right">
                                <span data-feather="help-circle"
                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              </label>
                            <div class="input-group" style="width: 100%;">
                              <input id="PolicyStartDate" name="PolicyStartDate"
                                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                [maxDate]="maxFromDate" placeholder="{{dateformat}}"
                                [classes]="{ 'is-invalid': submitted && f.PolicyStartDate.errors }" required
                                bsDatepicker #dpstartdate="bsDatepicker" formControlName="PolicyStartDate"
                                class="form-control">
                              <div class="input-group-append">
                                <button class="btn btn-outline-primary feather icon-calendar"
                                  (click)="dpstartdate.toggle()" type="button" rippleEffect placement="top"
                                  ngbTooltip="{{'INSURANCE.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                              </div>
                            </div>
                            <span *ngIf="submitted && f.PolicyStartDate.invalid">
                              <small class="text-danger"
                                *ngIf="f.PolicyStartDate.errors.required">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_POLICY_START_DATE'
                                | translate}}</small>
                            </span>
                          </div>
                        </div>
                        <div class="col-sm">
                          <div class="form-group">
                            <label class="form-label" for="PolicyEndDate">{{'INSURANCE.CONTROL_LABEL_POLICY_EXPIRATION_DATE' | translate}}
                              <span ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_POLICY_EXPIRATION_DATE'|translate}}" placement="right">
                                <span data-feather="help-circle"
                                  class="info-icon align-middle mr-sm-25 mr-0"></span></span>
                              </label>
                            <div class="input-group" style="width: 100%;">
                              <input id="PolicyEndDate" name="PolicyEndDate"
                                [classes]="{ 'is-invalid': submitted && f.PolicyEndDate.errors }"
                                [bsConfig]="{ showWeeks: false,containerClass:'theme-blue',dateInputFormat:dateformat}"
                                required bsDatepicker #dpenddate="bsDatepicker" formControlName="PolicyEndDate" placeholder="{{dateformat}}"
                                class="form-control" (ngModelChange)="OnPolicyExpirationDateChange($event)">
                              <div class="input-group-append">
                                <button class="btn btn-outline-primary feather icon-calendar"
                                  (click)="dpenddate.toggle()" type="button" rippleEffect placement="top"
                                  ngbTooltip="{{'INSURANCE.TOOLTIP_TOGGLE_CALENDAR' | translate}}"></button>
                              </div>
                            </div>
                            <span *ngIf="submitted && f.PolicyEndDate.invalid">
                              <small class="text-danger"
                                *ngIf="f.PolicyEndDate.errors.required">{{'INSURANCE.ERROR_MESSAGE_REQUIRED_POLICY_EXPIRATION_DATE'
                                | translate}}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="2" [disabled]="!_data && !IsForEdit">
                  <a class="font" (click)="onTabActive(2)" ngbNavLink>{{'INSURANCE.TAB_HEADING_NOTES' | translate}}</a>
                  <ng-template ngbNavContent>
                    <!-- <div class="row py-3 pl-3">
                      <div class="col-sm">
                        <i>{{'INSURANCE.SECTION_TEXT3'|translate}}</i>
                      </div>
                    </div> -->
                    <div class="scroll-area">
                      <label style="color: #323091;">{{'INSURANCE.CONTROL_LABEL_NOTES'|translate}}</label>
                      <fieldset class="form-group">
                        <textarea style="width: 100%;" rows="10" class="form-control" formControlName="Notes"
                        #notes  placeholder="{{'COMMON.PLACEHOLDER_TYPE_HERE'|translate}}"></textarea>
                      </fieldset>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="3"  [disabled]="!_data && !IsForEdit">
                  <a class="font" (click)="onTabActive(3)" ngbNavLink>{{'INSURANCE.TAB_HEADING_UPLOADS' | translate}}</a>
                  <ng-template ngbNavContent>
                    <!-- <div class="row py-3 pl-3">
                      <div class="col-sm">
                        <i>{{'INSURANCE.SECTION_TEXT1'|translate}}</i>
                      </div>
                    </div> -->
                    <div class="scroll-area">
                      <div class="row">
                        <div class="col">
                          <div class="row ml-0">
                          <h4 class="font pt-4" style="color: #323091;width: fit-content;">{{'INSURANCE.HEADING_TEXT_SELECT_IMAGES' |
                            translate}}: </h4>
                            <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                            <!-- <div class="custom-file">
                              <input type="file" id="MultiFileUploaderInsurance" (change)="onFileSelected($event)" class="custom-file-input" type="file"
                              ng2FileSelect [uploader]="uploader" multiple id="file-upload-multiple" />
                              <button class="custom-file-label font btn btn-primary custom-file-upload-btn"
                                for="customFile">Upload</button>
                            </div> -->
                            <!-- <fieldset class="form-group mb-0 ml-2 " style="margin-top: 18px;">
                              <div ngbDropdown class="input-group-append">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  ngbDropdownToggle
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  rippleEffect
                                >
                                  Browse
                                </button>
                                <div ngbDropdownMenu class="dropdown-menu-rightnew" >
                                  <a ngbDropdownItem (click)="openFilemanager()">From Noosom Media</a>
                                  <a ngbDropdownItem (click)="triggerFileUpload()">From External Device</a>
                                </div>

                            </div>

                                <div class="custom-file" style="display: none;">
                                  <input type="file" id="MultiFileUploaderInsurance" class="custom-file-input" (change)="onFileSelected($event)"
                                    ng2FileSelect [uploader]="uploader" multiple #fileInput />
                                  <label class="custom-file-label font" for="file-upload-multiple">{{'UPLOAD.PLACEHOLDER_CHOOSE_FILES' | translate}}</label>
                                </div>
                              </fieldset> -->
                          </fieldset></div>
                          <h5 style="color: #323091;">{{'INSURANCE.TEXT_FILE_TYPES' | translate}} "pdf"</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col overflow-auto">
                          <!-- <div class="d-flex justify-content-between mb-1">
                            <h4 class="font">{{'INSURANCE.HEADING_TEXT_UPLOAD_QUEUE' | translate}}</h4>
                            <p>{{'INSURANCE.TEXT_QUEUE_LENGTH' | translate}} {{ uploader?.queue?.length }}</p>
                          </div>

                          <table class="table">
                            <thead style="color: #323091;">
                              <tr>
                                <th width="50%" text-align="left">{{'INSURANCE.COLUMN_HEADING_NAME' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_SIZE' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_PROGRESS' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_STATUS' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_ACTIONS' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of uploader.queue">
                                <td>
                                  {{ item?.file?.name }}
                                </td>
                                <td *ngIf="uploader.options.isHTML5" nowrap>
                                  {{ item?.file?.size / 1024 / 1024 | number: '.2' }} {{'INSURANCE.TEXT_MB' |
                                  translate}}
                                </td>
                                <td *ngIf="uploader.options.isHTML5">
                                  <ngb-progressbar type="primary" striped="true" animated="true"
                                    [value]="item.progress"></ngb-progressbar>
                                </td>
                                <td class="text-center">
                                  <span *ngIf="item.isSuccess"><i data-feather="check-circle"></i></span>
                                  <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                                  <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                                </td>
                                <td nowrap>
                                  <button type="button" class="btn btn-raised btn-success btn-sm mr-50"
                                    (click)="item.upload()"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess" rippleEffect>
                                    <span data-feather="upload"></span>
                                  </button>
                                  <button type="button" class="btn btn-raised btn-danger btn-sm" (click)="item.remove();checkQueueLength(uploader?.queue?.length)"
                                    rippleEffect>
                                    <i data-feather="trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div id="">
                            <p class="mt-1 font">{{'INSURANCE.HEADING_TEXT_QUEUE_PROGRESS' | translate}}</p>
                            <ngb-progressbar class="mb-1" type="primary" [striped]="true" [animated]="true"
                              [value]="uploader.progress"></ngb-progressbar>
                            <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                              (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length"
                              rippleEffect ngbTooltip="Upload All">
                              <span data-feather="upload" class="mr-25"></span> {{'INSURANCE.BUTTON_TEXT_UPLOAD_ALL' |
                              translate}}
                            </button>
                            
                            <button type="button" class="btn btn-danger mb-1 mb-sm-0" (click)="uploader.clearQueue();checkQueueLength(uploader?.queue?.length)"
                              [disabled]="!uploader.queue.length" rippleEffect>
                              <span data-feather="trash" class="mr-25"></span> {{'INSURANCE.BUTTON_TEXT_REMOVE_ALL' |
                              translate}}
                            </button>
                          </div> -->
                          <div *ngIf="DocumentList1.length==0"><img style="padding-left: 34%;" src="assets/images/pages/noimageuploaded.png" height="230px"></div>
                          <div>
                            <a  *ngFor="let item of DocumentList1" href={{item.DocumentPath}} target="_blank"  className="gallery-item">
                            <img
                            class="img-responsive justify-gallery" loading="lazy"
                            src="assets/images/icons/document-icon.png" 
                        /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="4" [disabled]="!_data && !IsForEdit">
                  <a class="font" (click)="onTabActive(4)" ngbNavLink>{{'INSURANCE.TAB_HEADING_DOCUMENTS' | translate}}</a>
                  <ng-template ngbNavContent>
                    <!-- <div class="row py-3 pl-3">
                      <div class="col-sm">
                        <i>{{'INSURANCE.SECTION_TEXT2'|translate}}</i>
                      </div>
                    </div> -->
                    <div class="scroll-area">
                      <div class="col overflow-auto">
                        <div class="d-flex justify-content-between mb-1">
                          <table class="table">
                            <thead style="color: #323091;">
                              <tr>
                                <th text-align="left" width="40%">{{'INSURANCE.COLUMN_HEADING_NAME' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_SIZE' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_DATE_CREATED' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_TYPE' | translate}}</th>
                                <th>{{'INSURANCE.COLUMN_HEADING_ACTIONS' | translate}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let document of DocumentList">
                                <td>
                                  {{document.DocumentOldName}}
                                </td>
                                <td>
                                  {{ document.Size }}
                                </td>
                                <td>
                                  {{document.FileCreatedDate | date: 'dd/MM/yyyy' }}
                                </td>
                                <td>
                                  {{ document.FileType }}
                                </td>
                                <td nowrap>
                                  <button type="button" class="btn btn-raised btn-success btn-sm mr-50" ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_BUTTON_DOWNLOAD'|translate}}"
                                    (click)="onDownloadDocument(document)" rippleEffect>
                                    <i data-feather="download"></i>
                                  </button>
                                  <button type="button" class="btn btn-raised btn-danger btn-sm mr-50" ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_BUTTON_DELETE'|translate}}"
                                    (click)="onDeleteDocument(document.DocumentId)" rippleEffect>
                                    <i data-feather="trash-2"></i>
                                  </button>
                                  <a href={{document.DocumentPath}} target="_blank"><button type="button" ngbTooltip="{{'INSURANCE.TOOLTIP_TEXT_BUTTON_VIEW'|translate}}"
                                    class="btn btn-raised btn-warning btn-sm" rippleEffect>
                                    <i data-feather="eye"></i>
                                </button></a>
                                </td>
                              </tr>
                            </tbody>

                          </table>
                        </div>
                        <div id="">
                          <button type="button" class="btn mr-1 btn-success mb-1 mb-sm-0"
                            (click)="onDownloadAllDocument() || async"
                            ngbTooltip="{{'INSURANCE.BUTTON_TEXT_DOWNLOAD_ALL' | translate}}" rippleEffect>
                            <span data-feather="download" class="mr-25"></span> {{'INSURANCE.BUTTON_TEXT_DOWNLOAD_ALL' |
                            translate}}
                          </button>
                          <button type="button" class="btn btn-danger mb-1 mb-sm-0"
                            (click)="onDeleteAllDocument() || async"
                            ngbTooltip="{{'INSURANCE.BUTTON_TEXT_REMOVE_ALL' | translate}}" rippleEffect>
                            <span data-feather="trash-2" class="mr-25"></span> {{'INSURANCE.BUTTON_TEXT_REMOVE_ALL' |
                            translate}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-1"></div>
            </div>
          </div>
        </div>
      </section>
    </form>

  </div>
</div>
<div class="card-footer d-flex">
  <div class="align">
    <button type="submit" *ngIf="!IsForEdit&&tab<4" class="btn btn-primary mr-1" [disabled]="loading" placement="top"
        ngbTooltip="{{'INSURANCE.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}" (click)="(onSubmit(false))" ><span
        *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{'INSURANCE.BUTTON_TEXT_SAVE_AND_NEXT'|translate}}
      </button>

    <button type="submit" *ngIf="!IsForEdit" (click)="onSubmit(true)" class="btn btn-outline-primary" placement="top"
      ngbTooltip="{{'INSURANCE.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}">{{'INSURANCE.BUTTON_TEXT_SAVE_AND_CLOSE'|translate}}</button>

    <button type="submit" *ngIf="IsForEdit" class="btn btn-primary" [disabled]="loading" placement="top"
      ngbTooltip="{{'INSURANCE.BUTTON_TEXT_SAVE_CHANGES' | translate}}" (click)="onSubmit(true)"><span *ngIf="loading"
      class="spinner-border spinner-border-sm mr-1"></span>{{'INSURANCE.BUTTON_TEXT_SAVE_CHANGES'|translate}}</button>

    <button type="button" *ngIf="(tab<2||IsForEdit)&&!IsForAbandon" class="btn btn-outline-secondary ml-2" placement="top" (click)="onCloseButtonClick()"
      ngbTooltip="{{'INSURANCE.BUTTON_TEXT_CANCEL'|translate}}">{{'INSURANCE.BUTTON_TEXT_CANCEL'|translate}}
    </button>

      <button type="button" *ngIf="!IsForEdit&&tab>=2 || IsForAbandon" (click)="OnInsuranceDeleteClick()" class="btn btn-outline-danger ml-1" placement="top"
        ngbTooltip="{{'INSURANCE.BUTTON_TEXT_ABANDON'|translate}}">{{'INSURANCE.BUTTON_TEXT_ABANDON'|translate}}
      </button>
  </div>
  <div class="align" *ngIf="uploaderWarning">Attachment Uploaded:{{uploadCount}}/{{uploader?.queue?.length}}</div>
</div>