import { ThrowStmt } from '@angular/compiler';
import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'app/auth/service';
import { Constant } from 'app/Classes/constant';
import { RecordsType, Status } from 'app/enums';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Observable, Subscription } from 'rxjs';
import { MyImageGalleryService } from 'app/my-image-gallery/my-image-gallery.service';
import { E } from 'assets/extensions/extensions';
import { FileData, FileManagerComponent, ToolbarItemModel, ToolBarItems } from '@syncfusion/ej2-angular-filemanager';
import { LightGallery } from 'lightgallery/lightgallery';
import Swal from 'sweetalert2';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { ToastrService } from 'ngx-toastr';
import { DeleteInputModel, DeleteMultipleInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FailureEventArgs } from '@syncfusion/ej2-filemanager';
import { TblUserFileManagerDocuments, TblUserFolders } from 'app/filemanager/filemanagermodel.model';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbnail from 'lightgallery/plugins/thumbnail';
import autoPlay from 'lightgallery/plugins/autoplay';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import { WebSocketService } from 'app/Services/web-socket.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FileManagerAllModule } from '@syncfusion/ej2-angular-filemanager';
import { registerLicense } from '@syncfusion/ej2-base';
import { DocumentLinkingInputModel } from 'app/quick-post/quick-post-model.model';
@Component({
  selector: 'app-select-sync-file-manager',
  templateUrl: './select-sync-file-manager.component.html',
  styleUrls: ['./select-sync-file-manager.component.scss']
})
export class SelectSyncFileManagerComponent implements OnInit {
  @ViewChild('advancedfilemanager')
  public fileManagerObj!: FileManagerComponent;
  @ViewChild('Gallery') lightGallery: LightGallery;
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedFolderIds: number[] = [];
  selectedUploadIds = [];
  deleteInput: DeleteMultipleInputModel;
  filemanagerinputmodel: TblUserFolders;
  public attachmentlist: any = [];
  public attachmentlistimage: any = [];
  public needRefresh: boolean = false;
  _data: any;
  loading: boolean = false;
  cache: { [key: string]: string } = {};
  currentPath: string = "/";
  uploadCount = 0;
  private subDeleteSchoolAtt$?: Subscription;
  private subDownloadAddressAtt$?: Subscription;
  public downloadFileInputModel: DownloadFileInputModel;
  folders;
  error: string = "";
  galleryRefresh: LightGallery;
  backFlag: boolean = false;
  folderId: number;
  update: boolean = false;
  public fileData: FileData[];
  public _ImageUrlAPI: string = Constant.API + 'api/UserFileManager/UploadUserFileManagerDocuments';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderAddress",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm", "flv"]  // "doc", "docx", "xls", "xlsx", "ppt","pdf"
  });
  // public toolbarSettings = {
  //   items: ['NewFolder', 'Upload', 'Delete', 'Rename', 'SortBy', 'Refresh'] as ToolBarItems[]
  // };
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager: false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide: false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };
  public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  UserSchoolDocument: number[];
  public ajaxSettings: object = {
    url: `${Constant.API}api/UserFolder/GetUserFolderData`,
    getImageUrl: `api/UserFileManager/UploadUserFileManagerDocuments`,
    uploadUrl: `${Constant.API}api/UserFolder/CreateUpdateUserFolder`,
    downloadUrl: `${Constant.API}api/UserFolder/GetUserFolderData`
  };
  fileSettings;
  folderName;
  toolbarItems: [
    { name: '', prefixIcon: 'e-menu filemanagermenu', tooltipText: 'Toggele Menu' },
    // { name: 'NewFolder' },
    { name: 'SortBy' },
    { name: 'Refresh' },
    { name: 'Copy' },
    { name: 'Paste' },
    { name: 'Selection' },
    { name: 'View' },
    { name: '', align: 'Right', prefixIcon: 'e-show-hide-panel', tooltipText: 'Preview Pane' },
  ]
  public toolbarSettings: Object = {
    visible: true,
    items: [

      'SortBy',
      'Refresh',
      // 'Selection',
      'View',
      'Details',
    //  'Rename',
    //  'Delete',
      // 'Download',
      'SelectAll',
      // 'Navigation',
      // 'ItemView',
      // 'FullScreen'
    ]
  };

  public dragAndDropSettings = {
    targetId: 'fileManager', // Define the drop target for the drag event
    dropArea: '#customDropArea', // Optional: Create a custom drop area
    enableDrag: true, // Enable dragging
    enableDrop: true, // Enable dropping
  };

  public contextMenuSettings: Object = {
    file: ['Open', 'Details'],
    folder: ['Open', 'Details'],
    layout: ['Open', 'Details']
  }
  paneYn: boolean = true;
  public hasBaseDropZoneOver: boolean = false;
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  page = 0;
  constructor(private _title: Title,
    private globalService: GlobalService,
    private authenticationService: AuthenticationService,
    private encrDecr: EncrDecrService,
    private loadingScreenService: LoadingScreenService,
    public filemanagerService: MyImageGalleryService,
    private _toastrService: CustomToastrService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _router: Router,
    private webSocketService: WebSocketService,
    private dialogRef: MatDialogRef<SelectSyncFileManagerComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    _title.setTitle('File Manager');
    this.settings.licenseKey = this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey;
    registerLicense('Ngo9BigBOggjHTQxAR8/V1NDaF1cX2hIfEx0THxbf1x0ZFJMYVVbQXdPMyBoS35RckRhWX5ccnFcQ2heVkd3');
    this.folders = [
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T18:16:38.4384894+05:30'),
        filterPath: '',
        hasChild: true,
        isFile: false,
        name: 'File Manager',
        parentId: null,
        size: null,
        type: 'folder'
      }]
      this._data=data;
  }

  ngOnInit(): void {
    this.loadUserFolderData();
    //this.initializeFileManager();
    //  const xhr = new XMLHttpRequest();
    // xhr.open('POST', `${Constant.API}api/UserFolder/GetUserFolderData`, true);

    // // Setting headers
    // xhr.setRequestHeader('Authorization', 'Bearer ' + this.authenticationService.currentUserValue.Token);
    // xhr.setRequestHeader('Content-Type', 'application/json');
    // xhr.setRequestHeader('UserDetailsID', this.authenticationService.currentUserValue.UserDetailsId.toString());

    // // Handling response
    // xhr.onreadystatechange = function () {
    //   if (xhr.readyState === 4) {

    //   }
    // };

    // // Adding a JSON-encoded request body
    // xhr.send(JSON.stringify({
    //   RequestMessageString: this.encrDecr.get(Constant.EncrDecrKey, this.authenticationService.currentUserValue.UserDetailsId.toString())
    // }));
    this.fileData = [
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T18:16:38.4384894+05:30'),
        filterPath: '',
        hasChild: true,
        id: '0',
        isFile: false,
        name: 'Files',
        parentId: null,
        size: 1779448,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '',
        hasChild: false,
        id: '1',
        isFile: false,
        name: 'Documents',
        parentId: null,
        size: 680786,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '',
        hasChild: false,
        id: '2',
        isFile: false,
        name: 'Downloads',
        parentId: null,
        size: 6172,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '',
        hasChild: false,
        id: '3',
        isFile: false,
        name: 'Music',
        parentId: null,
        size: 20,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '',
        hasChild: true,
        id: '4',
        isFile: false,
        name: 'Pictures',
        parentId: null,
        size: 228465,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '',
        hasChild: false,
        id: '5',
        isFile: false,
        name: 'Videos',
        parentId: null,
        size: 20,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Documents\\',
        hasChild: false,
        id: '6',
        isFile: true,
        name: 'EJ2_File_Manager',
        parentId: '1',
        size: 12403,
        type: '.docx',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Documents\\',
        hasChild: false,
        id: '7',
        isFile: true,
        name: 'EJ2_File_Manager',
        parentId: '1',
        size: 90099,
        type: '.pdf',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Documents\\',
        hasChild: false,
        id: '8',
        isFile: true,
        name: 'File_Manager_PPT',
        parentId: '1',
        size: 578010,
        type: '.pptx',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Documents\\',
        hasChild: false,
        id: '9',
        isFile: true,
        name: 'File_Manager',
        parentId: '1',
        size: 274,
        type: '.txt',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Downloads\\',
        hasChild: false,
        id: '10',
        isFile: true,
        name: 'Sample_Work_Sheet',
        parentId: '2',
        size: 6172,
        type: '.xlsx',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Music\\',
        hasChild: false,
        id: '11',
        isFile: true,
        name: 'Music',
        parentId: '3',
        size: 10,
        type: '.mp3',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Music\\',
        hasChild: false,
        id: '12',
        isFile: true,
        name: 'Sample_Music',
        parentId: '3',
        size: 10,
        type: '.mp3',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Videos\\',
        hasChild: false,
        id: '13',
        isFile: true,
        name: 'Demo_Video',
        parentId: '5',
        size: 10,
        type: '.mp4',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Videos\\',
        hasChild: false,
        id: '14',
        isFile: true,
        name: 'Sample_Video',
        parentId: '5',
        size: 10,
        type: '.mp4',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Pictures\\',
        hasChild: false,
        id: '15',
        isFile: false,
        name: 'Employees',
        parentId: '4',
        size: 237568,
        type: 'folder',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Pictures\\Employees\\',
        hasChild: false,
        id: '16',
        isFile: true,
        name: 'Albert',
        parentId: '15',
        size: 53248,
        type: '.png',
        imageUrl:
          'https://ej2.syncfusion.com/demos/src/avatar/images/pic01.png',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Pictures\\Employees\\',
        hasChild: false,
        id: '17',
        isFile: true,
        name: 'Nancy',
        parentId: '15',
        size: 65536,
        type: '.png',
        imageUrl:
          'https://ej2.syncfusion.com/demos/src/avatar/images/pic02.png',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Pictures\\Employees\\',
        hasChild: false,
        id: '18',
        isFile: true,
        name: 'Michael',
        parentId: '15',
        size: 69632,
        type: '.png',
        imageUrl:
          'https://ej2.syncfusion.com/demos/src/avatar/images/pic03.png',
      },
      {
        dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
        dateModified: new Date('2024-01-08T16:55:20.9464164+05:30'),
        filterPath: '\\Pictures\\Employees\\',
        hasChild: false,
        id: '19',
        isFile: true,
        name: 'Robert',
        parentId: '15',
        size: 48951,
        type: '.png',
        imageUrl:
          'https://ej2.syncfusion.com/demos/src/avatar/images/pic04.png',
      },
    ];
    this.manualUpload();
  }

  // sendMessage() {
  //   const message = 'Hello Pushpraj!';
  //   this.webSocketService.sendMessage(message);
  // }

  fileOverBase(event: boolean): void {
    if (event == true)
      this.hasBaseDropZoneOver = event;
    if (this.hasBaseDropZoneOver == false && this.uploader.queue.length > 0) {
      this.uploader.uploadAll();
    }
  }

  manualUpload() {
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserFolderID', value: E.ConvertToString(this.folderId) }]
      });
      return fileItem;
    };
    this.uploader.response.subscribe(async result => {
      if (result) {
        this.loadingScreenService.startLoading();
        if (this.uploader.progress == 100) {
          this.modalService.dismissAll();
          setTimeout(() => {
            this.uploader.clearQueue()
          }, 500);
          this.loadingScreenService.stopLoading();
          this.uploadCount = 0;
          this.GetUserFolderById();
          this._toastrService.Success('File Uploaded.', Constant.SuccessToastrTitle);
        }
      }
      if (result) {
        let _result = JSON.parse(result);
        this.loadingScreenService.startLoading();
        if (_result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data))
          this.uploadCount = this.uploadCount + 1;
          //   if (_attachmentlist) {
          //     if(_attachmentlist.FileType.toLocaleLowerCase() === "images"){
          //     const imageDocs = _attachmentlist
          //     this.AddItemToGallery1(imageDocs.DocumentId, imageDocs.DocumentPath, imageDocs.DocumentOldName, imageDocs.Size, imageDocs.FileType,imageDocs.CreatedDate, imageDocs.CreatedTime)
          //    }
          //   else if(_attachmentlist.FileType.toLocaleLowerCase() === "videos"){
          //     const videoDocs = _attachmentlist
          //         this.AddItemToGallery(videoDocs.DocumentId, videoDocs.DocumentPath, videoDocs.DocumentOldName, videoDocs.Size, videoDocs.FileType,videoDocs.CreatedDate, videoDocs.CreatedTime)
          //     }
          //     this.loadingScreenService.stopLoading();
          //     this.needRefresh = true;

          // }
        }
        else if (_result.status == Status.StorageFull) {
          // this.uploader.destroy();
          this.uploader.cancelAll();
          // this.uploader.removeFromQueue();
          // this.uploader.cancelItem()
          this.uploader.clearQueue()
          this.loadingScreenService.stopLoading();
          Swal.fire({
            title: "Storage Full",
            text: _result.message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText: "Buy More Storage",
            cancelButtonText: "Cancel",
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          })
            .then(
              (async (result) => {
                if (result.value) {

                  this._router.navigate(['/pages/pricing'])
                }
              })
            );

        }
      }
    });
  }


  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if (this.galleryRefresh) {
        this.galleryRefresh.refresh();
      }
      this.needRefresh = false;
    }
  }

  loadUserFolderData(): void {
    if (this.authenticationService.currentUserValue) {
      const UserDetailsID = this.authenticationService.currentUserValue.UserDetailsId.toString();
      this.loadingScreenService.startLoading();
      this.filemanagerService.GetUserFolderData(UserDetailsID).subscribe(
        (result) => {
          if (result.status === Status.Success) {
            const data = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
            this.backFlag = false;
            this.attachmentlist = [];
            const defaultFolder = {
              dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
              dateModified: new Date('2024-01-08T18:16:38.4384894+05:30'),
              filterPath: '',
              hasChild: true,
              isFile: false,
              name: 'File Manager',
              parentId: null,
              size: null,
              type: 'folder'
            };
            this.folders = data.UserFolderListData.map((folder: any) => {
              return {
                ...folder,
                name: folder.UserFolderName,
                size: this.calculateTotalSizeInBytes(folder),
                hasChild: false,
                type: 'folder',
                isFile: false,
                dateModified: folder.ChangedDate
              };
            });
            this.folders.unshift(defaultFolder)
            this.loadingScreenService.stopLoading();
          }
        },
        (error) => {
          this.loadingScreenService.stopLoading();
        }
      );
    }
  }

  calculateTotalSizeInBytes(data): number {
    let totalSize = 0;
    if (data.TblUserFileManagerDocuments) {
      data.TblUserFileManagerDocuments.forEach(document => {
        totalSize += document.SizeInBytes || 0; // Add only if SizeInBytes exists
      });
    }
    return totalSize;
  }

  onFolderOpen(event: any) {
    const folderId = event.fileDetails.UserFolderId;
    this.currentPath = event.fileDetails.UserFolderName
    if (event.fileDetails.UserFolderName) {
      this.folderId = folderId;
      this.GetUserFolderById();
      this.paneYn = false;
      this.toolbarSettings = {
        visible: true,
        items: [
          'Back',
         // 'Upload',
          'SortBy',
          'Refresh',
         // 'Delete',
          //'Download',
          'SelectAll',
          'View',
          'Details'
        ]
      };
    }
    else {
      this.openUploaderModal(event.fileDetails.DocumentId, 'lele')
    }
  }


  //-------------------------------------------------------------------------------------------------------Folder open end-----------------------------------------------------------------

  async onToolbarClick(args: any) {
    if (args.item.properties.id === 'filemanager_0_tb_upload') {
      args.cancel = true;
      if (this.folderId) {
        this.fileInput.nativeElement.click()
      }
    }
    if (args.item.properties.id === 'filemanager_0_tb_download') {
      args.cancel = true;
      if (args.fileDetails.length > 0) {
        args.fileDetails.forEach(element => {
          this.onDownloadDocument(element);
        });
        args.cancel = true;
      }
    }
    if (args.item.properties.id === 'filemanager_0_tb_delete') {
      args.cancel = true;
      this.selectedFolderIds = [];
      if (args.fileDetails[0].DocumentId) {
        args.fileDetails.forEach(element => {
          this.selectedFolderIds.push(element.DocumentId)
        });
        await this.onDeleteDocument(args.fileDetails[0].DocumentId);
      }
      else {
        args.fileDetails.forEach(element => {
          this.selectedFolderIds.push(element.UserFolderId)
        });
        await this.OnMultipleFolderDeleteClick();
      }
    }
    if (args.item.properties.id === 'filemanager_0_tb_newfolder') {
    }
    if (args.item.properties.id === 'filemanager_0_tb_rename') {
      const folderId = args.fileDetails[0].UserFolderId;
      this.folderId = folderId;
    }
    if (args.item.properties.id === "filemanager_0_tb_back") {
      this.backFlag = false;
      const files = this.fileManagerObj.getSelectedFiles() as SelectedFile[];
    this.selectedUploadIds = []; // Clear previous selection if needed

    files.forEach(file => {
      if (file.DocumentId) { // Check if DocumentId exists
        this.selectedUploadIds.push(file.DocumentId);
      }
    });
   if (this.selectedUploadIds.length >= 1) {
      this.backFlag = true;
    }
      if (this.backFlag) { 
        Swal.fire({
          title: "Some Selected Files",
          text: 'You have some selected files, Are you sure to go back!',
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: "Yes",
          cancelButtonText: 'Cancel',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
        .then(
          (async (result) => {
            if (result.value) {
              this.loadUserFolderData();
              this.paneYn = true;
              if (this.lightGallery) {
                this.lightGallery.galleryItems = [];
                this.galleryRefresh.galleryItems = [];
              }
              this.toolbarSettings = {
                visible: true,
                items: [
                  //  'NewFolder',
                  'SortBy',
                  'Refresh',
                //  'Rename',
                 // 'Delete',
                  'SelectAll',
                  'View',
                  'Details'
                ]
              };
            }
          })
        );
      }
      else {
        this.loadUserFolderData();
        this.paneYn = true;
        if (this.lightGallery) {
          this.lightGallery.galleryItems = [];
          this.galleryRefresh.galleryItems = [];
        }
        this.toolbarSettings = {
          visible: true,
          items: [
            //  'NewFolder',
            'SortBy',
            'Refresh',
          //  'Rename',
          //  'Delete',
            'SelectAll',
            'View',
            'Details'
          ]
        };
      }

    }
    if (args.item.properties.id === "filemanager_0_tb_refresh") {
      // console.log(args)
      args.cancel = true;
      if (args.fileDetails[0].DocumentId) {
        //    this.GetUserFolderById();

      }
      //  else { this.loadUserFolderData(); }
    }
  }

  //--------------------------------------------------------------------------toolbar click ----------------------------------------------------------------------------------------

  onFileRemove(args): void {
    // Add your custom logic here
    alert('Files/Folders deleted: ' + args.name);
  }

  async onMenuClick(args: any): Promise<void> {
    if (args.item.properties.id === 'filemanager_0_cm_upload') {
      args.cancel = true;
      if (this.folderId) {
        this.fileInput.nativeElement.click();
      }
      //  this.customUploadHandler(); // Trigger custom upload logic
    }
    if (args.item.properties.id === 'filemanager_0_cm_download') {
      args.cancel = true;
      if (args.fileDetails.length > 0) {
        args.fileDetails.forEach(element => {
          this.onDownloadDocument(element);
        });
        args.cancel = true;
      }
    }
    if (args.item.properties.id === 'filemanager_0_cm_delete') {
      args.cancel = true;
      if (args.fileDetails[0].DocumentId)
        await this.onDeleteDocument(args.fileDetails[0].DocumentId);
      else {
        args.fileDetails.forEach(element => {
          this.selectedFolderIds.push(element.UserFolderId)
        });
        await this.OnMultipleFolderDeleteClick();
      }
      //args.cancel = true; 
    }
    if (args.item.properties.id === 'filemanager_0_cm_newfolder') {
    }
    if (args.item.properties.id === 'filemanager_0_cm_rename') {
      const folderId = args.fileDetails[0].UserFolderId;
      this.folderId = folderId;
    }
    if (args.item.properties.id === "filemanager_0_cm_refresh") {
      console.log(args)
      args.cancel = true;
      if (args.fileDetails[0].DocumentId) {
        this.GetUserFolderById();

      }
      else { this.loadUserFolderData(); }
    }
  }

  //---------------------------------------------------------------------Menu click end ---------------------------------------------------------------------------------------

  onFileCreated(args) {
    // Triggered after the folder is created
    if (args.status === 'success') {
      alert("Folder has been created successfully!");
    } else {
      console.error("Error creating folder:", args.error);
    }
  }

  openUploaderUpload() {
    this.uploader.uploadAll();
  }

  loadPage(event, filetype) {
    // this.savePosition();
    this.pageAdvancedEllipses
    //  this.getAttachmentList(filetype,event)
    this.GetUserFolderById(event)
    // setTimeout(() => this.restorePosition(), 0);
  }

  GetUserFolderById(page?) {
    this.loadingScreenService.startLoading();
    this.attachmentlist = []
    this.page = page
    var getMyAttachmentsInputModel = {
      RecordId: this.folderId,
      Page: this.page,
      Pagesize: 80,
      FileType: 'Images',
      //  QuickPostType:QuickPostType.Public,
      GetMetaDataYN: false
    }

    this.filemanagerService.GetUserFilesByFolderId(getMyAttachmentsInputModel).subscribe(async (result) => {
      if (result.status == Status.Success) {
        let data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
        let _attachmentlist = data;
        this.loadingScreenService.stopLoading();
        if (_attachmentlist[0]?.TotalNumberOfRecords > 0)
          this.pagesNumber = _attachmentlist[0]?.TotalNumberOfRecords;
        this.loadLightGallery(_attachmentlist)
        const defaultFolder = {
          dateCreated: new Date('2023-11-15T19:02:02.3419426+05:30'),
          dateModified: new Date('2024-01-08T18:16:38.4384894+05:30'),
          filterPath: '',
          hasChild: true,
          isFile: false,
          name: this.currentPath ? this.currentPath : 'File Manager',
          parentId: null,
          size: null,
          type: 'folder'
        };
        this.folders = _attachmentlist.map((folder: any) => {
          return {
            ...folder,
            name: folder.DocumentOldName,
            size: folder.SizeInBytes,
            isFile: true,
            hasChild: false,
            dateModified: folder.CreatedDate,
            filterPath: folder.UserFolderId,
            imageUrl: folder.DocumentPath,
            url: folder.DocumentPath,
            type: this.extractType('.' + folder.DocumentPath),
            //  parentId:folder.UserFolderId+'p',
          };
        });
        this.folders.unshift(defaultFolder)
      }
      this.loadingScreenService.stopLoading();
    },
    );
  }

  extractType(geturl) {
    const url = geturl;
    const extension = url.split('.').pop(); // Get the part after the last '.'
    return extension
  }

  async loadLightGallery(_attachmentlist) {
    // if(this.galleryRefresh){
    //   this.galleryRefresh.init();
    // }
    // if (_attachmentlist) {
    //       const imageDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "images")
    //       const videoDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "videos")
    //       const imagePromises = imageDocs.map( doc =>
    //           this.AddItemToGallery1(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType,doc.CreatedDate, doc.CreatedTime)
    //       );
    //        await Promise.all(imagePromises);
    //       const videoPromises = videoDocs.map( doc =>
    //           this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType,doc.CreatedDate, doc.CreatedTime)
    //       );

    //       await Promise.all(videoPromises);
    //       this.needRefresh = true;

    if (_attachmentlist) {
      _attachmentlist.forEach(async (doc) => {
        if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
          //this.DocumentList.push(doc);
        }
        else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
          await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.DocumentSubType, doc.CreatedDate, doc.CreatedTime);
          this.needRefresh = true;
        }
      });

    }
  }


  formatFolderData(data: any) {
    return data.map((folder: any) => {
      return {
        name: folder.UserFolderName,  // Folder name
        type: 'folder',               // Folder type
        hasChild: true, // Check if there are documents inside
        size: 10,                      // No size for folders
        dateModified: folder.ChangedDate, // Use the last modified date
        id: folder.UserFolderId       // Folder ID (optional but helpful for your context)
      };
    });
  }
  onFileManagerCreated() {
    // let fileManager = document.querySelector('#advancedfilemanager') as any;
    // if (fileManager) {
    //   // Navigate to the root or any specific folder
    //   fileManager.navigateTo('');
    // }
  }

  initializeFileManager(): void {
    if (this.authenticationService.currentUserValue) {
      const UserDetailsID = this.authenticationService.currentUserValue.UserDetailsId;
      this.ajaxSettings = {
        url: `${Constant.API}api/UserFolder/GetUserFolderData`,
        beforeSend: (args: any) => {
          args.ajaxSettings.beforeSend = (xhr: XMLHttpRequest) => {
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.authenticationService.currentUserValue.Token);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('UserDetailsID', this.authenticationService.currentUserValue.UserDetailsId.toString());
          };
          args.ajaxSettings.data = JSON.stringify({
            RequestMessageString: this.encrDecr.get(Constant.EncrDecrKey, UserDetailsID.toString()) // Encrypt UserDetailsID as needed
          });
        }
      };
    }
  }

  openUploaderModal(item, content) {
    setTimeout(() => {
      const index = this.attachmentlist.findIndex((el) => el.id === item);
      if (this.galleryRefresh)
        this.galleryRefresh.openGallery(index);
    }, 0);
  }

  refreshFileManager() {
    this.fileManagerObj.refreshFiles();
  }

  // async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
  //   this.attachmentlistimage = [
  //     ...this.attachmentlistimage,
  //     {
  //       id: _id,
  //       size: documentsize,
  //       src: _url,
  //       data: documentname,
  //       thumb: _url,
  //       subHtml: documentname, documentsize,
  //       type: filetype,
  //       name: documentname,
  //       createdDate:CreatedDate,
  //       createdTime:CreatedTime
  //     }
  //   ];
  //   this.needRefresh=true;
  // }

  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string, CreatedDate: string, CreatedTime: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif',
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate: CreatedDate,
      createdTime: CreatedTime
    };
    this.attachmentlist.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache

    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }

    const galleryItem = {
      id: _id,
      size: documentsize,
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name: documentname,
      createdDate: CreatedDate,
      createdTime: CreatedTime
    };
    const index = this.attachmentlist.findIndex(item => item.id === _id);
    this.attachmentlist[index] = galleryItem;
  }


  async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
    if (type === 'Videos') {
      return new Promise<string>((resolve, reject) => {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous'; // Enable cross-origin for video
        video.src = videoURL;

        video.addEventListener('loadedmetadata', () => {
          video.currentTime = 10.5; // Adjust as needed

          video.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            const scaleFactor = 0.5; // Adjust the scale factor as needed
            canvas.width = video.videoWidth * scaleFactor;
            canvas.height = video.videoHeight * scaleFactor;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Draw a play icon in the center of the canvas
            const playIconSize = 125; // Adjust the size as needed
            ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
            ctx.beginPath();
            ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
            ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
            ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
            ctx.closePath();
            ctx.fill();

            // Resolve with the data URL of the canvas
            resolve(canvas.toDataURL('image/jpeg', 0.5)); // Adjust quality parameter as needed
            this.loading = false;
            // this.needRefresh=true;
            this.loadingScreenService.stopLoading();
            // Clean up
            canvas.remove();
            video.remove();
          });

          video.addEventListener('error', (error) => {
            resolve('assets/images/pages/Cannot_play.png');
            this.loading = false;
            this.loadingScreenService.stopLoading();
            //  reject(`Error loading video: ${error.message}`);
          });
        });

        video.addEventListener('error', (error) => {
          resolve('assets/images/pages/Cannot_play.png');
          //  reject(`Error loading video metadata: ${error.message}`);
          this.loading = false;
          this.loadingScreenService.stopLoading();
        });

        // Load the video
        video.load();
      });
    }
    return Promise.resolve(''); // Return a default value for non-video types
  }


  async generateVideoData(src): Promise<string> {
    const videoData = {
      source: [{ src: src, type: 'video/mp4' }],
      attributes: { preload: false, controls: false }
    };
    return JSON.stringify(videoData);
  }

  onInitGallery = (detail): void => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
      <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    const customDownloadButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" title="Download" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customDeleteButton = `<button id="lg-custom-button-delete" style="padding-top:8px" ngbTooltip="tag" class="svg-icon lg-icon lg-group"><img class="svg-icon" title="Delete" style="height:28px;" src="assets/images/icons/trash.png"></button>`;
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customHelpButton);
    $toolbar.append(customDeleteButton);
    $toolbar.append(customDownloadButton);
    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunction();
    });
    document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
      await this.onDeleteDocument(idname.alt)
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, id) => id !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
      //detail.instance.refresh();
    })
    document.getElementById("lg-custom-button-download").addEventListener("click", () => {
      var idname = detail.instance.galleryItems[detail.instance.index];

      var downloadDocument2: TblUserFileManagerDocuments = new TblUserFileManagerDocuments;
      if (idname.src) {
        downloadDocument2.DocumentPath = idname.src;
      }
      else if (idname.video) {
        downloadDocument2.DocumentPath = JSON.parse(idname.video).source[0].src;
      }
      downloadDocument2.DocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument2)
    });
  };

  async onDownloadDocument(document) {
    if (document) {
      await this.DownloadMyAddressDocument(document);
    }
  }
  async DownloadMyAddressDocument(Document, IsDownloadAll = false) {
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.DocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      var path = 'api/UserFileManager/DownloadUserFileManagerDocuments';
      this.subDownloadAddressAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = 'Connection Refused';
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  async onDeleteDocument(userschoolDocumentId: number) {
    if (!userschoolDocumentId) {
      return;
    }

    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteMySchoolDocument([userschoolDocumentId]);

      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }

  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: 'Are you sure, you want to delete this Document?',
        text: "You won't be able to undo this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }

  async DeleteMySchoolDocument(UserSchoolDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
    if (this.selectedFolderIds.length > 0) {
      UserSchoolDocumentIds = this.selectedFolderIds;
    }
    try {
      if (UserSchoolDocumentIds.length < 1) {
        return false;
      }

      this.deleteDocumentsInputModel = [];
      this.UserSchoolDocument = UserSchoolDocumentIds
      UserSchoolDocumentIds.forEach((userschoolDocumentId) => {
        this.deleteDocumentsInputModel.push({
          DeleteID: userschoolDocumentId,
          UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
        });
      });

      const result = await this.postDataAndHandleResponse();

      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }

  private postDataAndHandleResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserFolder/DeleteUserFolderDocuments';
      this.subDeleteSchoolAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));

          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            this.GetUserFolderById()
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }

  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted!',
      text: E.ConvertToString(result.message),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });

    // if(this.dataType=="Videos"){
    //   const indexToRemove: number = this.attachmentlist.findIndex((item) => item.id == this.UserSchoolDocument);
    //   if (indexToRemove !== -1) {
    //     this.attachmentlist.splice(indexToRemove, 1);
    //   }
    //   const indexToRemovenew: number = this.attachmentlist.findIndex((item) => item.id == this.UserSchoolDocument);
    //   if (indexToRemove !== -1) {
    //     this.attachmentlist.splice(indexToRemovenew, 1);
    //   }}
    //   else if(this.dataType=="Images"){
    //     const indexToRemove: number = this.attachmentlistimage.findIndex((item) => item.id == this.UserSchoolDocument);
    //     if (indexToRemove !== -1) {
    //       this.attachmentlistimage.splice(indexToRemove, 1);
    //     }
    //     const indexToRemovenew: number = this.attachmentlistimage.findIndex((item) => item.id == this.UserSchoolDocument);
    //     if (indexToRemove !== -1) {
    //       this.attachmentlistimage.splice(indexToRemovenew, 1);
    //     }
    //   }
  }

  private handleFailure(result: any): void {
    if (result.message == "Files couldn't delete, Linked with other forms.") {
      this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
    }
    else {
      this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
    }
  }

  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    //  this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }

  // onTabActive(data){
  //   this.dataType=data
  // }


  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/view-only-custom.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })
  }

  onFailure(args) {
    args.cancel = true;
  }

  // Fetch folder contents when a folder is expanded in the navigation pane
  onFolderExpand(args: any) {
    if (this.fileManagerObj)
      this.fileManagerObj.root = "'/'"
    if (args.data && args.data.hasChild) {
      //  const folderId = args.data.id; // Get the folder ID or path from args.data
      // this.fetchFolderContents(folderId);
    }
  }

  async OnMultipleFolderDeleteClick() {
    Swal.fire({
      title: 'Are you sure, you want to delete this Folder and all the attachments associated with it?',
      text: "You won't be able to undo this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const a = await this.DeleteMultipleFolder();
          }
        })
      );
  }

  async DeleteMultipleFolder() {
    this.loadingScreenService.startLoading();
    var deleteid = this.selectedFolderIds
    this.deleteInput = {
      DeleteIDs: deleteid,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      let response: Observable<any>;
      this.filemanagerService.DeleteUserFolder(this.deleteInput)
        .subscribe(
          (data) => {
            this.loadingScreenService.stopLoading();
            var result = JSON.parse(JSON.stringify(data));
            if (result.status == Status.Success) {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: E.ConvertToString(result.message),
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
              this.loadUserFolderData();
              // this.folderTitle= this.currentAppLanguage.data.FILEMANAGER.LABEL_CREATE_FOLDER
              // this.selectedFolderIds = [];
              Result = true;
            }
            else {
              this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
              Result = false;
            }
            resolve(Result);
          },
          (error) => {
            this._toastrService.Error(error, Constant.ErrorToastrTitle);
            this.error = 'Could not connect with server.';
            this.loadingScreenService.stopLoading();
          }
        );
    });
    return await promise;
  }

  popUpClose(args) {
    if (args.popupName === "Error") {
      args.cancel = true;
      
     }
    if (args.popupName == 'Create Folder') {
      this.folderName = args.folderName;
      this.CreateUpdateUserFolder();
    }
    if (args.name == 'folderCreate') {
      this.folderName = args.folderName;
      this.CreateUpdateUserFolder();
    }
    if (args.name == 'rename') {
      this.update = true;
      this.folderName = args.newName;
      this.CreateUpdateUserFolder();
    }
    if (args.popupName === "Upload") {
      args.cancel = true;

    }
    if (args.popupName === "Image Preview") {
      args.cancel = true;
    }
  }

  onNavigate(args) {
    // Handle navigation event here

    // Perform actions based on the new path, such as fetching data, updating UI, etc.
  }


  CreateUpdateUserFolder() {
    // if(this.folderName.length>20){
    //  this.maxlength=true;
    //  this.Required=false;
    //  return;
    // }
    // else if(!this.folderName){
    //  this.Required=true;
    //  this.maxlength=false;
    //  return;
    // }


    if (this.update) {
      this.filemanagerinputmodel = {
        UserFolderId: this.folderId,
        UserFolderName: this.folderName,
        UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId,
        CreatedDate: E.GetTodayDateStringOnly(),
        CreatedTime: E.GetTodayTimeStringOnly(),
        ChangedDate: E.GetTodayDateStringOnly(),
        ChangedTime: E.GetTodayTimeStringOnly(),
        ActiveYn: true
      }
    }
    else if (!this.update) {
      this.filemanagerinputmodel = new TblUserFolders();
      this.filemanagerinputmodel = {
        UserFolderId: 0,
        UserFolderName: this.folderName,
        UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId
      }
    }
    if (this.authenticationService.currentUserValue && this.folderName) {
      this.loadingScreenService.startLoading();
      let response: Observable<any>;
      this.filemanagerService.CreateUpdateUserFolder(this.filemanagerinputmodel)
        .subscribe(
          (result) => {
            if (result.status == Status.RecordAdded) {
              let data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
              this.modalService.dismissAll();
              this.update = false;
              this.loadingScreenService.stopLoading();
              setTimeout(() => {
                let msg = 'Folder Added successfully.';
                this._toastrService.Success(msg, Constant.SuccessToastrTitle);
                this.loadUserFolderData();
              }, 500);
            }
            else if (result.status == Status.RecordUpdated) {
              let data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
              this.modalService.dismissAll();
              this.loadingScreenService.stopLoading();
              this.update = false;
              setTimeout(() => {
                let msg = 'Folder Updated successfully.';
                this._toastrService.Success(msg, Constant.SuccessToastrTitle);
                this.loadUserFolderData();
              }, 500);
            }
            else if (result.status == Status.Error) {
              let data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
              this.loadingScreenService.stopLoading();
              this.update = false;
              setTimeout(() => {
                this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
                this.loadUserFolderData();
              }, 500);
            }
            else { this.loadingScreenService.stopLoading(); }
          },
        );
    }
  }

  onCloseButtonClick() {
    this.dialogRef.close(false);
  }


  //--------------------------------------------------------------folder create -------------------------------------------------------------------------------------

  onFileSelection(args: any) {
    // console.log(args, '------------------file selection');
  }
  onFileSelect(args: any) {
    // console.log(args, '-----------------------file select');
  }
  updateDoc() {
    const files = this.fileManagerObj.getSelectedFiles() as SelectedFile[];
    this.selectedUploadIds = []; // Clear previous selection if needed

    files.forEach(file => {
      if (file.DocumentId) { // Check if DocumentId exists
        this.selectedUploadIds.push(file.DocumentId);
      }
    });
    if (this.selectedUploadIds.length < 1) {
      /// no files selected 
      this._toastrService.Error('No File selected!', Constant.ErrorToastrTitle);
    }
    else if (this.selectedUploadIds.length >= 1) {
      this.backFlag = true;
      var getMyAttachmentsInputModel:DocumentLinkingInputModel = {
          RecordId: this._data.RecordId,
          DocumentIds: this.selectedUploadIds,
          UserDetailsId: this.authenticationService.currentUserValue.UserDetailsId,
          RecordType:this._data.RecordType
        }
        this.documentLinked(getMyAttachmentsInputModel);
    }

  }

  documentLinked(id){
    this.loadingScreenService.startLoading();
    this.filemanagerService.CreateUpdateDocumentLinks(id).subscribe(async (result) => {
          if (result.status == Status.Success) {
            //let data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            this.dialogRef.close(true);
            this.loadingScreenService.stopLoading();
          }
          this.loadingScreenService.stopLoading();
        },
      );
  }

}
interface SelectedFile {
  DocumentId: number;
  // Add other properties as needed
}
