import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { locale as english } from 'app/mypastimes/i18n/en';
import { locale as french } from 'app/mypastimes/i18n/fr';
import { locale as german } from 'app/mypastimes/i18n/de';
import { locale as portuguese } from 'app/mypastimes/i18n/pt';
import { locale as gujarati } from 'app/mypastimes/i18n/gj';
import { locale as hindi } from 'app/mypastimes/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'app/Classes/constant';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { Observable, Subject, Subscription } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HobbyDocumentListOutputModel, HobbyLoadDataInputModel, UserHobbyModel } from '../pastime-model.model';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { PasttimesService } from '../pasttimes.service';
import { E } from 'assets/extensions/extensions';
import { RecordsType, Status } from 'app/enums';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DeleteInputModel, DeleteMultipleInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { GlobalService } from 'app/Services/global.service';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbnail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import autoPlay from 'lightgallery/plugins/autoplay';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { LightGallery } from 'lightgallery/lightgallery';
import { GeneralService } from 'app/Services/general.service';
import { SelectSyncFileManagerComponent } from 'app/sync-file-manager/select-sync-file-manager/select-sync-file-manager.component';
@Component({
  selector: 'app-my-hobbies',
  templateUrl: './my-hobbies.component.html',
  styleUrls: ['./my-hobbies.component.scss']
})
export class MyHobbiesComponent implements OnInit {
  @ViewChild('nav') nav: NgbNav;
  @ViewChild('hobby') hobby: TextBoxComponent;
  @ViewChild('notes') notes: ElementRef;
  @ViewChild('fileInput') fileInput;
  @ViewChild('AddressGallery') lightGallery!: LightGallery;
  galleryRefresh:LightGallery;
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager:false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };

  private needRefresh = false;
  _ImageUrlAPI: string = Constant.API + 'api/UserHobby/UploadUserHobbyDocuments';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderInsurance",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: [ "image", "tiff", "bmp", "png", "jpg", "jpeg", "pdf", "video","mp4", "mpeg", "mov", "avi", "ogv", "webm","flv"]
  });
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  public loading = false;
  public submitted = false;
  public coreConfig: any;
  public dialogTitle: string;
  public IsForEdit: boolean = false;
  public IsForAbandon: boolean = false;
  public error = '';
  public maxToDate: Date = new Date();
  public maxFromDate: Date = new Date();
  currentAppLanguage: any;
  UserAddressDocument: any;
  _data: number = 0;
  characterCount1: number = 0;
  maxChars1: number = 120;
  characterCount2: number = 0;
  maxChars2: number = 1500;
  private _unsubscribeAll: Subject<any>;
  public tab = 1;
  public frmHobby: FormGroup;
  private userHobby: UserHobbyModel;
  hobbyLoadDataInputModel:HobbyLoadDataInputModel;
  public DocumentList: HobbyDocumentListOutputModel[] = [];
  public DocumentList1: HobbyDocumentListOutputModel[] = [];
  public downloadFileInputModel: DownloadFileInputModel;
  private subDownloadAddressAtt$?: Subscription;
  private subDeleteAddressAtt$?: Subscription;
  deleteInputModel: DeleteMultipleInputModel = new DeleteMultipleInputModel();
  deleteInputModel1: DeleteInputModel = new DeleteInputModel();
  infoTooltip:string;
  dateformat: string;
  GalleryItems: any = [];
  GalleryItems1: any = [];
  cache: { [key: string]: string } = {};
  uploadCount=0;
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  page=0;
  timeformat: string;
  dateformat1: string;
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _coreConfigService: CoreConfigService,
    private _translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<MyHobbiesComponent>,
    private loadingScreenService: LoadingScreenService,
    private _toastrService: CustomToastrService,
    private encrDecr: EncrDecrService,
    private pastimeServie: PasttimesService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private generalService : GeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this._unsubscribeAll = new Subject();
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
      }, 100);
    })
    this._data=data;
    this.settings.licenseKey=this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey;
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
    this.dateformat1=this.globalService.getDateFormat();
    this.timeformat = this.globalService.getTimeFormat();
  }

  ngOnInit(): void {
    this.userHobby = new UserHobbyModel;
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    this.dialogTitle = this.currentAppLanguage.data.MYPASTIME.DIALOG_TITLE_ADD_HOBBY;
    this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.TOOLTIP_TEXT_HOBBY_INFORMATION;
    this.frmHobby = this._formBuilder.group({
      DateFrom: [new Date],
      DateTo: [new Date],
      HobbyName: ['',Validators.required],
      ShortDescription: [''],
      LongDescription: [''],
      Notes:['']
    });
    if (typeof this._data !== 'undefined') {
      // console.log('this._data')
      // console.log(this._data)
      if (this._data > 0) {
        this.IsForEdit = true;
        this.dialogTitle = this.currentAppLanguage.data.MYPASTIME.DIALOG_TITLE_EDIT_HOBBY;
      
      setTimeout(() => {
        this.loadingScreenService.startLoading();
        this.hobbyLoadDataInputModel={UserHobbyId:this._data,GetMetaDataYN:false,docYN:false}
        this.pastimeServie.GetUserHobbyLoadData(this.hobbyLoadDataInputModel)
          .subscribe(
            (data) => {
              this.loadingScreenService.stopLoading();
              //  console.log(data);
              if (data.status == Status.Success) {
                let hobbymodel = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)));
                //console.log(hobbymodel)
                this.userHobby=hobbymodel.Data.UserHobby
                if (this.userHobby) {
                  //this.userAwards = this.awardLoadDataOutputModel.UserAward;
                  this.frmHobby.patchValue({
                    HobbyName:this.userHobby.HobbyName,
                  ShortDescription:this.userHobby.ShortDescription,
                  LongDescription:this.userHobby.LongDescription,
                  DateFrom:E.ConvertToDate(this.userHobby.DateFrom),
                  DateTo:E.ConvertToDate(this.userHobby.DateTo),
                  Notes:this.userHobby.Notes
                  });
                  if(this.userHobby.ShortDescription){
                    this.updateCharacterCount1(this.userHobby.ShortDescription)
                  }
                  if(this.userHobby.LongDescription){
                    this.updateCharacterCount2(this.userHobby.LongDescription)
                  }
                }
                if (hobbymodel.Data.HobbyDocumentList) {
                  hobbymodel.Data.HobbyDocumentList.forEach(async(doc) => {
                    if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                      this.DocumentList.push(doc);
                    }
                  else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
                    await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName,doc.Size ,doc.DocumentSubType,doc.CreatedDate, doc.CreatedTime);
                    this.needRefresh = true;
                  }
                });
                }
              }
              else {
                this._toastrService.Error(E.ConvertToString(data.message), Constant.ErrorToastrTitle);
              }
            },
            (error) => {
              this._toastrService.Error(error, Constant.ErrorToastrTitle);
              this.error = error;
              this.loadingScreenService.stopLoading();
            }
          );

      }, 500);
      }
    }

    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      //console.log(this.uploader)
      this.loadingScreenService.startLoading()
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserHobbyID', value: E.ConvertToString(this._data) }]
      });
      //this.uploader.setOptions({ url: fileItem.url + fileItem.file.name });
      return fileItem;
    };
    // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
    //   alert('File uploaded');
    // };
    this.uploader.response.subscribe(result => {
      if (result) {
        this.loadingScreenService.startLoading()
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
          this.uploaderWarning=true;
          this.loadingScreenService.startLoading()
         // console.log(this.uploaderWarning)
      }else if(this.uploader.progress==100){
        this.uploaderWarning=false;
        this.forgotuploadWarning=false;
        //this.uploadCount = 0;
        this.loadingScreenService.stopLoading();
      }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _PastimeDocument = <HobbyDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
          if (_PastimeDocument.DocumentSubType.toLocaleLowerCase() == "documents") {
            this.DocumentList.push(_PastimeDocument);
            this.DocumentList1.push(_PastimeDocument);
          }
          else if (_PastimeDocument.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
            this.AddItemToGallery(_PastimeDocument.DocumentId, _PastimeDocument.DocumentPath, _PastimeDocument.DocumentOldName, _PastimeDocument.Size,_PastimeDocument.DocumentSubType,_PastimeDocument.CreatedDate,_PastimeDocument.ChangedTime);
            this.AddItemToGallery1(_PastimeDocument.DocumentId, _PastimeDocument.DocumentPath, _PastimeDocument.DocumentOldName, _PastimeDocument.Size,_PastimeDocument.DocumentSubType,_PastimeDocument.CreatedDate,_PastimeDocument.ChangedTime);
            this.needRefresh = true;
          }
         
        }
        else if(_result.status == Status.StorageFull){
         // this.uploader.destroy();
          this.uploader.cancelAll();
          // this.uploader.removeFromQueue();
          // this.uploader.cancelItem()
          this.uploader.clearQueue()
          Swal.fire({
            title: "Storage Full",
            text: _result.message,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText: "Buy More Storage",
            cancelButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CANCEL_BUTTON_TEXT,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            }
          })
          .then(
            (async (result) => {
              if (result.value) {
                //this.uploader.clearQueue();
                this.dialogRef.close(true);
                this._router.navigate(['/pages/pricing'])
              }
            })
          );
              
        }
      }
    });

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
  }

  updateCharacterCount1(text: string): void {
    // Update the character count with the length of the input text
    this.characterCount1 = text.length;
    // If character count exceeds the maximum limit, truncate the input
    if (this.characterCount1 > this.maxChars1) {
      text = text.substr(0, this.maxChars1);
      this.characterCount1 = this.maxChars1;
    }
  }
  updateCharacterCount2(text: string): void {
    // Update the character count with the length of the input text
    this.characterCount2 = text.length;
    // If character count exceeds the maximum limit, truncate the input
    if (this.characterCount2 > this.maxChars2) {
      text = text.substr(0, this.maxChars2);
      this.characterCount2 = this.maxChars2;
    }
  }

  
  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      // if (this.lightGallery)
        this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
  }

  onBeforeClose = async (detail) => {
    this.galleryRefresh.refresh();
  }

  onInitGallery = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    //console.log(this.lightGallery)
    //console.log(this.lightGallery.mousewheel)
    const customDeleteButton = `<button id="lg-custom-button-delete" style="padding-top:8px" ngbTooltip="tag" class="svg-icon lg-icon lg-group"><img class="svg-icon" title="Delete" style="height:28px;" src="assets/images/icons/trash.png"></button>`;
    const customDownloadButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" title="Download" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customDeleteButton);
    $toolbar.append(customDownloadButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
     await this.onDeleteDocument(idname.alt)
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, id) => id !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
      //detail.instance.refresh();
    })

    document.getElementById("lg-custom-button-download").addEventListener("click", () => {
      const downloadDocument: HobbyDocumentListOutputModel = new HobbyDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      if(idname.src){
      downloadDocument.DocumentPath = idname.src;}
      else if(idname.video){
        downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
      }
      downloadDocument.DocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };

  onInitGallery1 = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    //console.log(this.lightGallery)
    //console.log(this.lightGallery.mousewheel)
    const customDeleteButton = `<button id="lg-custom-button-delete1" style="padding-top:8px" ngbTooltip="tag" class="svg-icon lg-icon lg-group"><img class="svg-icon" title="Delete" style="height:28px;" src="assets/images/icons/trash.png"></button>`;
    const customDownloadButton = `<button id="lg-custom-button-download1" class="custombutton12 lg-icon"><img style="height:30px" title="Download" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help1" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
    const $toolbar = this.lightGallery.$toolbar;
    $toolbar.append(customDeleteButton);
    $toolbar.append(customDownloadButton);
    $toolbar.append(customHelpButton);
    //$toolbar.append(refreshButton);
    document.getElementById("lg-custom-button-delete1").addEventListener("click", async () => {
      const idname = detail.instance.galleryItems[detail.instance.index];
      console.log('click')
     await this.onDeleteDocument(idname.alt)
      let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
      const updatedGalleryItems = galleryItems1.filter((item, id) => id !== detail.instance.index);
      detail.instance.updateSlides(updatedGalleryItems, Math.max(detail.instance.index - 1, 0));
      detail.instance.slidesUpdated = true
      let slidesUpdated = false;
      //detail.instance.refresh();
    })

    document.getElementById("lg-custom-button-download1").addEventListener("click", () => {
      const downloadDocument: HobbyDocumentListOutputModel = new HobbyDocumentListOutputModel;
      const idname = detail.instance.galleryItems[detail.instance.index];
      if(idname.src){
      downloadDocument.DocumentPath = idname.src;}
      else if(idname.video){
        downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
      }
      downloadDocument.DocumentId = idname.alt;
      this.onDownloadDocument(downloadDocument)
    });
    document.getElementById("lg-custom-button-help1").addEventListener("click", () => {
      this.supportFunction();
    });
    // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
    //   detail.instance.refresh();
    // });
  };

  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: this.currentAppLanguage.data.ADDRESS.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }


  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
    this.loadingScreenService.startLoading();
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
      name:documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems1.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name:documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems1.findIndex(item => item.id === _id);
    this.GalleryItems1[index] = galleryItem;
    this.loadingScreenService.stopLoading();
  //  console.log(this.attachmentlist)
  }

  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string,CreatedDate:string, CreatedTime:string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      name:documentname,
      type: filetype,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
      
    this.GalleryItems.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
      name:documentname,
      createdDate:CreatedDate,
      createdTime:CreatedTime
    };
    const index = this.GalleryItems.findIndex(item => item.id === _id);
    this.GalleryItems[index] = galleryItem;
  //  console.log(this.attachmentlist)
  }


  onFileSelected(event){
    //console.log(event)
    this.uploader.uploadAll();
  }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }

  checkQueueLength(queuLength){
    console.log(queuLength)
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
          this.uploaderWarning=false;
        }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      if(this.hobby)
      this.hobby.textBox.nativeElement.focus();
    }
    ,500)}

  onTabActive(tabNumber:number){
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.TOOLTIP_TEXT_HOBBY_INFORMATION;}
    if(tabNumber==2){
      this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.TOOLTIP_TEXT_HOBBY_NOTES;
      setTimeout(() => {
        // console.log(this.notes)
        if (this.notes)
        this.notes.nativeElement.focus();
      },500)
    }
    else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.TOOLTIP_TEXT_UPLOAD_DOCUMENTS;}
    else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.INFO_TEXT2;
      this.getAttachmentList('Images');
    }
    else if(tabNumber==5){this.infoTooltip= this.currentAppLanguage.data.MYPASTIME.TOOLTIP_TEXT_UPLOADED_DOCUMENTS_DISPLAY;
      this.getAttachmentList('Documents');
    }
    this.tab=tabNumber;
  }

  loadPage(event,filetype){
    // this.savePosition();
     this.pageAdvancedEllipses
    this.getAttachmentList(filetype,event)
   // setTimeout(() => this.restorePosition(), 0);
   }

   getAttachmentList(FileType?,page?) {
    this.GalleryItems=[];
    this.DocumentList = [];
    //this.needRefresh = true;
    //if(page)
    this.page = page
    var getMyAttachmentsInputModel = {
      RecordId: this._data,
      Page:this.page,
      Pagesize:20,
      FileType:FileType,
    //  QuickPostType:QuickPostType.Public,
      GetMetaDataYN:false
    }
    this.loadingScreenService.startLoading();
    this.generalService.GetUserHobbyDocumentsById(getMyAttachmentsInputModel).subscribe(async result => {
      if (result) {
        if (result.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, result.data));
        //  console.log(_attachmentlist)
          if (_attachmentlist) {
            _attachmentlist.forEach(async(doc) => {
              if (doc.DocumentSubType.toLocaleLowerCase() == "documents") {
                this.DocumentList.push(doc);
              }
              else if (doc.DocumentSubType.toLocaleLowerCase() == "images" || "videos") {
                await this.AddItemToGallery(doc.DocumentId, doc.DocumentPath, doc.DocumentOldName,doc.Size ,doc.DocumentSubType,doc.CreatedDate, doc.CreatedTime);
                this.needRefresh = true;
              }
              if(_attachmentlist[0]?.TotalNumberOfRecords>0)
                this.pagesNumber=_attachmentlist[0]?.TotalNumberOfRecords;
            });
         } 
        this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(result.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }
  
  onSubmit(tabs: boolean) {
    if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
    this.submitted = true;
    if (this.frmHobby.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userHobby == null) {
      this.userHobby = new UserHobbyModel();
    }
    this.userHobby.HobbyName=this.frmHobby.get('HobbyName').value;
    this.userHobby.DateFrom = E.GetDateStringOnly(this.frmHobby.get('DateFrom').value);
    this.userHobby.DateTo = E.GetDateStringOnly(this.frmHobby.get('DateTo').value);
    this.userHobby.ShortDescription = this.frmHobby.get('ShortDescription').value;
    this.userHobby.LongDescription = this.frmHobby.get('LongDescription').value;
    this.userHobby.Notes = this.frmHobby.get('Notes').value;
    this.userHobby.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    
    if (E.ConvertToNumber(this.userHobby.UserHobbyId) == 0 && this.IsForEdit == false) {
      this.userHobby.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userHobby.CreatedDate = E.GetTodayDateStringOnly();
      this.userHobby.CreatedTime = E.GetTodayTimeStringOnly();
      this.userHobby.ChangedDate = E.GetTodayDateStringOnly();
      this.userHobby.ChangedTime = E.GetTodayTimeStringOnly();}
    else {
      this.userHobby.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userHobby.ChangedDate = E.GetTodayDateStringOnly();
      this.userHobby.ChangedTime = E.GetTodayTimeStringOnly();}
    if (this.tab <= 2 || this.IsForEdit) {
      this.loading = true;
      let response: Observable<any>;
      this.pastimeServie.CreateUpdateUserHobby(this.userHobby)
        .subscribe(
        (result) => {
          this.loading = false;
          var message = ""; var errormessage = "";
          if (result.status == Status.RecordAdded) {
            message = 'Hobby Added Successfully';
            this.tab = this.tab + 1;
            setTimeout(() => {
              // console.log(this.notes)
              if (this.notes)
              this.notes.nativeElement.focus();
            },500)
            this.nav.select(this.tab)
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserHobbyId;
            this.userHobby.UserHobbyId=parsedObject.Data.UserHobbyId;
            this.userHobby.UserHobbyId=parsedObject.Data.UserHobbyId;
            this.IsForAbandon=true;
          }
          else if (result.status == Status.RecordUpdated) {
            message = 'Hobby Updated Successfully';
            if(!tabs)
            {this.tab=this.tab+1
            this.nav.select(this.tab)}
          }
          else {
            errormessage = E.ConvertToString(result.message);
          }
          // console.log(message)
          if (message.length > 0) {
            this._toastrService.Success(message, Constant.SuccessToastrTitle);
            if ((this.tab == 1) || tabs) {
              this.dialogRef.close(true);
              this.frmHobby.reset();
            this.frmHobby.patchValue({
              DateFrom: new Date(),
              DateTo: new Date()
            });}
            // (<any>this.frmHobby.get('HobbyName')).nativeElement.focus();
          }
          else {
            this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
    }
    else if (this.tab >= 2 && this.tab <= 4) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      // console.log(this.tab)
      if (tabs) {
        this.dialogRef.close(true);
        this.frmHobby.reset();
      }
    }
  }

  onCloseButtonClick() {
    this.dialogRef.close();
  }

  async OnAchievementDeleteClick() {
    //console.log(UserInsuranceId);
    Swal.fire({
      title: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TITLE_HOBBY_TEXT,
      text: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const a = await this.DeleteMyAchievement();
          }
        })
      );
  }

  async DeleteMyAchievement() {
    this.loadingScreenService.startLoading();
    this.deleteInputModel1 = {
      DeleteID: this._data,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      let response: Observable<any>;
      this.pastimeServie.DeleteUserHobby(this.deleteInputModel1)
      .subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          //console.log(data);
          var result = JSON.parse(JSON.stringify(data));
          // console.log(result);
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: E.ConvertToString(result.message),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            this.dialogRef.close(true);
            this.frmHobby.reset();
            Result = true;
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  async onDownloadAllDocument() {
    for (let index = 0; index < this.DocumentList.length; index++) {
      const _doc = this.DocumentList[index];
      var _res = await this.DownloadMyAddressDocument(_doc, true);
      // console.log('_res');
      // console.log(_res);
      if (_res) {
        if ((index + 1) === this.DocumentList.length) {
          this._toastrService.Success('Downloaded All Successfully.', Constant.SuccessToastrTitle);
        }
      } else {
        break;
      }
    }
  }
  async onDownloadDocument(document: HobbyDocumentListOutputModel) {
    if (document) {
      await this.DownloadMyAddressDocument(document);
    }
  }
  async DownloadMyAddressDocument(Document: HobbyDocumentListOutputModel, IsDownloadAll = false) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.DocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      const path = 'api/UserHobby/DownloadUserHobbyDocuments';
      this.subDownloadAddressAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            //console.log(this.DocumentList);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  async onDeleteAllDocument() {
    let UserInsuranceDocumentIds: number[] = [];
    if (this.DocumentList) {
      this.DocumentList.forEach(_doc => {
        UserInsuranceDocumentIds.push(_doc.DocumentId);
      });
      if (UserInsuranceDocumentIds.length > 0) {
        Swal.fire({
          title: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TITLE_ALL_HOBBY_DOCUMENT_TEXT,
          text: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TEXT,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#7367F0',
          cancelButtonColor: '#E42728',
          confirmButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CONFIRM_BUTTON_TEXT_UNLINK,
          cancelButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CANCEL_BUTTON_TEXT,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          }
        })
          .then(
            (async (result) => {
              if (result.value) {
                const _res = await this.DeleteMyAddressDocument(UserInsuranceDocumentIds);
              }
            })
          );
      }
    }
  }

  // async onDeleteDocument(userInsuranceDocumentId: number) {
  //   let UserInsuranceDocumentIds: number[] = [];
  //   if (userInsuranceDocumentId) {
  //     UserInsuranceDocumentIds.push(userInsuranceDocumentId);
  //     Swal.fire({
  //       title: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT,
  //       text: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TEXT,
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#7367F0',
  //       cancelButtonColor: '#E42728',
  //       confirmButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
  //       cancelButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CANCEL_BUTTON_TEXT,
  //       customClass: {
  //         confirmButton: 'btn btn-primary',
  //         cancelButton: 'btn btn-danger ml-1'
  //       }
  //     })
  //       .then(
  //         (async (result) => {
  //           if (result.value) {
  //             const a = await this.DeleteMyInsuranceDocument(UserInsuranceDocumentIds);
  //           }
  //         })
  //       );
  //   }
  // }
  // async DeleteMyInsuranceDocument(UserInsuranceDocumentIds: Array<number>) {
  //   //console.log('DeleteMyAddressDocument');
  //   this.loadingScreenService.startLoading();
  //   if (UserInsuranceDocumentIds.length < 1) {
  //     return false;
  //   }
  //   // this.deleteDocumentsInputModel = [];
  //   UserInsuranceDocumentIds.forEach(userInsuranceDocumentId => {
  //     this.deleteInputModel={
  //       DeleteIDs: [userInsuranceDocumentId],
  //       UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
  //     };
  //   });
  //   let promise = new Promise((resolve, reject) => {
  //     var Result: boolean;
  //     // const path = 'api/UserInsurance/DeleteUserInsuranceDocuments';
  //     // let response: Observable<any>;
  //     // response = this.globalService.PostDataById(this.deleteDocumentsInputModel, path);
  //     let response: Observable<any>;
  //     //  console.log('this.userInsurance');
  //     // console.log(this.userInsurance);
  //     this.pastimeServie.DeleteUserHobbyDocuments(this.deleteInputModel)
  //       .subscribe(
  //         (data) => {
  //           this.loadingScreenService.stopLoading();
  //          // console.log(data);
  //           var result = JSON.parse(JSON.stringify(data));
  //          // console.log(result);
  //           if (result.status == Status.Success) {
  //             Swal.fire({
  //               icon: 'success',
  //               title: 'Deleted!',
  //               text: E.ConvertToString(result.message),
  //               customClass: {
  //                 confirmButton: 'btn btn-success'
  //               }
  //             });
  //             //this.loadData();    //Re-loading User Addresses Grid
  //             //console.log(this.DocumentList);
  //             this.DocumentList = this.DocumentList.filter(x => !UserInsuranceDocumentIds.includes(x.UserHobbyDocumentId));
  //             //console.log(this.DocumentList);

  //             Result = true;
  //           }
  //           else {
  //             this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  //             Result = false;
  //           }
  //           resolve(Result);
  //         },
  //         (error) => {
  //           this._toastrService.Error(error, Constant.ErrorToastrTitle);
  //           this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  //           this.loadingScreenService.stopLoading();
  //         }
  //       );
  //   });
  //   return await promise;
  // }

  async onDeleteDocument(userAddressDocumentId: number) {
    if (!userAddressDocumentId) {
      return;
    }
  
    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteMyAddressDocument([userAddressDocumentId]);
  
      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }
  
  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TITLE_HOBBY_DOCUMENT_TEXT,
        text: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CONFIRM_BUTTON_TEXT_UNLINK,
        cancelButtonText: this.currentAppLanguage.data.MYPASTIME.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }
  
  async DeleteMyAddressDocument(UserAddressDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
  
    try {
      if (UserAddressDocumentIds.length < 1) {
        return false;
      }
  
      //this.deleteDocumentsInputModel = [];
       this.UserAddressDocument = UserAddressDocumentIds
      UserAddressDocumentIds.forEach((userAddressDocumentId) => {
        this.deleteInputModel={
                DeleteIDs: [userAddressDocumentId],
                UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
                RecordId: this._data
              };
      });
  
      const result = await this.postDataAndHandleResponse();
  
      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }
  
  private postDataAndHandleResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserHobby/DeleteUserHobbyDocuments';
      this.subDeleteAddressAtt$ = this.globalService.PostDataById(this.deleteInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));
  
          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }
  
  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted!',
      text: E.ConvertToString(result.message),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });
  
    // Modify this part as needed
    this.DocumentList = this.DocumentList.filter((x) => !this.UserAddressDocument.includes(x.DocumentId));
    this.DocumentList1 = this.DocumentList1.filter((x) => !this.UserAddressDocument.includes(x.DocumentId));
    const indexToRemove: number = this.GalleryItems.findIndex((item) => item.id == this.UserAddressDocument);
    if (indexToRemove !== -1) {
      this.GalleryItems.splice(indexToRemove, 1);
    }
    const indexToRemovenew: number = this.GalleryItems1.findIndex((item) => item.id == this.UserAddressDocument);
    if (indexToRemovenew !== -1) {
      this.GalleryItems1.splice(indexToRemovenew, 1);
    }
  }
  
  private handleFailure(result: any): void {
    this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  }
  
  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }
  // OnPolicyExpirationDateChange(event) {
  //   //  console.log(this.insuranceLoadDataOutputModel.UserInsurance)
  //   //   if (this.insuranceLoadDataOutputModel.UserInsurance.UserInsuranceId){
  //     let datefrom = this.frmInsurance.get('PolicyStartDate').value;
  //     let dateto = this.frmInsurance.get('PolicyEndDate').value;
  //     // console.log(datefrom,dateto)
  //     if (datefrom && dateto) {
  //       if (datefrom.getTime() > dateto.getTime()) {
  //         this.frmInsurance.get('PolicyStartDate').setValue(dateto);
  //       }
  //     }
  //     if (dateto)
  //       this.maxFromDate = dateto;
  
      
  //   }
  
  //   ngAfterViewChecked(): void {
  //     this.changeDetectorRef.detectChanges();
  //   }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }

  get f(){
    return this.frmHobby.controls;
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  openFilemanager(){
    // using for old filemanager uploads
   // this.openDialog(UploadManagerComponent)
   var matdata = {
    RecordId : this._data,
    RecordType: RecordsType.Hobby
   }
   this.openDialog(SelectSyncFileManagerComponent,matdata)
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.height="60%"
    dialogConfig.position={
      top:"10%"
    }
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
            if(data){
              this.nav.select(4);
              this.getAttachmentList('Images');
            }
      }
    );
  }

  ngOnDestroy() {
    
    if (this.subDownloadAddressAtt$)
      this.subDownloadAddressAtt$.unsubscribe();
    if (this.uploader && this.uploader.response)
      this.uploader.response.unsubscribe();
    if (this.subDeleteAddressAtt$)
      this.subDeleteAddressAtt$.unsubscribe();
  }

}
