import { CountryListOutputModel } from "app/Models/country-model.model";
import { RegionListOutputModel } from "app/Models/region-model.model";
import { StateListOutputModel } from "app/Models/state-model.model";

export class TblUserSchools {
    UserSchoolId: number;
    UserDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    SchoolName: string;
    EducationReceived: string;
    AddressTag: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressCounty: string;
    AddressPostCode: string;
    CurrentSchoolYn:boolean;
    Latitude: number;
    Longitude: number;
    Country: number;
    SchoolFromDate: string;
    SchoolToDate: string;
    Notes: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class SchoolModel {
    UserSchoolId: number;
    UserDetailsId: number;
    RegionId: number;
    CountryId: number;
    StateId: number;
    SchoolName: string;
    FullAddress: string;
    EducationReceived: string;
    AddressTag: string;
    Address1: string;
    Address2: string;
    Address3: string;
    AddressTown: string;
    AddressState:string;
    AddressCountry: string;
    AddressPostCode: string;
    CurrentSchoolYn:boolean;
    //AddressState:string;
    Latitude: number;
    Longitude: number;
    Notes: string;
    //Country: number;
    SchoolFromDate: Date;
    SchoolToDate: Date;
    CreatedDate: Date;
    CreatedTime: Date;
    CreatedBy: number;
    ChangedDate: Date;
    ChangedTime: Date;
    ChangedBy: number;
}
export class SchoolLoadDataOutputModel {
    UserSchool: TblUserSchools;
    CountryList: Array<CountryListOutputModel>
    RegionList: Array<RegionListOutputModel>;
    StateList: Array<StateListOutputModel>;
    SchoolDocumentList: Array<SchoolDocumentListOutputModel>;
}
export class SchoolDocumentListOutputModel {
    DocumentId: number;
    UserSchoolDocumentId:number;
    UserDetailsId: number;
    UserSchoolId: number;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    DocumentSubType: string;
    Size: string;
    DocumentType: string;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}