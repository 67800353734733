import {InsuranceTypeListOutputModel} from 'app/Models/insurancetype.model'
export class Insurance {
}
export class TblUserInsurances
{
	 UserInsuranceId: number;
     UserDetailsId: number;
     InsuranceTypeId: number;
     InsuranceName: string;
     PolicyNumber: string;
     CardNumber: string;
     ContactNumber: string;
     ContactNumberCountryCode: string;
     PolicyStartDate:string;
     PolicyEndDate:string;
     Notes:string;
     CreatedDate:string;
     CreatedTime:string;
     CreatedBy: number;
     ChangedDate:string;
     ChangedTime:string;
     ChangedBy: number;
}
export class InsuranceModel
{
    UserInsuranceId: number;
    UserDetailsId: number;
    InsuranceTypeId: number;
    InsuranceName: string;
    InsuranceTypeName:string;
    InsuranceStatusName:string;
    PolicyNumber: string;
    CardNumber: string;
    ContactNumber: string;
    ContactNumberCountryCode: string;
    PolicyStartDate: string;
    PolicyEndDate: string;
    Notes: string;
    CreatedDate:string;
    CreatedTime:string;
    CreatedBy: number;
    ChangedDate:string;
    ChangedTime:string;
    ChangedBy: number;
    //InsuranceTypeName: string;
}
export class InsuranceLoadDataOutputModel
{
   UserInsurance:TblUserInsurances;
   InsuranceTypeList:  Array<InsuranceTypeListOutputModel>;
   InsuranceDocumentList: Array<InsuranceDocumentListOutputModel> ;
} 
export class InsuranceDocumentListOutputModel
{
    DocumentId: number;
    UserDetailsId: number;
    UserInsuranceId: number;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    DocumentSubType: string;
    Size: string;
    DocumentType: string;
    CreatedDate:Date;
    CreatedTime:Date;
    CreatedBy: number;
    ChangedDate:Date;
    ChangedTime:Date;
    ChangedBy:number;
}
